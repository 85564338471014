<template>
  <div>
    <v-dialog
      v-model="activo"
      max-width="65%"
    >
      <v-card
        flat
      >
        <v-card-title>
          Buscar Artículos
          <v-spacer></v-spacer>
          <v-btn icon @click="activo = false"><v-icon title="Cerrar">fas fa-times</v-icon></v-btn>
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col cols="12">
              <v-data-table
                class="cebra elevation-2 mt-2"
                :headers="headers"
                :items="articulos"
                :loading="load"
                dense
                :search="search"
              >
                <!-- Buscador en el datatable -->
                <template v-slot:top>
                  <v-row class="d-flex justify-end pa-2" no-gutters>
                    <v-col cols="6" sm="3">
                      <SearchDataTable
                        v-model="search"
                      />
                    </v-col>
                  </v-row>
                </template>
                <!-- Msj que se mostrara si no existen resultados -->
                <template v-slot:no-data>
                  <v-alert
                    class="mx-auto mt-4"
                    type="warning"
                    border="left"
                    dense
                    text
                  >
                    La búsqueda no pudo obtener ningún resultado.
                  </v-alert>
                </template>
                <!-- Acciones -->
                <template v-slot:[`item.acciones`]="{ item }">
                  <v-btn icon small title="Agregar" @click="agregarArticulo(item)"><v-icon small color="success">fas fa-check</v-icon></v-btn>
                </template>
                <!-- Cantidad -->
                <template v-slot:[`item.cantidad`]="{ item }">
                  <v-row class="d-flex justify-center" no-gutters>
                    <v-text-field v-model="item.cantidad" type="number" outlined dense style="width: 50px" hide-details></v-text-field>
                  </v-row>
                </template>
              </v-data-table>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
  import SearchDataTable from './SearchDataTable.vue'
export default {
  props: {
    dialogActivo: {
      type: Boolean,
      default: false
    },
    bodega: {
      type: Number,
      default: 0
    }
  },
  computed: {
    activo: {
      get(){
        return this.dialogActivo
      },
      set(value){
        this.$emit('setearModalArticulos', value)
      }
    },
    bodegaOrigen: {
      get(){
        return this.bodega
      }
    }
  },
  components: {
    SearchDataTable,
  },
  data() {
    return {
      headers: [
        { text: 'Cod. Interno', align: 'center', value: 'articulo_codigo' },
        { text: 'Cod. Barra', align: 'center', value: 'articulo_cod_barras' },
        { text: 'Nombre', align: 'center', value: 'articulo_nombre' },
        { text: 'Cantidad', align: 'center', value: 'cantidad' },
        { text: 'Acciones', align: 'center', value: 'acciones' }
      ],
      articulos: [],
      load: false,
      search: ''
    }
  },
  created () {
    
  },
  methods: {
    async initDataTableArticulos(){
      this.load = true
      this.articulos = []
      // pido la primera pagina de entrada
      let articulosPeticion = await this.$store.dispatch('remitos/getArticulosXBodega', {bodega: this.bodegaOrigen})
      this.load = false
      if (articulosPeticion.resultado == 1){
        this.articulos = articulosPeticion.articulos
      }else{
        this.$store.dispatch('show_snackbar', {
          text: articulosPeticion.msj,
          color: 'error'
        })
      }
    },
    agregarArticulo(item){
      // opcion indica que lo estamos mandando al articulo desde este modal ya armado
      item.opcion = 2
      // tipo 2 indica que se buscara por codigo de articulo
      item.tipo = 2
      // envio el item
      this.$emit('agregarArtModal', item)
      this.activo = false
    }
  },
  watch: {
    activo: function(){
      if (this.activo == true){
        // cargar el datatable de artículos
        this.initDataTableArticulos()
      }else{
        this.articulos = []
        this.search = ''
      }
    },
    articulos: function(){
      if (this.articulos.length > 0){
        for (let id in this.articulos){
          this.articulos[id].cantidad = 0
        }
      }
    }
  },
}
</script>

<style>

</style>