<template>
  <v-menu
    ref="menu"
    v-model="menu"
    :close-on-content-click="false"
    :nudge-right="40"
    :return-value.sync="time"
    transition="scale-transition"
    max-width="290px"
    min-width="290px"
    offset-y
  >
    <template v-slot:activator="{ on, attrs }">
      <v-text-field
        v-model="time"
        :label="label"
        :clearable="clearable"
        readonly
        outlined
        filled
        dense
      >
        <template v-slot:append-outer>
          <v-icon
            v-bind="attrs"
            v-on="on"
            :color="disabled ? '' : 'info'"
            :title="disabled ? '' : 'Seleccionar hora'"
            :disabled="disabled"
          >
            fas fa-clock
          </v-icon>
        </template>
      </v-text-field>
    </template>
    <v-time-picker
      v-if="menu"
      v-model="time"
      format="24hr"
      :min="horario_restringido[0]"
      :max="horario_restringido[1]"
      :allowed-minutes="allowedStep"
      full-width
      @click:minute="$refs.menu.save(time)"
    ></v-time-picker>
  </v-menu>
</template>

<script>
/**
 *  Componente para seleccionar la hora
 */
export default {
  data () {
    return {
      menu: false
    }
  },
  props: {
    value: String, // hora en formato HH:mm (obligatorio)
    label: {
      type: String,
      default: ''
    },
    disabled: {
      type: Boolean,
      default: false
    },
    clearable: {
      type: Boolean,
      default: false
    },
    // array de las horas inicio y hora fin del picker. Ej: ['09:00', '21:00']
    horario_restringido: {
      type: Array,
      default () {
        return [ '', '' ]
      }
    }
  },
  computed: {
    time: {
      get () {
        return this.value
      },
      set (value) {
        this.$emit('input', value)
      }
    }
  },
  methods: {
    allowedStep: m => m % 5 === 0
  }
}
</script>
