<template>
  <v-dialog
    v-model="dialog"
    max-width="600"
  >
    <v-card>
      <v-card-title>
        Subir archivo
        <v-spacer></v-spacer>
        <v-btn
          icon
          @click="dialog = false"
        >
          <v-icon>fas fa-times</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text class="py-0">
        <v-card flat>
          <v-card-text class="pt-0 pb-0">
            <v-row
              class="d-flex justify-center"
              style="border-style: dashed; border-width: 2px; border-radius: 15px; cursor: pointer;"
              :style="$vuetify.theme.dark ? 'background-color: #0f0f0f;' : 'background-color: #f0f0f0;'"
              no-gutters
              @dragover.prevent="hover = true"
              @dragleave.prevent="hover = false"
              @drop.prevent="drop"
              @click="$refs.input.click()"
            >
              <input
                ref="input"
                type="file"
                class="d-none"
                :accept="tipos.join(',')"
                @change="buscar($event)"
              >
              <v-col cols="12" class="d-flex justify-center mt-6">
                <v-icon
                  :large="hover ? false : true"
                  :x-large="hover ? true : false"
                >
                  fas fa-folder{{ hover ? '-open' : '' }}
                </v-icon>
              </v-col>
              <v-col cols="12" class="d-flex justify-center mt-2 mb-4">
                <h1 class="body-1 font-weight-bold mx-4 text-center">
                  Arrastre el archivo o haga click aquí
                </h1>
              </v-col>
            </v-row>
            <div
              class="d-flex body-2 pt-2"
              style="cursor: default"
            >
              <div>
                Tipo{{ tipos.length > 1 ? 's' : '' }} de archivo válido: {{ tipos.join(', ') }}
              </div>
            </div>
          </v-card-text>
          <v-card-text
            v-if="data != null"
            class="pb-0"
          >
            <v-alert
              type="info"
              border="left"
              class="mb-0"
              outlined
              text
            >
              Se cargará el archivo <strong>{{ nombre }}</strong>
            </v-alert>
          </v-card-text>
        </v-card>
      </v-card-text>
      <v-card-actions class="d-flex justify-end pb-4">
        <v-row class="d-flex justify-end py-4 px-3">
          <v-btn
            color="error"
            class="mt-2 ml-2"
            @click="dialog = false"
          >
            Cancelar
          </v-btn>
          <v-btn
            color="primary"
            class="mt-2 ml-2"
            @click="$refs.input.click()"
          >
            <v-icon left>fas fa-folder-open</v-icon>
            Seleccionar archivo
          </v-btn>
          <BtnConfirmar
            clase="mt-2 ml-2"
            nombre="Confirmar"
            :disabled="data == null"
            @action="procesar()"
          />
        </v-row>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
/**
 * Modal para subir un archivo de texto con series
 * 
 * Notas: este componente emite la accion @procesar al padre la cual devuelve las series correctas, sobrantes e inexistentes (en ese orden)
 * 
 */
import { tipos_archivos } from '../../util/utils'
import BtnConfirmar from '../util/BtnConfirmar'

export default {
  data () {
    return {
      data: null,
      hover: false,
      tipos: ['.txt'], // los tipos de archivos están en /util/utils.js
      nombre: ''
    }
  },
  props: {
    value: Boolean,
    p_id: Number    // id del control de stock, obligatorio
  },
  computed: {
    dialog: {
      get () {
        return this.value
      },
      set (value) {
        this.$emit('input', value)
      }
    }
  },
  watch: {
    dialog (val) {
      // limpia los valores cuando cierra el modal
      if (!val) {
        this.data = null
        this.nombre = ''
      }
    }
  },
  components: {
    BtnConfirmar
  },
  methods: {
    async procesar () {
      this.$store.state.loading = true
      await this.$store.dispatch('controlStock/procesar_archivo', {
        filename: this.nombre,
        data: this.data,
        id: this.p_id
      })
        .then((res) => {
          this.dialog = false
          this.$store.dispatch('show_snackbar', {
            text: res.message,
            color: 'success'
          })
          this.$emit('procesar', res.correctas, res.sobrantes, res.inexistentes)
        })
        .catch(error => {
          this.$store.dispatch('show_snackbar', {
            text: error.message,
            color: 'error'
          })
        })
      this.$store.state.loading = false
    },
    validar (files) {
      // obtiene el archivo (en este caso solo puede subir 1)
      const file = files[0]
      const tipo = file.type
      this.nombre = file.name

      // busca en el array de tipos_archivos el tipo de archivo del archivo que subio
      const tipo_archivo = tipos_archivos.find(a => a.mime == tipo)

      // verifica que exista el tipo de archivo en el array
      if (tipo_archivo) {
        // verifica que pertenezca por lo menos a un tipo de extension existente en el array de tipos
        if (this.tipos.find(a => a == tipo_archivo.extension)) {
          // el archivo que subio es correcto
          const reader = new FileReader()
          reader.onload = (e) => {
            const bytes = e.target.result
            if (bytes) {
              this.data = bytes
            } else {
              this.$store.dispatch('show_snackbar', {
                text: 'Archivo sin datos',
                color: 'warning'
              })
            }
          }
          reader.readAsText(file)
        } else {
          // subio un archivo con una extension no valida
          this.$store.dispatch('show_snackbar', {
            text: 'Tipo de archivo no válido',
            color: 'error'
          })
        }
      } else {
        // subio un tipo de archivo que no esta registrado en el array tipos_archivos
        this.$store.dispatch('show_snackbar', {
          text: 'Tipo de archivo no válido para el sistema',
          color: 'error'
        })
      }
      
      // limpia el input de archivos
      this.$refs.input.value = ''
    },
    drop (event) {
      this.hover = false
      const files = event.dataTransfer.files
      if (files.length > 1) {
        this.$refs.input.value = ''
        this.$store.dispatch('show_snackbar', {
          text: 'Solo puede subir un archivo',
          color: 'error'
        })
        return
      }
      this.validar(files)
    },
    buscar (event) {
      const files = event.target.files
      if (files) this.validar(files)
    }
  }
}
</script>