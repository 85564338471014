<template>
  <div>
    <v-row class="d-flex justify-center mt-0">
      <v-col cols="12" class="py-1">
        <!-- Filtros -->
        <v-expansion-panels v-model="panel">
          <v-expansion-panel readonly>
            <!-- Botón para Abrir modal de Descuentos Especiales -->
            <v-btn
              style="position: absolute; top: -15px; right: 181px; z-index: 1"
              color="orange" dark
              small
              fab
              title="Descuentos Especiales"
              @click="objModal.activo = true"
              v-if="tienePermisoDescEspeciales == 1"
            >
              <v-icon>fas fa-percentage</v-icon>
            </v-btn>
            <!-- Botón para Importar Archivo -->
            <v-btn
              style="position: absolute; top: -15px; right: 117px; z-index: 1"
              color="info"
              small
              fab
              title="Subir Archivo"
              @click="importarExcel()"
              v-if="tienePermiso == 1"
            >
              <v-icon>fas fa-upload</v-icon>
            </v-btn>
            <!-- Boton Agregar nueva Promoción -->
            <v-btn
              style="position: absolute; top: -15px; right: 55px; z-index: 1"
              color="success"
              small
              fab
              title="Nueva Promoción"
              @click="nuevaPromocion()"
            >
              <v-icon>fas fa-plus</v-icon>
            </v-btn>
            <!-- Input del archivo, nunca visible -->
            <input 
              ref="uploader" 
              class="d-none" 
              type="file" 
              @change="onFileChanged"
            >
            <!-- Titulo Filtros -->
            <v-expansion-panel-header class="py-0 px-4 text-h5" style="cursor: default">
              <div>
                <v-icon class="mb-1" left>fas fa-filter</v-icon>
                Filtros
              </div>
              <template v-slot:actions>
                <v-btn icon @click.stop="panel = panel === 1 ? 0 : 1">
                  <v-icon>fas fa-chevron-down</v-icon>
                </v-btn>
              </template>
            </v-expansion-panel-header>
            <!-- Filtros -->
            <v-expansion-panel-content class="py-1">
              <v-form @submit.prevent="buscar()">
                <!-- Código, Descripción, Estado y Fechas -->
                <v-row >
                  <v-col cols="12" sm="3" md="2" class="py-1">
                    Código
                    <v-text-field
                      v-model="codigo"
                      outlined
                      dense
                      type="number"
                      hide-details
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="5" md="4" class="py-1">
                    Descripción
                    <v-text-field
                      v-model="descripcion"
                      outlined
                      dense
                      type="text"
                      hide-details
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="4" md="2" class="py-1">
                    Estado
                    <v-autocomplete
                      v-model="selectedEstado"
                      item-text="estado_nombre"
                      :items="estados"
                      hide-details
                      outlined
                      dense
                      return-object
                    ></v-autocomplete>
                  </v-col>
                  <v-col cols="6" sm="3" md="2" class="py-1">
                    Fecha Inicio
                    <FechaPicker 
                      v-model="fechaInicio"
                      hideDetails
                    />
                  </v-col>
                  <v-col cols="6" sm="3" md="2" class="py-1">
                    Fecha Fin
                    <FechaPicker 
                      v-model="fechaFin"
                      hideDetails
                    />
                  </v-col>
                  <!-- Artículos -->
                  <v-col cols="12" sm="6" md="12" class="py-1">
                    Artículos
                    <v-combobox
                      v-model="articulos"
                      chips
                      deletable-chips
                      multiple
                      append-icon=""
                      dense
                      outlined
                      hide-details
                    >
                      <template v-slot:selection="{ attrs, item, select, selected }">
                        <v-chip
                          small
                          v-bind="attrs"
                          :input-value="selected"
                          close
                          @click="select"
                          @click:close="quitarArticulo(item)"
                          >
                          {{ item }}
                        </v-chip>
                      </template>
                      <template v-slot:append-outer>
                        <v-tooltip
                          bottom
                        >
                          <template v-slot:activator="{ on }">
                            <v-btn small icon v-on="on" @click.stop="buscarArticulos()">
                              <v-icon small color="success">
                                fas fa-search
                              </v-icon>
                            </v-btn>
                          </template>
                          Buscar Artículos
                        </v-tooltip>
                      </template>
                    </v-combobox>
                  </v-col>
                  <!-- Categorías, Check de Formas de Pago y con Cupones -->
                  <v-col cols="12" sm="4" md="3" class="py-1">
                    Categorias
                    <v-autocomplete
                      v-model="selectedCategoria"
                      item-text="categoria_nombre"
                      :items="categorias"
                      hide-details
                      outlined
                      dense
                      return-object
                      multiple
                      deletable-chips
                      small-chips
                    ></v-autocomplete>
                  </v-col>
                  <v-col cols="6" sm="3" md="2" class="" :class="pantallaChica ? 'py-1' : 'pt-6'">
                    <v-checkbox
                      v-model="fp"
                      label="Forma Pago"
                      dense
                      hide-details
                      :true-value="1"
                      :false-value="0"
                    ></v-checkbox>
                  </v-col>
                  <v-col cols="6" sm="2" md="2" :class="pantallaChica ? 'py-1' : 'pt-6'">
                    <v-checkbox
                      v-model="cupones"
                      label="Cupones"
                      dense
                      hide-details
                      :true-value="1"
                      :false-value="0"
                    ></v-checkbox>
                  </v-col>
                  <v-col cols="12" sm="3" md="5" class="py-1 px-0 d-flex justify-end">
                    <BtnFiltro
                      class="py-1"
                      :loading="load"
                      @clear="limpiar()"
                    />
                  </v-col>
                </v-row>
              </v-form>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
        <!-- Listado de Promociones -->
        <v-data-table
          class="cebra elevation-2 mt-2"
          :headers="headers"
          :items="promociones"
          :loading="load"
          dense
          :search="search"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
        >
          <!-- Buscador en el datatable -->
          <template v-slot:top>
            <v-row class="d-flex justify-end pa-2" no-gutters>
              <v-col cols="12" sm="6" md="3">
                <SearchDataTable
                  v-model="search"
                />
              </v-col>
            </v-row>
          </template>
          <!-- formato de fechas -->
          <template v-slot:[`item.fecha_inicio`]="{ item }">
            {{ moment(item.fecha_inicio).format('DD/MM/YYYY') }}
          </template>
          <template v-slot:[`item.fecha_fin`]="{ item }">
            {{ moment(item.fecha_fin).format('DD/MM/YYYY') }}
          </template>
          <!-- Msj que se mostrara si no existen resultados -->
          <template v-slot:no-data>
            <v-alert
              class="mx-auto mt-4"
              max-width="500"
              type="warning"
              border="left"
              dense
              text
            >
              No hay Promociones para los filtros seleccionados
            </v-alert>
          </template>
          <!-- Acciones -->
          <template v-slot:[`item.acciones`]="{ item }">
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn small icon color="indigo" @click="verPromocion(item)" v-on="on">
                  <v-icon small>far fa-eye</v-icon>
                </v-btn>
              </template>
              <span>Ver</span>
            </v-tooltip>
            <v-tooltip bottom v-if="item.cupon == 1">
              <template v-slot:activator="{ on }">
                <v-btn small icon color="info" @click="descargarCupones(item)" v-on="on">
                  <v-icon small>fas fa-download</v-icon>
                </v-btn>
              </template>
              <span>Descargar Cupones</span>
            </v-tooltip>
            <v-tooltip bottom v-if="item.cupon == 1">
              <template v-slot:activator="{ on }">
                <v-btn small icon color="success" @click="agregarCupones(item)" v-on="on">
                  <v-icon small>fas fa-plus-circle</v-icon>
                </v-btn>
              </template>
              <span>Agregar Cupones</span>
            </v-tooltip>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
    <!-- Modal Agregar Cupones -->
    <ModalAgregarCupones 
      :datos="dialogAddCupones"
      @setearModalAddCupones="setModalAddCupones"
      @setExitoCupones="setSuccessCupones"
    />
    <!-- Modal Seleccionar Articulo -->
    <ModalSelectArticulo 
      :datos="dialogSelectArticulo"
      @setearModalSelectArt="setModalSelectArt"
      @setearArticulo="setArticulo"
    />
    <!-- Modal Descuentos Especiales por persona -->
    <ModalDescuentosEspecialesVue 
      v-model="objModal.activo"
      :datos="objModal"
    />
  </div>
</template>

<script>
import BtnFiltro from '../../components/util/BtnFiltro.vue'
import SearchDataTable from '../../components/util/SearchDataTable.vue'
import ModalAgregarCupones from '../../components/promociones/ModalAgregarCupones.vue'
import FechaPicker from '../../components/util/FechaPicker.vue'
import moment from 'moment'
import download from 'downloadjs'
import { roundNumber } from '../../util/utils'
import ModalSelectArticulo from '../../components/util/ModalSelectArticulo.vue'
import { read, utils } from 'xlsx'
import ModalDescuentosEspecialesVue from '../../components/promociones/ModalDescuentosEspeciales.vue'

export default {
  data() {
    return {
      moment: moment,
      panel: 0,
      load: false,
      codigo: '',
      descripcion: '',
      fechaInicio: '',
      fechaFin: '',
      selectedEstado: {},
      estados: [],
      headers: [
        { text: 'Código', align: 'center', value: 'promocion_id' },
        { text: 'Descripción', align: 'left', value: 'descripcion' },
        { text: 'Fecha Inicio', align: 'center', value: 'fecha_inicio' },
        { text: 'Fecha Fin', align: 'center', value: 'fecha_fin' },
        { text: 'Descuento', align: 'center', value: 'descuento' },
        { text: 'Estado', align: 'center', value: 'estado_nombre' },
        { text: 'Usuario', align: 'center', value: 'usuario_log' },
        { text: 'Acciones', align: 'center', value: 'acciones' }
      ],
      promociones: [],
      search: '',
      dialogAddCupones: {
        activo: false,
        promocion_id: 0,
        promocion_nombre: ''
      },
      selectedCategoria: [],
      categorias: [],
      articulos: [],
      dialogSelectArticulo: {
        activo: false,
        articulos: [],
        bloquear_cant: true,
        bloquear_precio: true,
        cerrar_seleccion: false,
        titulo_modal: 'Buscar Artículos',
        color: ''
      },
      empiezaDepurar: 0,
      sortDesc: true,
      sortBy: 'promocion_id',
      isSelecting: false,
      selectedFile: null,
      tienePermiso: 0,
      tienePermisoDescEspeciales: 0,
      fp: 0,
      cupones: 0,
      //Descuentos especiales
      objModal: {
        activo: false,
      },
      pantallaChica: this.$vuetify.breakpoint.xs,
    }
  },
  components: {
    BtnFiltro,
    SearchDataTable,
    ModalAgregarCupones,
    FechaPicker,
    ModalSelectArticulo,
    ModalDescuentosEspecialesVue
  },
  created () {
    this.initForm()
  },
  methods: {
    async initForm(){
      this.$store.state.loading = true
      let initPeticion = await this.$store.dispatch('promociones/initPromoIndex')
      this.$store.state.loading = false
      if (initPeticion.resultado == 1){
        this.estados = initPeticion.estados
        this.categorias = initPeticion.categorias
        this.tienePermiso = initPeticion.permiso
        this.tienePermisoDescEspeciales = initPeticion.permiso_desc_especiales;
      }else{
        this.$store.dispatch('show_snackbar', {
          text: initPeticion.msj,
          color: 'error',
        })
      }
    },
    limpiar(){
      this.codigo = ''
      this.selectedEstado = {}
      this.descripcion = ''
      this.fechaInicio = ''
      this.fechaFin = ''
      this.articulos = []
      this.selectedCategoria = []
      this.fp = 0
      this.cupones = 0
    },
    async buscar(){
      this.load = true
      this.promociones = []
      // codigo
      let cod = 0
      if (this.codigo != null && this.codigo != undefined && this.codigo.toString().length > 0) cod = this.codigo
      // estado
      let estado = -1
      if (Object.keys(this.selectedEstado).length > 0) estado = this.selectedEstado.estado_id
      // fechas
      let fechaDesde = ''
      if (this.fechaInicio != null && this.fechaInicio != undefined && this.fechaInicio.toString().length > 0) fechaDesde = this.fechaInicio
      let fechaHasta = ''
      if (this.fechaFin != null && this.fechaFin != undefined && this.fechaFin.toString().length > 0) fechaHasta = this.fechaFin
      let body = {
        codigo: cod,
        descripcion: this.descripcion,
        estado: estado,
        fecha_desde: fechaDesde,
        fecha_hasta: fechaHasta,
        categorias: this.selectedCategoria,
        articulos: this.articulos,//.map(a => parseInt(a)),
        fp: this.fp,
        cupon: this.cupones
      }
      let promosPeticion = await this.$store.dispatch('promociones/getPromociones', body)
      this.load = false
      if (promosPeticion.resultado == 1){
        this.promociones = promosPeticion.promociones
      }else{
        this.$store.dispatch('show_snackbar', {
          text: promosPeticion.msj,
          color: 'error',
        })
      }
    },
    verPromocion(item){
      let path = '/ver-promo/' + item.promocion_id.toString()
      const routeData = this.$router.resolve({ path: path })
      window.open(routeData.href, '_blank')
    },
    async descargarCupones(item){
      this.$store.state.loading = true
      let cuponesPeticion = await this.$store.dispatch('promociones/getCuponesPromocion', {promocion_id: item.promocion_id})
      this.$store.state.loading = false
      if (cuponesPeticion.resultado == 1){
        download(cuponesPeticion.base64, `Cupones Promocion ${item.promocion_id}.xlsx`, 'application/vnd.ms-excel')
      }else{
        this.$store.dispatch('show_snackbar', {
          text: cuponesPeticion.msj,
          color: 'error',
        })
      }
    },
    agregarCupones(item){
      this.dialogAddCupones.promocion_id = item.promocion_id
      this.dialogAddCupones.promocion_nombre = item.descripcion
      this.dialogAddCupones.activo = true
    },
    setModalAddCupones(value){
      this.dialogAddCupones.activo = value
      if (this.dialogAddCupones.activo == false){
        this.dialogAddCupones.promocion_id = 0
        this.dialogAddCupones.promocion_nombre = ''
      }
    },
    setSuccessCupones(){
      this.buscar()
    },
    nuevaPromocion(){
      let path = '/nueva-promo'
      const routeData = this.$router.resolve({ path: path })
      window.open(routeData.href, '_blank')
    },
    quitarArticulo(item){
      let pos = this.articulos.indexOf(item)
      this.articulos.splice(pos, 1)
    },
    async buscarArticulos(){
      // obtengo los articulos
      if (this.dialogSelectArticulo.articulos.length == 0){
        this.$store.state.loading = true
        let articulosPeticion = await this.$store.dispatch('promociones/getAllArticulos')
        this.$store.state.loading = false
        if (articulosPeticion.resultado == 1){
          this.dialogSelectArticulo.articulos = articulosPeticion.articulos
        }else{
          this.$store.dispatch('show_snackbar', {
            text: articulosPeticion.msj,
            color: 'error',
          })
          return
        }
      }
      // abro el modal
      this.dialogSelectArticulo.activo = true
    },
    setModalSelectArt(value){
      this.dialogSelectArticulo.activo = value
    },
    setArticulo(item){
      this.articulos.push(item.articulo_codigo)
    },
    importarExcel(){
      this.isSelecting = true;

      // After obtaining the focus when closing the FilePicker, return the button state to normal
      window.addEventListener('focus', () => {
          this.isSelecting = false
      }, { once: true });
      
      // Trigger click on the FileInput
      this.$refs.uploader.click();
    },
    async to_json (file) {
      return await new Promise(async (resolve, reject) => {
        try{
          let contenidoArchivo = []
          const reader = new FileReader()
          reader.onload = (e) => {
            const bytes = e.target.result
            const excel = read(bytes, { type: 'binary', cellDates: true })
            // obtiene el nombre de la primer hoja
            const hoja_nombre = excel.SheetNames[0]
            // obtiene la primer hoja
            const hoja = excel.Sheets[hoja_nombre]
            // obtiene la data de la primer hoja
            const data = utils.sheet_to_json(hoja, { header: 1 })
            // cicla por cada row de la primer hoja (excluyendo los headers)
            this.json = []
            for (let i = 1; i < data.length; i++){
              const row = data[i]
              if (row.length > 0){
                let unaRow = {}
                if (row[1] != undefined && row[1] != null && row[1] != '' && row[1] != 'null' &&
                row[0] != undefined && row[0] != null && row[0] != '' && row[0] != 'null' &&
                row[2] != undefined && row[2] != null && row[2] != '' && row[2] != 'null' &&
                row[3] != undefined && row[3] != null && row[3] != '' && row[3] != 'null'){
                  // por codigo de barras
                  unaRow = {
                    promo_nombre: row[0],
                    articulo_codigo: row[1],
                    precio_venta: row[2],
                    precio_descuento: row[3],
                    fecha_inicio: moment(row[4]).format("DD/MM/YYYY"),
                    fecha_fin: moment(row[5]).format("DD/MM/YYYY")
                  }
                  contenidoArchivo.push(unaRow)
                }
              }
            }
            return resolve({
              resultado: 1,
              msj: 'OK',
              contenido: contenidoArchivo
            })
          }
          reader.readAsBinaryString(file)
        }catch(error){
          return resolve({
            resultado: 0,
            msj: 'Ocurrio un problema al ejecutar el metodo to_json: ' + error.message,
            contenido: null
          })
        }
      })
    },
    async onFileChanged(e) {
      this.$store.state.loading = true
      this.selectedFile = e.target.files[0]
      let contenidoPeticion = await this.to_json(this.selectedFile)
      if (contenidoPeticion.resultado == 0){
        this.$store.state.loading = false
        this.$store.dispatch('show_snackbar', {
          text: 'No se pudo leer correctamente el contenido del archivo. Vuelva a intentarlo por favor.',
          color: 'error'
        })
        return
      }
      let generarPeticion = await this.$store.dispatch('promociones/generarPromosFile', {file: contenidoPeticion.contenido})
      this.$store.state.loading = false
      if (generarPeticion.resultado == 1){
        this.$store.dispatch('show_snackbar', {
          text: 'El archivo fué importado correctamente.',
          color: 'success'
        })
      }else{
        this.$store.dispatch('show_snackbar', {
          text: generarPeticion.msj,
          color: 'error'
        })
      }
      // limpio el archivo seleccionado
      this.selectedFile = null
      this.isSelecting = false
    },
  },
  watch: {
    promociones: function(){
      if (this.promociones.length > 0){
        for (let id in this.promociones){
          this.promociones[id].fecha_log = moment(this.promociones[id].fecha_log).format("DD/MM/YYYY")
          this.promociones[id].descuento = roundNumber(this.promociones[id].descuento, 4)
        }
      }
    },
    articulos: function(){
      if (this.articulos.length > 0 && this.empiezaDepurar == 0){
        this.empiezaDepurar = 1
        let error = 0
        for (let id in this.articulos){
          // es valor numérico
          if (isNaN(this.articulos[id])){
            this.articulos.splice(id, 1)
            error = error + 1
          }else{
            // es numérico, está duplicado?
            let unArt = this.articulos.filter(element => element == this.articulos[id])
            if (unArt.length > 1){
              this.articulos.splice(id, 1)
              error = error + 1
            }
          }
        }
        if (parseInt(error) > 0){
          this.$store.dispatch('show_snackbar', {
            text: 'Recuerde ingresar únicamente valores numéricos y no repetir los códigos de artículos.',
            color: 'info',
          })
        }
        this.empiezaDepurar = 0
      }
    },
    '$vuetify.breakpoint.xs'(val){
      this.pantallaChica = val;
    },
  },
}
</script>

<style>

</style>