<template>
  <v-card>
    <v-card-title class="pb-0 pt-2">
      Artículos
      <v-spacer></v-spacer>
      <v-dialog
        v-model="dialog"
        max-width="800px"
        :persistent="load"
        scrollable
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            v-bind="attrs"
            v-on="on"
            class="mt-n10"
            color="info"
            title="Buscar artículo"
            :disabled="disabled"
            small
            fab
          >
            <v-icon small>fas fa-search</v-icon>
          </v-btn>
        </template>
        <v-card :disabled="load">
          <v-card-title>
            Artículos
            <v-spacer></v-spacer>
            <v-btn
              icon
              @click="dialog = false"
            >
              <v-icon>fas fa-times</v-icon>
            </v-btn>
          </v-card-title>
          <v-card-text class="">
            <v-data-table
              class="cebra"
              sort-by="nombre"
              :search="search"
              :loading="load"
              :headers="headers"
              :items="articulos_activos"
              :footer-props="{'items-per-page-options':[10, 15, 50, 100]}"
              dense
            >
              <template v-slot:top>
                <v-row class="pb-2">
                  <v-col cols="12" sm="6">
                    <SearchDataTable
                      v-model="search"
                    />
                  </v-col>
                </v-row>
              </template>
              <template v-slot:[`item.actions`]="{ item }">
                <v-icon
                  color="success"
                  title="Agregar"
                  class="mr-2"
                  small
                  @click="agregar_articulo(item)"
                >
                  fas fa-check-circle
                </v-icon>
              </template>
            </v-data-table>
          </v-card-text>
        </v-card>
      </v-dialog>
    </v-card-title>
    <v-card-text class="pb-0">
      <v-data-table
        class="cebra mt-2"
        sort-by="nombre"
        :headers="headers"
        :items="articulos"
        dense
      >
        <template v-slot:top>
          <v-row class="pb-2">
            <v-col cols="12" sm="3" md="2" class="py-1">
              Cod. Interno
              <v-text-field
                v-model="codigo"
                ref="artCod"
                :readonly="disabled"
                :filled="disabled"
                hide-details
                outlined
                dense
                @blur="get_articulo(codigo)"
                @keypress="enterKey"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="5" md="4" class="py-1">
              Nombre
              <v-text-field
                v-model="nombre"
                hide-details
                outlined
                readonly
                filled
                dense
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="4" md="6" :class="pantallaChica ? 'py-2' : 'py-5'" align="right">
              <v-btn
                color="green darken-3"
                dark
                @click="$refs.input.click()"
                :disabled="disabled"
              >
                <v-icon small left>far fa-file-excel</v-icon>
                Importar Excel
                <input
                  ref="input"
                  type="file"
                  class="d-none"
                  :accept="tipos_validos.join(',')"
                  @change="importar_excel($event)"
                >
              </v-btn>
            </v-col>
          </v-row>
        </template>
        <template v-slot:[`item.actions`]="{ item }">
          <v-icon
            color="error"
            title="Quitar"
            class="mr-2"
            :disabled="disabled"
            small
            @click="quitar_articulo(item)"
          >
            fas fa-times-circle
          </v-icon>
        </template>
        <template v-slot:no-data>
          <v-alert
            class="mx-auto mt-4"
            max-width="400"
            type="warning"
            border="left"
            dense
            text
          >
            No hay artículos seleccionados
          </v-alert>
        </template>
      </v-data-table>
    </v-card-text>
  </v-card>
</template>

<script>
/**
 *  Componente para buscar articulos activos por codigo o seleccionar uno de la lista
 * 
 *  Notas: el prop value se utiliza para sincronizar los articulos que se van cargando
 *         con el componente padre
 */
import { mapState } from 'vuex'
import SearchDataTable from './SearchDataTable'
import { tipos_archivos } from '../../util/utils'
import { read, utils } from 'xlsx'

export default {
  data () {
    return {
      dialog: false,
      load: false,
      codigo: null,
      nombre: null,
      search: '',
      headers: [
        { text: 'Cod. Interno', value: 'codigo', align: 'end' },
        { text: 'Nombre', value: 'nombre' },
        { text: 'Categoría', value: 'categoria_nombre' },
        { text: 'Acciones', value: 'actions', align: 'center', sortable: false, filterable: false },
      ],
      tipos_validos: ['.xls', '.xlsx'],
      cadena_in: '',
      pantallaChica: this.$vuetify.breakpoint.xs,
    }
  },
  props: {
    disabled: {  // prop para habilitar o deshabilitar las acciones del componente
      type: Boolean,
      default: false
    },
    value: Array // array de articulos, peude enviarse vacio o con valores iniciales (obligatorio)
  },
  computed: {
    ...mapState('articulos', ['articulos_activos']),
    articulos: {
      get () {
        return this.value
      },
      set (value) {
        this.$emit('input', value)
      }
    }
  },
  components: {
    SearchDataTable
  },
  watch: {
    async dialog (val) {
      if (val && this.articulos_activos.length == 0) {
        this.load = true
        await this.$store.dispatch('articulos/get_articulos_activos')
        this.load = false
      } else {
        this.search = ''
      }
    },
    '$vuetify.breakpoint.xs'(val){
      this.pantallaChica = val;
    },
  },
  methods: {
    quitar_articulo (articulo) {
      const index = this.articulos.indexOf(articulo)
      this.articulos.splice(index, 1)
    },
    agregar_articulo (articulo, mostrar_nombre) {
      // mostrat nombre se utiliza cuando agrega un art buscando por codigo y no encuentra dicho art. Cuando abre el modal para buscar en la lista, no es necesario utilizarlo
      if (mostrar_nombre) {
        this.nombre = articulo.nombre
      }
      const codigo = articulo.codigo
      const existe = this.articulos.find(a => a.codigo == codigo)
      // verifica que el articulo no se haya agregado previamente
      if (existe) {
        this.$store.dispatch('show_snackbar', {
          text: 'Ya se agregó el artículo ' + codigo,
          color: 'warning',
          timeout: 2000
        })
      } else {
        this.articulos.push(articulo)
        this.$store.dispatch('show_snackbar', {
          text: 'Artículo ' + codigo + ' agregado correctamente',
          color: 'success',
          timeout: 2000
        })
      }
      this.nombre = null
      this.codigo = null
    },
    async get_articulo (codigo) {
      if (codigo) {
        this.$store.state.loading = true
        await this.$store.dispatch('articulos/get_articulo_activo', codigo)
          .then((res) => {
            this.agregar_articulo(res.data, true)
          })
          .catch(error => {
            this.nombre = null
            this.codigo = null
            this.$store.dispatch('show_snackbar', {
              text: error.message,
              color: 'error',
              timeout: 2000
            })
          })
        this.$store.state.loading = false 
        this.$refs.artCod.focus()
      }
    },
    async get_articulos(){
      this.$store.state.loading = true
      let artsPeticion = await this.$store.dispatch('articulos/get_arts_activos', {cadena_in: this.cadena_in})
      this.$store.state.loading = false
      if (artsPeticion.exito == 0){
        this.$store.dispatch('show_snackbar', {
          text: artsPeticion.message,
          color: 'error'
        })
        return
      }
      if (artsPeticion.articulos.length > 0){
        for (let id in artsPeticion.articulos){
          const existe = this.articulos.find(a => a.codigo == artsPeticion.articulos[id].codigo)
          if (!existe) this.articulos.push(artsPeticion.articulos[id])
        }
        this.$store.dispatch('show_snackbar', {
          text: 'Artículos agregados correctamente',
          color: 'success'
        })
      }
    },
    enterKey (e) {
      if (e.key == 'Enter') document.activeElement.blur()
    },
    importar_excel(event){
      const files = event.target.files
      if (files){
        // obtiene el archivo (en este caso solo puede subir 1)
        const file = files[0]
        const tipo = file.type
        // busca en el array de tipos_archivos el tipo de archivo del archivo que subio
        const tipo_archivo = tipos_archivos.find(a => a.mime == tipo)
        if (tipo_archivo){
          // verifica que pertenezca por lo menos a un tipo de extension existente en el array de tipos
          if (this.tipos_validos.find(a => a == tipo_archivo.extension)) {
            // el archivo que subio es correcto, pasa a convertirlo a json
            this.convertir_json(file)
          }
          else {
            this.$refs.input.value = ''
            // subio un archivo con una extension no valida
            this.$store.dispatch('show_snackbar', {
              text: 'Tipo de archivo no válido',
              color: 'error'
            })
          }
        }else{
          this.$refs.input.value = ''
          // subio un tipo de archivo que no esta registrado en el array tipos_archivos
          this.$store.dispatch('show_snackbar', {
            text: 'Tipo de archivo no válido para el sistema',
            color: 'error'
          })
        }
      }else this.$refs.input.value = ''
    },
    convertir_json (file) {
      try {
        const reader = new FileReader()
        reader.onload = (e) => {
          const bytes = e.target.result
          const excel = read(bytes, { type: 'binary', cellDates: true })
          // obtiene el nombre de la primer hoja
          const hoja_nombre = excel.SheetNames[0]
          // obtiene la primer hoja
          const hoja = excel.Sheets[hoja_nombre]
          // obtiene la data de la primer hoja
          const data = utils.sheet_to_json(hoja, { header: 1 })

          // cicla por cada row de la primer hoja (excluyendo los headers)
          this.cadena_in = ''
          for (let i = 1; i < data.length; i++) {
            if (this.cadena_in.length > 0) this.cadena_in = this.cadena_in + `, `
            const row = data[i]
            // solo si la row tiene algun dato lo agrega al json
            if (row.length > 0) this.cadena_in = this.cadena_in + row[0]
          }
          this.$refs.input.value = ''
          if (this.cadena_in.length > 0) {
            // paso a obtener los artículos
            this.get_articulos()
          }
          else {
            this.cadena_in = null
            this.$store.dispatch('show_snackbar', {
              text: 'El archivo cargado no contiene datos',
              color: 'warning',
              timeout: 3000
            })
          }
        }
        reader.readAsBinaryString(file)
      }
      catch (error) {
        this.json = null
        // this.nombre = ''
        this.$store.dispatch('show_snackbar', {
          text: 'Ocurrio un error inesperado al convertir el archivo a JSON: ' + error,
          color: 'error',
          timeout: 4000
        })
      }
    }
  }
}
</script>