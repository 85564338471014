<template>
  <div class="d-flex">
    <download-excel
      :data="data"
      :name="`${name}.xls`"
      :fields="headers"
    >
      <v-btn
        class="mr-2 my-sm-0 my-1 py-sm-0 py-3"
        title="Descargar Excel"
        :x-small="$vuetify.breakpoint.xs"
        :small="!$vuetify.breakpoint.xs"
        :disabled="data.length == 0"
      >
        Excel
        <v-icon
          color="green darken-3"
          :small="$vuetify.breakpoint.xs"
          right
        >
          fas fa-file-download
        </v-icon>
      </v-btn>
    </download-excel>
    <download-excel
      type="csv"
      :data="data"
      :name="`${name}.csv`"
      :fields="headers"
    >
      <v-btn
        class="mr-2 my-sm-0 my-1 py-sm-0 py-3"
        title="Descargar CSV"
        :x-small="$vuetify.breakpoint.xs"
        :small="!$vuetify.breakpoint.xs"
        :disabled="data.length == 0"
      >
        CSV
        <v-icon
          color="green darken-3"
          :small="$vuetify.breakpoint.xs"
          right
        >
          fas fa-file-download
        </v-icon>
      </v-btn>
    </download-excel>
  </div>
</template>

<script>
/**
 *  Componente para descargar un excel/csv a partir de un json
 */
export default {
  props: {
    data: Array,  // json con los datos
    name: String, // nombre del archivo a descargar
    /**
     *  las cabeceras ademas de indicar el nombre de cada columna, indican los atributros de la data que se van a descargar.
     *  Si un atributo no figura en la cabecera pero si en la data, no se agrega esa columna al archivo descargado
     */
    headers: {
      type: Object,
      default: null
    }
  }
}
</script>