<template>
  <div class="mx-4 pb-4">
    <v-btn
      color="pink accent-1"
      class="mb-3 mr-3"
      :disabled="disabled"
      :dark="!disabled"
      small
      @click="sin()"
    >
      <v-icon left small>fas fa-boxes</v-icon>
      Integral sin series
    </v-btn>
    <v-btn
      color="cyan"
      class="mb-3"
      :disabled="disabled"
      :dark="!disabled"
      small
      @click="con()"
    >
      <v-icon left small>fas fa-barcode</v-icon>
      Integral con series
    </v-btn>
  </div>
</template>

<script>
/**
 *  Modal que selecciona todas las categorias y rubros con o sin serie.
 *  Emite la accion @result al padre con 2 array que contienen los codigos de las categorias y series filtradas
 */
import { mapState } from 'vuex'

export default {
  data () {
    return {
      rubros_filtrados: [],
      categorias_filtradas: []
    }
  },
  async created () {
    this.$store.state.loading = true
    await this.$store.dispatch('rubros/get_rubros_habilitados')
    await this.$store.dispatch('categorias/get_categorias_habilitadas')
    this.$store.state.loading = false
  },
  props: {
    disabled: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...mapState('rubros', ['rubros']),
    ...mapState('categorias', ['categorias'])
  },
  methods: {
    seleccionar_categorias (articulos) {
      this.rubros_filtrados = []
      this.categorias_filtradas = []
      articulos.forEach(art => {
        this.categorias_filtradas.push(art.codigo)
        this.rubros_filtrados.push(art.rubro_codigo)
      })
      this.rubros_filtrados = this.rubros_filtrados.filter((c, index) => {
        return this.rubros_filtrados.indexOf(c) == index
      })
      this.$emit('result', {
        categorias: this.categorias_filtradas,
        rubros: this.rubros_filtrados
      })
    },
    sin () {
      let articulos = this.categorias.filter(c => c.solicita_serie != 1)
      this.seleccionar_categorias(articulos)
    },
    con () {
      let series = this.categorias.filter(c => c.solicita_serie == 1)
      this.seleccionar_categorias(series)
    }
  }
}
</script>