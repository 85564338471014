<script>
import { Line } from 'vue-chartjs'
export default {
  extends: Line,
  props: {
    datos: {
      type: Object,
      default: {}
    },
  },
  computed: {
    labels: {
      get(){
        return this.datos.labels
      }
    },
    data_sets: {
      get(){
        return this.datos.data_sets
      }
    },
    titulo: {
      get(){
        return this.datos.titulo
      }
    }
  },
  mounted(){
    this.renderChart(
      {
        labels: this.labels,
        datasets: this.data_sets
      },
      {
        responsive: true,
        maintainAspectRatio: false,
        title: {
          display: true,
          text: this.titulo
        }
      }
    )
  },
  watch: {
    data_sets: function(){
      this.renderChart(
        {
          labels: this.labels,
          datasets: this.data_sets
        },
        {
          responsive: true,
          maintainAspectRatio: false,
          title: {
            display: true,
            text: this.titulo
          }
        }
      )
    },
    labels: function(){
      this.renderChart(
        {
          labels: this.labels,
          datasets: this.data_sets
        },
        {
          responsive: true,
          maintainAspectRatio: false,
          title: {
            display: true,
            text: this.titulo
          }
        }
      )
    }
  },
}
</script>

<style>

</style>