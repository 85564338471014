<template>
  <div>
    <v-dialog
      v-model="activo"
      max-width="750"
      persistent
    >
    <v-card
        flat class="pb-2"
      >
        <v-card-title>
          Series para el Artículo {{ articulo_codigo }} - {{ articulo_nombre }}
          <v-spacer></v-spacer>
          <v-btn icon :disabled="Math.abs(parseInt(cantidad) - seriesSelect.length) != 0"><v-icon @click="activo = false" title="Cerrar">fas fa-times</v-icon></v-btn>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text class="pt-4 pb-1">
          <!-- Info de las Cantidades para el Usuario -->
          <v-row :no-gutters="$vuetify.breakpoint.xs">
            <v-col cols="12">
              <div class="text-center" v-if="parseInt(cantidad) >= seriesSelect.length">
                <p class="mb-2" style="font-size: 18px;">Cantidad a Ingresar: {{ cantidad }} - Cantidad Ingresada: {{ seriesSelect.length }} - Cantidad Pendiente: {{ parseInt(cantidad) - seriesSelect.length }}</p>
              </div>
              <div v-else>
                <p class="mb-2" style="font-size: 18px;">Cantidad a Ingresar: {{ cantidad }} - Cantidad Ingresada: {{ seriesSelect.length }} - Cantidad a Quitar: {{ seriesSelect.length - parseInt(cantidad) }}</p>
              </div>
            </v-col>
          </v-row>
          <!-- Listado de Series -->
          <v-data-table
            class="cebra elevation-2 mt-1"
            :headers="headers"
            :items="seriesSelect"
            :loading="load"
            dense
            :search="search"
            hide-default-footer
            disable-pagination
          >
            <!-- Serie y Buscador en el datatable -->
            <template v-slot:top>
              <v-row :no-gutters="$vuetify.breakpoint.xs">
                <v-col cols="6">
                  <v-form @submit.prevent="agregarSerie()">
                    <v-text-field
                      v-model.trim="serie"
                      ref="serieRef"
                      @blur="agregarSerie()"
                      outlined
                      dense
                      type="text"
                      hide-details
                      label="Serie"
                      v-if="ver == 0"
                    ></v-text-field>
                  </v-form>
                </v-col>
                <v-col cols="6">
                  <SearchDataTable
                    v-model="search"
                  />
                </v-col>
              </v-row>
            </template>
            <!-- Msj que se mostrara si no existen resultados -->
            <template v-slot:no-data>
              <v-alert
                class="mx-auto mt-4"
                max-width="400"
                type="warning"
                border="left"
                dense
                text
              >
                No se agregaron artículos.
              </v-alert>
            </template>
            <!-- Acciones -->
            <template v-slot:[`item.acciones`]="{ item }">
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-btn small icon color="error" @click="quitarSerie(item)" v-on="on" v-if="ver == 0">
                    <v-icon small>far fa-times-circle</v-icon>
                  </v-btn>
                </template>
                <span>Quitar Serie</span>
              </v-tooltip>
            </template>
          </v-data-table>
        </v-card-text>
        <v-card-actions class="d-flex justify-end mx-2">
          <v-row>
            <v-col cols="6">
              <v-row class="">
                <input
                  ref="input"
                  type="file"
                  class="d-none"
                  :accept="tipos.join(',')"
                  @change="buscar($event)"
                >
                <v-col cols="12" class="d-flex-column justify-center">
                  <v-btn
                    @dragover.prevent="hover = true"
                    @dragleave.prevent="hover = false"
                    @drop.prevent="drop"
                    @click="$refs.input.click()"
                    color="info"
                    title="Arraste o haga click aquí"
                  >
                    <v-icon small class="mr-2">
                      fas fa-folder{{ hover ? '-open' : '' }}
                    </v-icon>
                    Subir archivo
                  </v-btn>
                  {{ nombre }}
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="6" class="d-flex justify-end">
              <v-btn color="warning" @click="activo = false" class="mr-2" v-if="series.length == 0">Volver</v-btn>
              <v-btn color="success" @click="confirmarSeries()" class="mr-2" v-if="parseInt(cantidad) - seriesSelect.length == 0">Confirmar Series</v-btn>
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import SearchDataTable from '../util/SearchDataTable.vue'
import { tipos_archivos } from '../../util/utils';

export default {
  props: {
    datos: {
      type: Object,
      default: {}
    },
  },
  components: {
    SearchDataTable,
  },
  computed: {
    activo: {
      get() {
        return this.datos.activo
      },
      set(value) {
        this.$emit('setearModalIngSeries', value)
      }
    },
    articulo_codigo: {
      get() {
        return this.datos.articulo_codigo
      }
    },
    articulo_nombre: {
      get() {
        return this.datos.articulo_nombre
      }
    },
    series: {
      get() {
        return this.datos.series
      }
    },
    cantidad: {
      get() {
        return this.datos.cantidad
      }
    },
    ver: {
      get() {
        return this.datos.ver
      }
    }
  },
  data() {
    return {
      serie: '',
      headers: [
        { text: 'Serie', align: 'center', value: 'serie' },
        { text: 'Acciones', align: 'center', value: 'acciones' }
      ],
      seriesSelect: [],
      load: false,
      search: '',
      seriePrueba: 123456789012345,
      hover: false,
      tipos: ['.txt'],
      data: null,
      nombre: '',
    }
  },
  methods: {
    initForm(){
      if (this.series.length > 0){
        for (let id in this.series){
          let unaSerie = {
            articulo_codigo: this.series[id].articulo_codigo,
            serie: this.series[id].serie
          }
          this.seriesSelect.push(unaSerie)
        }
      }else{
        // llenar series prueba
        //this.llenarSeries()
      }
    },
    llenarSeries(){
      for (let id = 1; id <= this.cantidad; id++){
        this.seriePrueba = this.seriePrueba + 1
        let unaSerie = {
          articulo_codigo: this.articulo_codigo,
          serie: this.seriePrueba.toString()
        }
        this.seriesSelect.push(unaSerie)
      }
    },
    limpiarForm(){
      this.serie = ''
      this.seriesSelect = []
      this.search = '';
      this.nombre = '';
    },
    agregarSerie(){
      if (this.serie != null && this.serie != undefined && this.serie.toString().length > 0){
        // ya se encuentra en el listado?
        let unaSerie = this.seriesSelect.filter(element => element.serie.toUpperCase() == this.serie.toUpperCase())
        if (unaSerie.length > 0){
          this.$store.dispatch('show_snackbar', {
            text: 'La serie ya se encuentra en el listado.',
            color: 'warning',
          })
          this.serie = ''
          // focus en la serie nuevamente
          this.$refs.serieRef.focus()
          return
        }
        // ya se llegó al la cantidad esperada?
        if (this.seriesSelect.length + 1 > parseInt(this.cantidad)){
          this.$store.dispatch('show_snackbar', {
            text: 'Ya se ingresaron todas las series. No se puede ingresar más.',
            color: 'warning',
          })
          this.serie = ''
          // focus en la serie nuevamente
          this.$refs.serieRef.focus()
          return
        }
        // no existe, paso a armar mi objeto para agregar al listado
        let unArt = {
          articulo_codigo: this.articulo_codigo,
          serie: this.serie
        }
        this.seriesSelect.push(unArt)
        // limpio la serie
        this.serie = ''
        // focus en la serie nuevamente
        this.$refs.serieRef.focus()
      }
    },
    quitarSerie(item){
      let pos = this.seriesSelect.indexOf(item)
      this.seriesSelect.splice(pos, 1)
      // focus en la serie nuevamente
      this.$refs.serieRef.focus()
    },
    confirmarSeries(){
      this.$emit('setSeriesIngresadas', this.seriesSelect)
      this.activo = false
    },
    validar (files) {
      // obtiene el archivo (en este caso solo puede subir 1)
      const file = files[0]
      const tipo = file.type
      this.nombre = file.name

      // busca en el array de tipos_archivos el tipo de archivo del archivo que subio
      const tipo_archivo = tipos_archivos.find(a => a.mime == tipo)

      // verifica que exista el tipo de archivo en el array
      if (tipo_archivo) {
        // verifica que pertenezca por lo menos a un tipo de extension existente en el array de tipos
        if (this.tipos.find(a => a == tipo_archivo.extension)) {
          // el archivo que subio es correcto
          const reader = new FileReader()
          reader.onload = (e) => {
            const bytes = e.target.result
            if (bytes) {
              this.data = bytes
              let codigos = this.data.split("\n");
              codigos.forEach(e => {
                if(e.length > 0){
                  this.serie = e.trim();
                  this.agregarSerie();
                }
              });
            } else {
              this.$store.dispatch('show_snackbar', {
                text: 'Archivo sin datos',
                color: 'warning'
              })
            }
          }
          reader.readAsText(file)
        } else {
          // subio un archivo con una extension no valida
          this.$store.dispatch('show_snackbar', {
            text: 'Tipo de archivo no válido',
            color: 'error'
          })
        }
      } else {
        // subio un tipo de archivo que no esta registrado en el array tipos_archivos
        this.$store.dispatch('show_snackbar', {
          text: 'Tipo de archivo no válido para el sistema',
          color: 'error'
        })
      }
      
      // limpia el input de archivos
      this.$refs.input.value = ''
    },
    drop (event) {
      this.hover = false
      const files = event.dataTransfer.files
      if (files.length > 1) {
        this.$refs.input.value = ''
        this.$store.dispatch('show_snackbar', {
          text: 'Solo puede subir un archivo',
          color: 'error'
        })
        return
      }
      this.validar(files)
    },
    buscar(){
      const files = event.target.files
      if (files) this.validar(files)
    },
  },
  watch: {
    activo: function(){
      if (this.activo == false) this.limpiarForm()
      else this.initForm()
    }
  },
}
</script>

<style>

</style>