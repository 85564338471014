<template>
  <v-dialog
    v-model="dialog"
    fullscreen
  >
    <v-card>
      <v-card-title>
        Escanear código
        <v-spacer></v-spacer>
        <v-btn
          icon
          @click="dialog = false"
        >
          <v-icon>fas fa-times</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text>
        <StreamBarcodeReader @decode="onDecode" @loaded="onLoaded"></StreamBarcodeReader>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
/**
 *  Modal para leer codigos de barra/qr con la camara del celular/tablet
 * 
 *  Nota: emite la accion @result al padre con el resultado de lo que leyó
 * 
 */
import { StreamBarcodeReader } from 'vue-barcode-reader'

export default {
  props: {
    value: Boolean
  },
  computed: {
    dialog: {
      get () {
        return this.value
      },
      set (value) {
        this.$emit('input', value)
      }
    }
  },
  components: {
    StreamBarcodeReader
  },
  methods: {
    onLoaded () {
      console.log('loaded')
    },
    onDecode (result) {
      window.navigator.vibrate(200)
      this.$emit('result', result)
      this.dialog = false
    }
  }
}
</script>