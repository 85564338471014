<template>
  <div>
    <v-dialog
      v-model="activo"
      max-width="60%"
      scrollable
    >
      <v-card
        flat
      >
        <v-card-title>
          <v-icon small class="ma-2">{{ icono }}</v-icon> {{ titulo_modal }}
          <v-spacer></v-spacer>
          <v-btn icon><v-icon @click="activo = false" title="Cerrar">fas fa-times</v-icon></v-btn>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text class="pa-0">
          <div class="text-h6 ml-5 mt-2">
            Archivos existentes
          </div>
          <!-- Listado con los archivos existentes -->
          <v-data-table
            class="elevation-2 mt-2 mx-4"
            :headers="headers"
            :items="archivos"
            :loading="load"
            :search="search"
            dense
          >
            <!-- Buscador en el datatable -->
            <template v-slot:top>
              <v-row class="d-flex justify-end pa-2" no-gutters>
                <v-col cols="6" sm="3">
                  <SearchDataTable
                    v-model="search"
                  />
                </v-col>
              </v-row>
            </template>
            <!-- Msj que se mostrara si no existen resultados -->
            <template v-slot:no-data>
              <v-alert
                class="mx-auto mt-4"
                max-width="400"
                type="warning"
                border="left"
                dense
                text
              >
                Sin Archivos para Mostrar.
              </v-alert>
            </template>
            <!-- Acciones -->
            <template v-slot:[`item.actions`]="{ item }">
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-btn small icon color="success" @click="descargar(item)" v-on="on">
                    <v-icon small>fas fa-download</v-icon>
                  </v-btn>
                </template>
                <span>Descargar</span>
              </v-tooltip>
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-btn small icon color="info" @click="verArchivo(item)" v-on="on">
                    <v-icon small>fas fa-eye</v-icon>
                  </v-btn>
                </template>
                <span>Ver</span>
              </v-tooltip>
              <v-tooltip bottom v-if="permiso_borrar == 1 && !solo_ver">
                <template v-slot:activator="{ on }">
                  <BtnConfirmar
                    icono="fas fa-trash"
                    title="Borrar"
                    color="error"
                    clase="mr-2"
                    :texto="`¿Desea eliminar el archivo <strong>${item.name}</strong>?`"
                    :icon_button="true"
                    :small="true"
                    @action="eliminar(item)"
                    v-on="on"
                  />
                </template>
                <span>Borrar</span>
              </v-tooltip>
            </template>
          </v-data-table>
          <!-- Seccion para dar de Alta los nuevos archivos -->
          <FileDropzone 
            v-if="!solo_ver"
            :key="refrescar"
            :multiple="multiple"
            :tipos="tipos"
            :max_size="max_size"
            :ruta="ruta"
            :alerta="true"
            :accion="true"
            :raiz="raiz"
            :tabla="tabla"
            :id="id"
            @action="agregar_archivo"
            :parametros="parametros"
            :nombre="p_nombre"
          />
        </v-card-text>
        <v-card-actions class="d-flex justify-end pb-4">
          <v-btn
            class="ml-3 mt-2"
            color="error"
            @click="activo = false"
          >
            Cancelar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- Modal Ver Archivo PDF -->
    <PDFViewer
      v-model="dialogPdf"
      :pdf="pdf"
      :nombre="nombre"
      :titulo="nombre"
    />
    <ImgViewer
      v-model="dialogImg"
      :img="img"
      :nombre="nombre"
    />
  </div>
</template>

<script>
import SearchDataTable from '../util/SearchDataTable.vue'
import BtnConfirmar from '../util/BtnConfirmar.vue'
import FileDropzone from '../util/FileDropzone.vue'
import PDFViewer from '../../util/plantillas/PDFViewer.vue'
import ImgViewer from '../util/ImgViewer.vue'
import { tipos_archivos } from '../../util/utils'
export default {
  props: {
    datos: {
      type: Object,
      default: {}
    },
    /*
    Si se envía en true, SOLO permite visualizar los archivos existentes
    Para subir archivos, hay que enviarlo en false o no enviar el props
     */
    solo_ver: { 
      type: Boolean,
      default: false
    },
    p_nombre:{
      type: String,
      default: ''
    }
  },
  components: {
    SearchDataTable,
    BtnConfirmar,
    FileDropzone,
    PDFViewer,
    ImgViewer
  },
  computed: {
    activo: {
      get() {
        return this.datos.activo
      },
      set(value) {
        this.$emit('setearModalUpImg', value)
      }
    },
    icono: {
      get(){
        return this.datos.icono
      }
    },
    titulo_modal: {
      get(){
        return this.datos.titulo_modal
      }
    },
    tipos: {
      get(){
        return this.datos.tipos
      }
    },
    multiple: {
      get(){
        return this.datos.multiple
      }
    },
    max_size: {
      get(){
        return this.datos.max_size
      }
    },
    ruta: {
      get(){
        return this.datos.ruta
      }
    },
    raiz: {
      get(){
        return this.datos.raiz
      }
    },
    tabla: {
      get(){
        return this.datos.tabla
      }
    },
    id: {
      get(){
        return this.datos.id
      }
    },
    permiso_borrar: {
      get(){
        return this.datos.permiso_borrar
      }
    },
    parametros: {
      get(){
        return this.datos.parametros
      }
    }
  },
  data() {
    return {
      headers: [
        { text: 'Nombre', value: 'name' },
        { text: 'Tipo', value: 'type' },
        { text: 'Acciones', value: 'actions', sortable: false, filterable: false, align: 'center' }
      ],
      archivos: [],
      load: false,
      search: '',
      refrescar: 0,
      nombre: '',
      pdf: '',
      img: '',
      dialogPdf: false,
      dialogImg: false
    }
  },
  methods: {
    async initForm(){
      // obtengo los archivos dependiendo de los parametros que me pasaron
      this.load = true
      await this.$store.dispatch('get_all_files', {
        path: this.ruta,
        raiz: 0
      })
        .then(response => {
          this.archivos = response.files
        })
        .catch(error => {
          console.log(error)
          this.$store.dispatch('show_snackbar', {
            text: 'No se encontraron archivos para el contexto actual.',
            color: 'info',
          })
        })
        .finally(() => this.load = false)
    },
    descargar(item){
      let routeData 
      if (this.raiz == 0) routeData = this.$router.resolve({ path: `/descargarArchivo?path=${this.ruta}/${item.name}&raiz=0` })
      else routeData = this.$router.resolve({ path: `/descargarArchivo?path=${this.ruta}/${item.name}` })
      window.open(routeData.href, '_blank')
    },
    verArchivo(item){
      this.nombre = item.name
      const tipo = tipos_archivos.find(a => a.extension == item.type).tipo
      if (tipo == 'PDF') {
        this.pdf = `data:application/pdf;base64,${item.file}`
        this.dialogPdf = true
      }
      else if (tipo == 'IMG') {
        this.img = `data:${tipos_archivos.find(a => a.extension == item.type).mime};base64,${item.file}`
        this.dialogImg = true
      }
    },
    async eliminar(item){
      this.$store.state.loading = true
      await this.$store.dispatch('delete_file', {
        path: `${this.ruta}/${item.name}`,
        raiz: this.raiz,
        tabla: this.tabla,
        id: this.id
      })
        .then((res) => {
          this.archivos.splice(this.archivos.indexOf(item), 1)
          this.$store.dispatch('show_snackbar', {
            text: res.message,
            color: 'success'
          })
        })
        .catch(error => {
          this.$store.dispatch('show_snackbar', {
            text: error.message,
            color: 'error'
          })
        })
      this.$store.state.loading = false
    },
    agregar_archivo(file){
      this.archivos.push(file)
    },
    limpiarForm(){
      this.archivos = []
      this.load = false
      this.search = ''
      this.nombre = ''
      this.pdf = ''
      this.img = ''
      this.dialogPdf = false
      this.dialogImg = false
    }
  },
  watch: {
    activo: function(){
      if (this.activo == false){
        this.refrescar ++
        this.limpiarForm()
      }
      else this.initForm()
    }
  },
}
</script>

<style>

</style>