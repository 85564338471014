<template>
  <v-dialog
    v-model="dialog"
    max-width="600"
    scrollable
  >
    <v-card>
      <v-card-title>
        Escanear series
        <v-spacer></v-spacer>
        <v-btn
          icon
          @click="dialog = false"
        >
          <v-icon>fas fa-times</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-title
        class="pt-0"
        :class="$vuetify.breakpoint.smAndDown ? 'd-flex justify-center' : ''"
      >
        <form
          :style="$vuetify.breakpoint.smAndUp ? 'width: 50%' : 'width: 100%'"
          @submit.prevent="agregar_serie()"
        >
          <v-text-field
            v-model="serie"
            label="Serie"
            ref="serieInput"
            hide-details
            autofocus
            outlined
            dense
          ></v-text-field>
        </form>
        <v-btn
          v-if="$device.android || $device.ios"
          color="indigo"
          class="mt-3 mt-sm-0 ml-0 ml-sm-3"
          small
          dark
          @click="dialog_camera = true"
        >
          <v-icon left small> fas fa-camera</v-icon>
          Cámara
        </v-btn>
      </v-card-title>
      <v-card-text class="py-0">
        <v-simple-table
          class=""
          style=""
          dense
        >
          <template v-slot:default>
            <thead>
              <tr>
                <th class="text-left">Series</th>
                <th class="text-right">Quitar</th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(item, index) in series"
                :key="index"
              >
                <td>{{ item }}</td>
                <td class="text-right">
                  <v-icon
                    color="error"
                    class="pr-1"
                    small
                    left
                    @click="quitar_serie(item)"
                  >
                    fas fa-times
                  </v-icon>
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-card-text>
      <v-card-actions class="d-flex justify-end pb-4">
        <v-btn
          color="error"
          class="mt-2 ml-2"
          @click="dialog = false"
        >
          Cancelar
        </v-btn>
        <BtnConfirmar
          clase="mt-2 ml-2"
          nombre="Confirmar"
          :disabled="series.length < 1"
          @action="procesar()"
        />
      </v-card-actions>
    </v-card>
    <LectorCodigos
      v-model="dialog_camera"
      :key="componentKey"
      @result="agregar"
    />
  </v-dialog>
</template>

<script>
/**
 * Modal para pistolear series
 * 
 *  Nota: cuando guarda emite la accion @procesar al padre la cual devuelve las series correctas, sobrantes e inexistentes (en ese orden)
 * 
 */
import BtnConfirmar from '../util/BtnConfirmar'
import LectorCodigos from '../util/LectorCodigos'

export default {
  data () {
    return {
      dialog_camera: false,
      componentKey: 0,
      serie: '',
      series: []
    }
  },
  props: {
    value: Boolean,
    p_id: Number    // id del control de stock, obligatorio
  },
  computed: {
    dialog: {
      get () {
        return this.value
      },
      set (value) {
        this.$emit('input', value)
      }
    }
  },
  components: {
    BtnConfirmar,
    LectorCodigos
  },
  watch: {
    dialog (val) {
      if (val) {
        // revisa si existen series almacenadas para este control en el local storage
        const item = localStorage.getItem(`css${this.p_id}`)
        if (item) {
          this.series = JSON.parse(item)
        }
      } else {
        this.serie = ''
      }
    },
    dialog_camera (val) {
      val || (this.componentKey += 1)
    }
  },
  methods: {
    async agregar (res) {
      this.serie = res
      this.agregar_serie()
      await new Promise(resolve => setTimeout(resolve, 200))
      await this.$swal.fire({
        icon: 'success',
        title: 'Serie escaneada correctamente',
        showConfirmButton: false,
        timer: 1000
      })
      this.dialog_camera = true
    },
    async procesar () {
      this.$store.state.loading = true
      await this.$store.dispatch('controlStock/carga_asistida', {
        series: this.series,
        id: this.p_id
      })
        .then((res) => {
          this.dialog = false
          this.$store.dispatch('show_snackbar', {
            text: res.message,
            color: 'success'
          })
          this.$emit('procesar', res.correctas, res.sobrantes, res.inexistentes)
          // limpia el local storage
          this.series = []
          this.set_localstorage(this.series)
        })
        .catch(error => {
          this.$store.dispatch('show_snackbar', {
            text: error.message,
            color: 'error'
          })
        })
      this.$store.state.loading = false
    },
    async agregar_serie () {
      if (this.serie) {
        // solo agrega la serie si no existe previamente en el array
        if (!this.series.find(s => s == this.serie)) {
          this.series.unshift(this.serie)
        }
        this.serie = ''
        // copia el array en el localstorage
        this.set_localstorage(this.series)
      }
    },
    quitar_serie (item) {
      this.$refs.serieInput.focus()
      const index = this.series.indexOf(item)
      this.series.splice(index, 1)
      // copia el array en el localstorage
      this.set_localstorage(this.series)
    },
    set_localstorage (array) {
      const key = `css${this.p_id}`
      // si el array es 0 elimina el item del localstorage
      if (array.length == 0) {
        localStorage.removeItem(key)
      } else {
        localStorage.setItem(key, JSON.stringify(array))
      }
    }
  }
}
</script>