<template>
  <v-dialog
    v-model="activo"
    max-width="400px"
  >
    <v-card>
      <v-card-title>
        {{ titulo }}
        <v-spacer></v-spacer>
        <v-btn
          icon
          @click="activo = false"
        >
          <v-icon>fas fa-times</v-icon>
        </v-btn>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text class="body-1 pt-4">
        <span v-html="texto"></span>
      </v-card-text>
      <v-card-actions class="d-flex justify-end pb-4">
        <v-btn
          color="success"
          class="mr-2"
          @click="confirmar"
        >
          Sí
        </v-btn>
        <v-btn
          color="error"
          @click="activo = false"
        >
          No
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
/**
 *  Componente botón que muestra un modal para confirmar una accion,
 *  la funcion que realiza en caso de que seleccione el boton 'Sí'
 *  se define en el padre mediante: @action="funcion"
 */
export default {
  props: {
    activoDialog: {
      type: Boolean,
      default: false,
    },
    // color del boton
    color: {
      type: String,
      default: 'success',
    },
    // titulo del modal
    titulo: {
      type: String,
      default: 'Confirmar',
    },
    // texto del modal
    texto: {
      type: String,
      default: '¿Desea guardar los cambios realizados?',
    }
  },
  computed: {
    activo: {
      get() {
        return this.activoDialog
      },
      set(value) {
        this.$emit('setearModalConfAccion', value)
      }
    },
  },
  methods: {
    confirmar () {
      this.$emit('action', true)
      this.activo = false
    }
  }
}
</script>