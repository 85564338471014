<template>
  <div class="chart-wrapper">
    <canvas :id="id"></canvas>
  </div>
</template>

<script>
import Chart from 'chart.js'
export default {
  props: {
    datos: {
      type: Object,
      default: {}
    },
  },
  computed: {
    labels: {
      get(){
        return this.datos.labels
      }
    },
    data_sets: {
      get(){
        return this.datos.data_sets
      }
    },
    id: {
      get(){
        return this.datos.id
      }
    }
  },
  mounted () {
    // const ctx = document.getElementById(this.id)
    // new Chart(ctx, this.planetChartData)
  },
  data() {
    return {
      planetChartData: {
        type: "bubble",
        data: {
          labels: [],
          datasets: []
        },
        options: {
          responsive: true,
          maintainAspectRatio: false,
          scales: {
            yAxes: [{
              ticks: {
                beginAtZero: true
              },
              gridLines: {
                display: true
              },
              scaleLabel: {
                display: true,
                labelString: 'GMROS'
              }
            }],
            xAxes: [{
              ticks: {
                beginAtZero: true
              },
              gridLines: {
                display: true
              },
              scaleLabel: {
                display: true,
                labelString: 'GMROI'
              }
            }]
          },
          legend: {
            display: true
          },
          title:{
            display: true,
            text:'GMROS vs. GMROI'
          }
        }
      }
    }
  },
  watch: {
    labels: function(){
      this.planetChartData.data.labels = this.labels
      this.planetChartData.data.datasets = this.data_sets
      const ctx = document.getElementById(this.id)
      new Chart(ctx, this.planetChartData)
    },
    data_sets: function(){
      this.planetChartData.data.labels = this.labels
      this.planetChartData.data.datasets = this.data_sets
      const ctx = document.getElementById(this.id)
      new Chart(ctx, this.planetChartData)
    }
  },
}
</script>

<style scoped>
  .chart-wrapper {
    border: 1px solid blue;
    height: 700px;
  }
</style>