<template>
  <div>
    <v-dialog
      v-model="activo"
      max-width="60%"
    >
      <v-card
        flat
      >
        <v-card-title
          class="pt-0"
          :class="$vuetify.breakpoint.smAndDown ? 'd-flex justify-center' : ''"
        >
          Carga Asistida para Recepciones
          <v-spacer></v-spacer>
          <v-btn icon><v-icon @click="activo = false" title="Cerrar">fas fa-times</v-icon></v-btn>
        </v-card-title>
        <br/>
        <v-card-text>
          <!-- Artículo a agregar y switch del metodo de ingreso -->
          <v-row :no-gutters="$vuetify.breakpoint.xs">
            <v-col cols="12" sm="6" md="4" class="py-0">
              <v-form @submit.prevent="agregarInput()">
                <v-col cols="12" class="py-0">
                  <div v-if="tipo_ingreso == false">Por Código de Artículos</div>
                  <div v-else>Por Código de Barras</div>
                  <v-text-field
                    v-model="articulo"
                    ref="refArt"
                    @blur="agregarInput()"
                    outlined
                    dense
                    :type="tipo_ingreso == false ? 'number' : 'text'"
                    autofocus
                  ></v-text-field>
                </v-col>
              </v-form>
            </v-col>
            <v-col cols="12" sm="6" md="4" class="py-0">
              <br/>
              <v-switch
                v-model="tipo_ingreso"
                :label="tipo_ingreso == false ? 'Por Código de Artículos' : 'Por Código de Barras'"
                class="d-flex align-center py-0 my-0"
                hide-details
                dense
              ></v-switch>
            </v-col>
          </v-row>
          <!-- Listado de Artículos -->
          <v-row :no-gutters="$vuetify.breakpoint.xs">
            <v-col cols="12">
              <v-data-table
                class="cebra elevation-2"
                :headers="pre_recepcion == 0 ? headers : headerss"
                :items="detalles"
                :loading="load"
                dense
                :search="search"
              >
                <!-- Buscador en el datatable -->
                <template v-slot:top>
                  <v-row class="d-flex justify-end pa-2" no-gutters>
                    <v-col cols="6" sm="3">
                      <SearchDataTable
                        v-model="search"
                      />
                    </v-col>
                  </v-row>
                </template>
                <!-- Msj que se mostrara si no existen resultados -->
                <template v-slot:no-data>
                  <v-alert
                    class="mx-auto mt-4"
                    type="warning"
                    border="left"
                    dense
                    text
                  >
                    Aún no se escanearon productos.
                  </v-alert>
                </template>
                <!-- Cantidad -->
                <template v-slot:[`item.cantidad`]="{ item }">
                  <v-row class="d-flex justify-center" no-gutters>
                    <v-text-field v-model="item.cantidad" type="number" outlined dense style="width: 50px" hide-details @blur="controlCantidades()"></v-text-field>
                  </v-row>
                </template>
                <!-- Acciones -->
                <template v-slot:[`item.acciones`]="{ item }">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                      <v-btn small icon color="error" @click="quitarArticulo(item)" v-on="on">
                        <v-icon small>far fa-times-circle</v-icon>
                      </v-btn>
                    </template>
                    <span>Quitar</span>
                  </v-tooltip>
                  <v-tooltip bottom v-if="item.para_asociar == 1">
                    <template v-slot:activator="{ on }">
                      <v-btn small icon color="warning" @click="asociarArticulo(item)" v-on="on">
                        <v-icon small>fas fa-exclamation-circle</v-icon>
                      </v-btn>
                    </template>
                    <span>Pendiente de Asociar</span>
                  </v-tooltip>
                  <v-tooltip bottom v-if="item.asociar_ean == 1 && pre_recepcion == 1">
                    <template v-slot:activator="{ on }">
                      <v-btn small icon color="warning" @click="asociarArticulo(item)" v-on="on">
                        <v-icon small>fas fa-exclamation-triangle</v-icon>
                      </v-btn>
                    </template>
                    <span>Pendiente de Asociar EAN</span>
                  </v-tooltip>
                  <v-tooltip bottom v-if="item.cruce_ok == 0 && pre_recepcion == 1">
                    <template v-slot:activator="{ on }">
                      <v-btn small icon color="info" @click="asociarOrdenDet(item)" v-on="on">
                        <v-icon small>fas fa-exclamation</v-icon>
                      </v-btn>
                    </template>
                    <span>Pendiente de Asociar con la Orden</span>
                  </v-tooltip>
                  <v-tooltip bottom v-if="item.cruce_ok == 1 && pre_recepcion == 1">
                    <template v-slot:activator="{ on }">
                      <v-btn small icon color="success" v-on="on">
                        <v-icon small>far fa-check-circle</v-icon>
                      </v-btn>
                    </template>
                    <span>Ok Orden de Compra</span>
                  </v-tooltip>
                  <v-tooltip bottom v-if="item.duplicado_ok == 0 && pre_recepcion == 1">
                    <template v-slot:activator="{ on }">
                      <v-btn small icon color="warning" @click="resolverDuplicado()" v-on="on">
                        <v-icon small>fas fa-exchange-alt</v-icon>
                      </v-btn>
                    </template>
                    <span>Duplicado EAN</span>
                  </v-tooltip>
                </template>
                <!-- ultima row para el total -->
                <template slot="body.append">
                  <tr class="font-weight-bold">
                    <th class="text-center">Cant. Art: {{ cantidadArts }}</th>
                    <th></th>
                    <th class="text-center" v-if="pre_recepcion == 0">Cant. Total: {{ cantidadTotal }}</th>
                    <th v-if="pre_recepcion == 1"></th>
                    <th v-if="pre_recepcion == 1"></th>
                    <th class="text-center" v-if="pre_recepcion == 1">Cant. Total: {{ cantidadTotal }}</th>
                    <th></th>
                  </tr>
                </template>
              </v-data-table>
            </v-col>
          </v-row>
          <!-- Orden de Compra Asociada -->
          <v-row :no-gutters="$vuetify.breakpoint.xs" v-if="pre_recepcion == 1" justify="center" class="mt-4">
            <v-col cols="12" sm="8" md="8" class="py-0">
              <v-autocomplete
                v-model="orden_compra"
                item-text="orden_descripcion"
                :items="ordenes_compra"
                hide-details
                outlined
                dense
                return-object
                label="Orden de Compra"
              ></v-autocomplete>
            </v-col>
            <v-col cols="12" sm="2" md="2" class="mt-0 pt-0">
              <v-btn small color="success" title="Descargar Detalles de la Orden de Compra" @click="generarExcel()" v-if="Object.keys(orden_compra).length > 0"><v-icon class="ma-2" small>far fa-file-excel</v-icon> Excel</v-btn>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions class="d-flex justify-end mx-2">
          <v-btn color="warning" class="mr-2" @click="activo = false">Volver</v-btn>
          <BtnConfirmar 
            texto="¿Confirma agregar los artículos al detalle de la Recepción?"
            nombre="Agregar"
            icono="fas fa-check"
            @action="confirmarArticulos()"
            v-if="detalles.length > 0 && pre_recepcion == 0"
          />
          <BtnConfirmar 
            texto="¿Confirma procesar los detalles ingresados?"
            nombre="Procesar"
            icono="fas fa-sync-alt"
            @action="procesarArticulos()"
            v-if="detalles.length > 0 && pre_recepcion == 1"
          />
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- Modal Seleccionar un Artículo (por código de barras repetido) -->
    <SeleccionarArticulo 
      :dialogActivo="dialogSelectArticulo"
      :detallesArts="selectArticulos"
      @setearModalSelectArt="setModalSelectArt"
      @seleccionarArt="setArtSeleccionado"
    />
    <!-- Modal Seleccionar un Artículo (para asociar) -->
    <ModalSelectArticulo 
      :datos="dialogSelecArticulo"
      @setearModalSelectArt="setModalSelecArt"
      @setearArticulo="setArticulo"
    />
    <!-- Modal Confirmar Acción -->
    <ModalConfirmar 
      :activoDialog="dialogConfAsociar"
      :texto="`¿Está seguro de Asociar el Artículo ${selectedArt.articulo_codigo} al Código de Barras '${selectedItem.articulo_cod_barras}' para el Proveedor ${proveedor_nombre}?`"
      @action="asociarArt()"
      @setearModalConfAccion="setModalAsociar"
    />
    <!-- Modal Seleccionar Detalles de Ordenes de Compra -->
    <ModalDetPendOC 
      :datos="dialogDetsPendASociar"
      @setearModalDetPendOC="setModalDetPendOC"
      @setDetPendiente="setDetOC"
    />
  </div>
</template>

<script>
import SearchDataTable from '../util/SearchDataTable.vue'
import SeleccionarArticulo from '../generales/SeleccionarArticulo.vue'
import BtnConfirmar from '../util/BtnConfirmar.vue'
import ModalSelectArticulo from '../util/ModalSelectArticulo.vue'
import ModalConfirmar from '../util/ModalConfirmar.vue'
import ModalDetPendOC from './ModalDetPendOC.vue'
import download from 'downloadjs'
import { tipos_archivos } from '../../util/utils'

export default {
  props: {
    datos: {
      type: Object,
      default: {}
    },
  },
  components: {
    SearchDataTable,
    SeleccionarArticulo,
    BtnConfirmar,
    ModalSelectArticulo,
    ModalConfirmar,
    ModalDetPendOC
  },
  computed: {
    activo: {
      get() {
        return this.datos.activo
      },
      set(value) {
        this.$emit('setearModalCarAsistRecep', value)
      }
    },
    tipo_ingreso: {
      get() {
        return this.datos.tipo_ingreso
      },
      set(value) {
        this.$emit('setearTipIngCarAsistida', value)
      }
    },
    articulos: {  // esto ya me trae los articulos con sus codigos de barra y los codigos ean
      get() {
        return this.datos.articulos
      }
    },
    proveedor_codigo: {
      get() {
        return this.datos.proveedor_codigo
      }
    },
    proveedor_nombre: {
      get() {
        return this.datos.proveedor_nombre
      }
    },
    pre_recepcion: {
      get() {
        return this.datos.pre_recepcion
      }
    },
    orden_compra: {
      get() {
        return this.datos.orden_compra
      },
      set(value) {
        this.$emit('setearOrdCompra', value)
      }
    },
    ordenes_compra: {
      get() {
        return this.datos.ordenes_compra
      }
    },
    deposito_codigo: {
      get() {
        return this.datos.deposito_codigo
      }
    }
  },
  data() {
    return {
      articulo: '',
      headers: [
        { text: 'Artículo Código', align: 'center', value: 'articulo_codigo' },
        { text: 'Código Barras', align: 'center', value: 'articulo_cod_barras' },
        { text: 'Cantidad', align: 'center', value: 'cantidad' },
        { text: 'Acciones', align: 'center', value: 'acciones' }
      ],
      headerss: [
        { text: 'Artículo Código', align: 'center', value: 'articulo_codigo' },
        { text: 'Código Barras', align: 'center', value: 'articulo_cod_barras' },
        { text: 'Código EAN', align: 'center', value: 'codigo_ean' },
        { text: 'Cantidad en OC.', align: 'center', value: 'cantidad_oc' },
        { text: 'Cantidad', align: 'center', value: 'cantidad' },
        { text: 'Acciones', align: 'center', value: 'acciones' }
      ],
      detalles: [],
      load: false,
      search: '',
      dialogSelectArticulo: false,
      selectArticulos: [],
      dialogSelecArticulo: {
        activo: false,
        articulos: [],
        bloquear_cant: true,
        bloquear_precio: true,
        cerrar_seleccion: true,
        titulo_modal: 'Seleccione un artículo para asociar el código de barras ',
        color: 'rowwar'
      },
      dialogDetsPendASociar: {
        activo: false,
        detsOrdCompPend: []
      },
      selectedItem: {},
      dialogConfAsociar: false,
      selectedArt: {},
      repetidos: [],
      cantidadArts: 0,
      cantidadTotal: 0,
    }
  },
  methods: {
    initForm(){
      if (localStorage.getItem('CargaAsistRecep')){
        let datos = JSON.parse(localStorage.getItem('CargaAsistRecep'))
        // están ingresando artículos del mismo proveedor?
        if (datos.proveedor_codigo != this.proveedor_codigo){
          // ingresan con un código de proveedor distinto al último que se guardó en localStorage, vacío localStorage
          localStorage.removeItem('CargaAsistRecep')
          this.detalles = []
        }else{
          // coinciden los proveedores, asigno los artículos que estuvieron pistoleando
          if (datos.detalles.length > 0){
            this.detalles = datos.detalles
            // obtengo los totales
            this.calcularTotales()
            // aqui tengo que tener cuidado, puesto que puede que tenga algun codigo de barras o ean vacio
            let vacios = []
            if (this.pre_recepcion == 1){
              // controlo que no tenga codigos ean vacios
              vacios = this.detalles.filter(element => (element.codigo_ean == null || element.codigo_ean == undefined || element.codigo_ean.toString().length == 0) && (element.articulo_codigo == null || element.articulo_codigo == undefined || element.articulo_codigo.toString().length == 0))
            }else{
              // controlo por codigos de barra vacios
              vacios = this.detalles.filter(element => (element.articulo_cod_barras == null || element.articulo_cod_barras == undefined || element.articulo_cod_barras.toString().length == 0) && (element.articulo_codigo == null || element.articulo_codigo == undefined || element.articulo_codigo.toString().length == 0))
            }
            if (vacios.length > 0){
              for (let id in vacios){
                let pos = this.detalles.indexOf(vacios[id])
                this.detalles.splice(pos, 1)
              }
            }
          }
        }
        this.controlAsociarEAN(0)
      }
    },
    calcularTotales(){
      this.cantidadTotal = 0
      this.cantidadArts = 0
      this.cantidadArts = this.detalles.length
      for (let id in this.detalles){
        this.cantidadTotal = this.cantidadTotal + parseInt(this.detalles[id].cantidad)
      }
    },
    controlCantidades(){
      // recalculo cantidades
      this.calcularTotales()
      // guardo en localStorage
      this.guardarLocalStorage(1)
    },
    agregarInput(){
      if (this.articulo != null && this.articulo != undefined && this.articulo.toString().length > 0){
        // está ingresando por código de artículo o barras
        let unArt = []
        // bandera que me va a indicar si el usuario debe asociar el artículo al código de barras
        let paraAsociar = 0
        let paraAsociar2 = 0
        if (this.tipo_ingreso == false) unArt = this.articulos.filter(element => element.articulo_codigo == this.articulo)
        else{
          if (this.pre_recepcion == 0) unArt = this.articulos.filter(element => element.articulo_cod_barras != null && element.articulo_cod_barras != undefined && element.articulo_cod_barras.toString().toUpperCase() == this.articulo.toString().toUpperCase())
          else unArt = this.articulos.filter(element => element.codigo_ean != null && element.codigo_ean != undefined && element.codigo_ean.toString().toUpperCase() == this.articulo.toString().toUpperCase())
        }
        // encontré algo?
        if (unArt.length == 0){
          // controlo a ver si están ingresando con código de barras
          if (this.tipo_ingreso == true){
            // es con código de barras, al momento de agregar el detalle lo voy a marcar para que asocie el código de barras al artículo si o si
            if (this.pre_recepcion == 0) paraAsociar = 1
            else paraAsociar2 = 1
          }else{
            // intentó agregar por código de artículo y el mismo no existe en el listado, no lo agrego
            this.$store.dispatch('show_snackbar', {
              text: 'El artículo escaneado no existe en el sistema.',
              color: 'info',
            })
            this.articulo = ''
            this.$refs.refArt.focus()
            return
          }
        }
        // tengo más de un artículo para seleccionar? solo me interesa cuando el código de barras está asociado a más de un código de BB
        if (unArt.length > 1 && this.tipo_ingreso == true){
          // debo abrir el modal para que el usuario escoja el artículo correcto, armo la estructura que necesito para el modal seleccionar artículo
          this.selectArticulos = []
          for (let id in unArt){
            let unDet = {
              articulo_codigo: unArt[id].articulo_codigo,
              articulo_nombre: unArt[id].articulo_nombre,
              codigo_barras: unArt[id].articulo_cod_barras,
              codigo_ean: unArt[id].codigo_ean
            }
            this.selectArticulos.push(unDet)
          }
          // abro el modal
          this.dialogSelectArticulo = true
          return
        }
        // si llego hasta aquí tengo dos casos:
        // 1 - escaneron por código de BB: entonces si llegaron aquí, si o si tengo al menos 1 artículo en unArt, debería quedarme con el que tenga código de barras si es 
        //     que tuviera asociado.
        // 2 - escaneron por código de Barras: tengo sólo 1 artículo en unArt (ya que si hubiera tenido más de 1 hubiera ido al modal de seleccionar artículo) o bien podría
        //     no tener ningún artículo en unArt. En el caso de no tener nada, de todas formas debo agregar al detalle indicando que el usuario debe asociar ese código de barras
        //     a un artículo de BB existente en el sistema.
        let unDetalle = []
        if (this.tipo_ingreso == false) unDetalle = this.detalles.filter(element => element.articulo_codigo == unArt[0].articulo_codigo)
        else{
          if (this.pre_recepcion == 0){
            // tengo algo en unArt? si no tengo nada, entonces filtro por la variable global articulo
            if (unArt.length > 0) unDetalle = this.detalles.filter(element => element.articulo_cod_barras != null && element.articulo_cod_barras != undefined && element.articulo_cod_barras.toString().toUpperCase() == unArt[0].articulo_cod_barras.toString().toUpperCase())
            else unDetalle = this.detalles.filter(element => element.articulo_cod_barras != null && element.articulo_cod_barras != undefined && element.articulo_cod_barras.toString().toUpperCase() == this.articulo.toString().toUpperCase())
          }else{
            // tengo algo en unArt? si no tengo nada, entonces filtro por la variable global articulo
            if (unArt.length > 0) unDetalle = this.detalles.filter(element => element.codigo_ean != null && element.codigo_ean != undefined && element.codigo_ean.toString().toUpperCase() == unArt[0].codigo_ean.toString().toUpperCase())
            else unDetalle = this.detalles.filter(element => element.codigo_ean != null && element.codigo_ean != undefined && element.codigo_ean.toString().toUpperCase() == this.articulo.toString().toUpperCase())
          }
        }
        // controlo a ver si ya se encuentra en el listado
        if (unDetalle.length > 0){
          // ya existe en el listado, lo acumulo
          let pos = this.detalles.indexOf(unDetalle[0])
          let det = this.detalles[pos]
          det.cantidad = parseInt(det.cantidad) + 1
          Object.assign(this.detalles[pos], det)
        }else{
          // no existe, debo agregarlo pero aquí cuidado porque tengo que ver si está pendiente de asociar
          if (paraAsociar == 1 || paraAsociar2 == 1){
            // agrego al detalle indicando que deben asociar el código de barras a un artículo
            let unObj = {
              articulo_cod_barras: paraAsociar == 1 ? this.articulo : '',
              articulo_codigo: '',
              articulo_nombre: '',
              cantidad: 1,
              categoria_codigo: '',
              detallado: '',
              codigo_ean: paraAsociar2 == 1 ? this.articulo : '',
              para_asociar: paraAsociar,
              asociar_ean: paraAsociar2,
              cantidad_oc: 0,
              cruce_ok: -1,
              duplicado_ok: -1
            }
            this.detalles.push(unObj)
          }else{
            let det = unArt[0]
            // agrego normalmente quedandome preferentemente con el artículo que tenga código de barras
            if (unArt.length > 1){
              if (this.pre_recepcion == 0){
                // intento quedarme con el detalle con código de barras no vacío
                let conCodBarras = unArt.filter(element => element.articulo_cod_barras != null && element.articulo_cod_barras != undefined && element.articulo_cod_barras.toString().length > 0)
                if (conCodBarras.length > 0) det = conCodBarras[0]
              }else{
                // intento quedarme con el detalle con código ean no vacío
                let conCodBarras = unArt.filter(element => element.codigo_ean != null && element.codigo_ean != undefined && element.codigo_ean.toString().length > 0)
                if (conCodBarras.length > 0) det = conCodBarras[0]
              }
            }
            det.para_asociar = 0
            det.asociar_ean = 0
            det.cruce_ok = -1
            det.duplicado_ok = -1
            if (det.cantidad == 0 || det.cantidad == null || det.cantidad == undefined || det.cantidad.toString().length == 0) det.cantidad = 1
            this.detalles.push(det)
          }
        }
        // limpio artículo y hago foco nuevamente
        this.articulo = ''
        // calculo totales
        this.calcularTotales()
        // controlo la consistencia de los detalles
        this.controlAsociarEAN(1)
        // guardo en localStorage
        this.guardarLocalStorage(1)
      }
    },
    quitarArticulo(item){
      let pos = this.detalles.indexOf(item)
      this.detalles.splice(pos, 1)
      // vuelvo el foco a articulo
      this.$refs.refArt.focus()
      // calculo totales
      this.calcularTotales()
      // comentados el 11-08-2023 por MMURILLO ya que esto se va a hacer en el watch de detalles
      // this.guardarLocalStorage()
      this.controlAsociarEAN(1)
    },
    setModalSelectArt(value){
      this.dialogSelectArticulo = value
      if (this.dialogSelectArticulo == false) this.selectArticulos = []
    },
    setArtSeleccionado(item){
      // si vinieron por aqui, el item que llega si o si tiene código de artículo y código de barras, por lo tanto filtro a ver si ya lo tengo agregado en el detalle
      let unDet = []
      if (this.pre_recepcion == 0) unDet = this.detalles.filter(element => element.articulo_cod_barras != null && element.articulo_cod_barras != undefined && element.articulo_cod_barras.toString().length > 0 && element.articulo_cod_barras.toString().toUpperCase() == item.codigo_barras.toString().toUpperCase() && element.articulo_codigo == item.articulo_codigo)
      else{
        // tener cuidado porque no necesariamente tiene codigo de articulo el detalle
        unDet = this.detalles.filter(element => element.codigo_ean != null && element.codigo_ean != undefined && element.codigo_ean.toString().length > 0 && element.codigo_ean.toString().toUpperCase() == item.codigo_ean.toString().toUpperCase() && element.articulo_codigo == item.articulo_codigo)
        if (unDet.length == 0) unDet = this.detalles.filter(element => element.codigo_ean != null && element.codigo_ean != undefined && element.codigo_ean.toString().length > 0 && element.codigo_ean.toString().toUpperCase() == item.codigo_ean.toString().toUpperCase() && (element.articulo_codigo == null || element.articulo_codigo == undefined || element.articulo_codigo.toString().length == 0))
      }
      if (unDet.length > 0){
        // ya existe en el detalle, lo acumulo
        let pos = this.detalles.indexOf(unDet[0])
        // comparo pos del registro que voy a acumular con la pos del registro original
        let posOriginal = this.detalles.indexOf(this.selectedItem)
        if (pos != posOriginal && posOriginal != -1){
          // como voy a acumular en la posicion pos, quito el detalle en posOriginal
          this.detalles.splice(posOriginal, 1)
        }
        let det = this.detalles[pos]
        det.articulo_codigo = item.articulo_codigo
        det.articulo_cod_barras = item.codigo_barras
        if (this.pre_recepcion == 0) det.cantidad = parseInt(det.cantidad) + 1
        // cruce con la orden de compra
        det.cruce_ok = -1
        // control de duplicidad
        det.duplicado_ok = 1
        Object.assign(this.detalles[pos], det)
      }else{
        // no existe, por lo tanto debo agregarlo. Ojo: como el item viene de la variable global articulos, primero encuentro el registro en ese array y a ese registro lo agrego
        let unArt = []
        if (this.pre_recepcion == 0) unArt = this.articulos.filter(element => element.articulo_cod_barras != null && element.articulo_cod_barras != undefined && element.articulo_cod_barras.toString().length > 0 && element.articulo_cod_barras.toString().toUpperCase() == item.codigo_barras.toString().toUpperCase() && element.articulo_codigo == item.articulo_codigo)
        else{
          // tener cuidado porque no necesariamente tiene codigo de articulo el detalle
          unArt = this.articulos.filter(element => element.codigo_ean != null && element.codigo_ean != undefined && element.codigo_ean.toString().length > 0 && element.codigo_ean.toString().toUpperCase() == item.codigo_ean.toString().toUpperCase() && element.articulo_codigo == item.articulo_codigo)
          if (unArt.length == 0) unArt = this.articulos.filter(element => element.codigo_ean != null && element.codigo_ean != undefined && element.codigo_ean.toString().length > 0 && element.codigo_ean.toString().toUpperCase() == item.codigo_ean.toString().toUpperCase())
        }
        if (unArt.length == 0){
          this.$store.dispatch('show_snackbar', {
            text: 'No se pudo determinar el artículo a agregar.',
            color: 'info',
          })
          return
        }
        let det = unArt[0]
        det.cruce_ok = -1
        det.duplicado_ok = 1
        if (det.cantidad == 0 || det.cantidad == null || det.cantidad == undefined || det.cantidad.toString().length == 0) det.cantidad = 1
        this.detalles.push(det)
      }
      // reseteo item
      this.selectedItem = {}
      // limpio articulo
      this.articulo = ''
      // calculo totales
      this.calcularTotales()
      // controlo la consistencia de los detalles
      this.controlAsociarEAN(1)
    },
    guardarLocalStorage(opcion){
      // quito la clave si es que ya existe
      if (localStorage.getItem('CargaAsistRecep')){
        localStorage.removeItem('CargaAsistRecep')
      }
      // armo mi nuevo objeto
      let asistido = {
        proveedor_codigo: this.proveedor_codigo,
        detalles: this.detalles
      }
      // agrego la nueva
      localStorage.setItem('CargaAsistRecep', JSON.stringify(asistido))
      // devuelvo el foco a articulo
      if (opcion == 1) this.$refs.refArt.focus()
    },
    confirmarArticulos(){
      // tengo detalles pendientes de asociar?
      let pendAsociar = this.detalles.filter(element => element.para_asociar == 1)
      if (pendAsociar.length > 0){
        this.$store.dispatch('show_snackbar', {
          text: 'Existen detalles pendientes de asociar. No se puede agregar.',
          color: 'warning',
        })
        return
      }
      let dets = this.detalles
      this.$emit('setArtsAsistidos', dets)
      // limpio localStorage
      if (localStorage.getItem('CargaAsistRecep')) localStorage.removeItem('CargaAsistRecep')
      // cierro el modal
      this.activo = false
    },
    async procesarArticulos(){
      // reseteo los detalles de orden de compra pendientes de asociar
      this.dialogDetsPendASociar.detsOrdCompPend = []
      // inicializo los repetidos
      this.repetidos = []
      for (let id in this.detalles){
        // indico que cada detalle esta pendiente de cruzar
        if (this.detalles[id].cruce_ok != 1) this.detalles[id].cruce_ok = -1
        // si no se le verificó la duplicidad a un detalle, lo marco como pendiente de verificar el duplicado
        if (this.detalles[id].duplicado_ok != 1) this.detalles[id].duplicado_ok = -1
      }
      // como estoy pre recepcionando, primero voy a ir a cruzar la informacion que tengo en los detalles con la orden de compra, si es que la seleccionan
      if (Object.keys(this.orden_compra).length == 0){
        // no seleccionan orden de compra, controlo si tienen algo para asociar
        let pendAsociar = this.detalles.filter(element => element.asociar_ean == 1 || element.articulo_codigo == null || element.articulo_codigo == undefined || element.articulo_codigo.toString().length == 0)
        if (pendAsociar.length > 0){
          this.$store.dispatch('show_snackbar', {
            text: 'Debe terminar de asociar el ean de los artículos.',
            color: 'warning',
          })
          return
        }
        // sin inconsistencias, por lo tanto emito sin problema el detalle
        let dets = this.detalles
        this.$emit('setArtsAsistidos', dets)
        // limpio localStorage
        if (localStorage.getItem('CargaAsistRecep')) localStorage.removeItem('CargaAsistRecep')
        // cierro el modal
        this.activo = false
      }else{
        // tienen seleccionada una orden de compra, por lo tanto voy a la base de datos a cruzar la informacion que tengo aqui con la de la orden de compra
        this.$store.state.loading = true
        let crucePeticion = await this.$store.dispatch('recepciones/recepcionVSorden', {
          detalles: this.detalles,
          orden_id: this.orden_compra.orden_id,
          proveedor_codigo: this.proveedor_codigo,
          deposito_codigo: this.deposito_codigo
        })
        this.$store.state.loading = false
        if (crucePeticion.resultado == 0){
          this.$store.dispatch('show_snackbar', {
            text: crucePeticion.msj,
            color: 'error',
          })
          return
        }
        if (crucePeticion.resultado == 2){
          // tengo articulos duplicados, paso a marcarlos
          this.repetidos = crucePeticion.repetidos
          this.indicarDuplicados()
          return
        }
        // no hay duplicados, marco como no duplicados a los detalles que ya tienen el codigo de artículo correcto
        for (let id in this.detalles){
          if (this.detalles[id].articulo_codigo != null && this.detalles[id].articulo_codigo != undefined && this.detalles[id].articulo_codigo.toString().length > 0) this.detalles[id].duplicado_ok = 1
        }
        // llegaron los detalles de la orden cruzados, marco los detalles que tengo actualmente en el modal como correctos o pendientes
        let arts = crucePeticion.detalles
        // me quedo unicamente con los que no tienen cruce ok
        let dets = this.detalles.filter(element => element.cruce_ok != 1)
        if (dets.length > 0){
          for (let id in dets){
            let pos = this.detalles.indexOf(dets[id])
            // busco el detalle actual en arts para ver si tuve coincidencias
            let existe = []
            let unCod = dets[id].articulo_codigo
            let unEan = dets[id].codigo_ean
            if (unCod != null && unCod != undefined && unCod.toString().length > 0) existe = arts.filter(element => element.articulo_codigo == unCod && element.codigo_ean.toString().trim().toUpperCase() == unEan.toString().trim().toUpperCase())
            else existe = arts.filter(element => element.codigo_ean.toString().trim().toUpperCase() == unEan.toString().trim().toUpperCase())
            if (existe.length > 0){
              this.detalles[pos].articulo_codigo = existe[0].articulo_codigo
              this.detalles[pos].articulo_cod_barras = existe[0].codigo_barras
              this.detalles[pos].cruce_ok = 1
              this.detalles[pos].asociar_ean = 0
              this.detalles[pos].cantidad_oc = existe[0].cantidad
              this.detalles[pos].duplicado_ok = 1
            }else this.detalles[pos].cruce_ok = 0
          }
        }
        // ahora me quedo con los detalles de la orden de compra que quedaron pendientes de asociar
        this.dialogDetsPendASociar.detsOrdCompPend = arts.filter(element => element.codigo_ean.toString().length == 0)
        // controlo a ver si los detalles estan listos
        this.controlPreRecepcion()
      }
      // calculo totales
      this.calcularTotales()
    },
    controlPreRecepcion(){
      let inconsistente = 0
      // estan controlados los duplicados?
      let duplicados = this.detalles.filter(element => element.duplicado_ok != 1)
      if (duplicados.length > 0) inconsistente = 1
      // console.log('duplicados', duplicados)
      // asociar ean
      let asociar = this.detalles.filter(element => element.asociar_ean == 1)
      if (asociar.length > 0) inconsistente = 1
      // console.log('asociar', asociar)
      // codigo de articulo no nulo?
      let codigosArts = this.detalles.filter(element => element.articulo_codigo == null || element.articulo_codigo == undefined || element.articulo_codigo.toString().length == 0)
      if (codigosArts.length > 0) inconsistente = 1
      // console.log('codigosArts', codigosArts)
      // controlo la bandera
      if (inconsistente == 1){
        // detalle no consistente, que resuelvan primero las inconsistencias
        this.$store.dispatch('show_snackbar', {
          text: 'Termine de resolver las inconsistencias y vuelva a intentarlo.',
          color: 'warning',
        })
      }else{
        // todo correcto, emito exito
        this.$emit('setPreRecepcion', this.detalles)
        // limpio localStorage
        if (localStorage.getItem('CargaAsistRecep')) localStorage.removeItem('CargaAsistRecep')
        this.activo = false
      }
    },
    limpiarForm(){
      this.articulo = ''
      this.detalles = []
      this.load = false
      this.search = ''
      this.selectArticulos = []
      this.dialogSelecArticulo.activo = false
      this.dialogSelecArticulo.articulos = []
      this.dialogSelecArticulo.bloquear_cant = true
      this.dialogSelecArticulo.bloquear_precio = true
      this.dialogSelecArticulo.cerrar_seleccion = true
      this.dialogSelecArticulo.titulo_modal = 'Seleccione un artículo para asociar el código de barras '
      this.dialogDetsPendASociar.activo = false
      this.dialogDetsPendASociar.detsOrdCompPend = []
      this.selectedItem = {}
      this.selectedArt = {}
      this.repetidos = []
      this.cantidadArts = 0
      this.cantidadTotal = 0
    },
    asociarArticulo(item){
      // asigno los articulos
      this.dialogSelecArticulo.articulos = this.articulos
      // guardo el item seleccionado
      this.selectedItem = item
      // termino del título del modal
      if (this.pre_recepcion == 0) this.dialogSelecArticulo.titulo_modal = this.dialogSelecArticulo.titulo_modal + this.selectedItem.articulo_cod_barras
      else this.dialogSelecArticulo.titulo_modal = 'Seleccione un artículo para asociar el código EAN ' + this.selectedItem.codigo_ean
      // abro el modal
      this.dialogSelecArticulo.activo = true
    },
    setModalSelecArt(value){
      this.dialogSelecArticulo.activo = value
      if (this.dialogSelecArticulo.activo == false){
        this.dialogSelecArticulo.articulos = []
        this.dialogSelecArticulo.titulo_modal = 'Seleccione un artículo para asociar el código de barras '
      }
    },
    async asociarArt(){
      // obtengo la posicion del item dentro del detalle
      let pos = this.detalles.indexOf(this.selectedItem)
      if (pos == -1){
        this.$store.dispatch('show_snackbar', {
          text: 'No se pudo determinar la posición del detalle seleccionado.',
          color: 'error',
        })
        return
      }
      // guardo la relacion en la base de datos
      this.$store.state.loading = true
      let asociarPeticion = await this.$store.dispatch('recepciones/asociarArtCodBarras', {
        articulo_codigo: this.selectedArt.articulo_codigo,
        articulo_nombre: this.selectedArt.articulo_nombre,
        codigo_barras: this.selectedItem.articulo_cod_barras,
        codigo_ean: this.selectedItem.codigo_ean,
        proveedor_codigo: this.proveedor_codigo,
        opcion: this.pre_recepcion == 0 ? 1 : 2 // opcion 2 quiere decir que voy a asociar el EAN al codigo de BB
      })
      this.$store.state.loading = false
      if (asociarPeticion.resultado == 0){
        this.$store.dispatch('show_snackbar', {
          text: asociarPeticion.msj,
          color: 'error',
        })
        return
      }
      // se guardó correctamente en la base de datos, paso a asociar los valores
      this.selectedItem.articulo_codigo = this.selectedArt.articulo_codigo
      this.selectedItem.articulo_nombre = this.selectedArt.articulo_nombre
      this.selectedItem.categoria_codigo = this.selectedArt.categoria_codigo
      this.selectedItem.detallado = this.selectedArt.detallado == null ? 0 : this.selectedArt.detallado
      this.selectedItem.series = []
      // limpio el artículo seleccionado
      this.selectedArt = {}
      let det = this.selectedItem
      // asigno el nuevo objeto al detalle
      Object.assign(this.detalles[pos], det)
      // limpio el item seleccionado
      this.selectedItem = {}
      // guardo en localStorage
      //this.guardarLocalStorage()
      // calculo totales
      this.calcularTotales()
      // control de los detalles
      this.controlAsociarEAN(1)
    },
    setArticulo(art){
      // me dieron el artículo, pido confirmación
      this.selectedArt = art
      this.dialogConfAsociar = true
    },
    setModalAsociar(){
      this.dialogConfAsociar = false
    },
    controlAsociarEAN(opcion){
      // controlo cada detalle, si tiene el codigo de BB vacio o no definido lo tengo que marcar para que asocie el codigo de barras o bien el codigo EAN
      if (this.detalles.length > 0){
        for (let id in this.detalles){
          if (this.detalles[id].articulo_codigo == null || this.detalles[id].articulo_codigo == null || this.detalles[id].articulo_codigo.toString().length == 0){
            // no esta definido el articulo, estoy pre recepcionando?
            if (this.pre_recepcion == 1) this.detalles[id].asociar_ean = 1
            else this.detalles[id].para_asociar = 1
          }else{
            // bien definido, marco el detalle correcto
            this.detalles[id].asociar_ean = 0
            this.detalles[id].para_asociar = 0
          }
        }
      }
      // actualizo localStorage
      this.guardarLocalStorage(opcion)
    },
    asociarOrdenDet(item){
      this.selectedItem = item
      // tengo los detalles pendientes en this.dialogDetsPendASociar.detsOrdCompPend
      if (this.dialogDetsPendASociar.detsOrdCompPend.length > 0) this.dialogDetsPendASociar.activo = true
      else{
        this.$store.dispatch('show_snackbar', {
          text: 'Primero debe procesar los detalles ingresados.',
          color: 'info',
        })
      }
    },
    setModalDetPendOC(value){
      this.dialogDetsPendASociar.activo = value
      //if (this.dialogDetsPendASociar.activo == false) this.selectedItem = {}
    },
    indicarDuplicados(){
      // me quedo unicamente con los detalles que no tienen resuelta su duplicidad
      let dets = this.detalles.filter(element => element.duplicado_ok != 1)
      if (dets.length > 0){
        for (let id in dets){
          let existe = []
          if (dets[id].articulo_codigo != null && dets[id].articulo_codigo != undefined && dets[id].articulo_codigo.toString().length > 0) existe = this.repetidos.filter(element => element.articulo_codigo == dets[id].articulo_codigo && element.codigo_ean.toString().trim().toUpperCase() == dets[id].codigo_ean.toString().trim().toUpperCase())
          else existe = this.repetidos.filter(element => element.codigo_ean.toString().trim().toUpperCase() == dets[id].codigo_ean.toString().trim().toUpperCase())
          if (existe.length > 0){
            // marco el detalle como pendiente de resolver duplicidad
            let pos = this.detalles.indexOf(dets[id])
            this.detalles[pos].duplicado_ok = 0
          }
        }
      }
    },
    resolverDuplicado(item){
      // debo abrir el modal para que el usuario escoja el artículo correcto, armo la estructura que necesito para el modal seleccionar artículo
      this.selectArticulos = []
      let duplicados = []
      this.selectedItem = item
      if (item.articulo_codigo != null && item.articulo_codigo != undefined && item.articulo_codigo.toString().length > 0) duplicados = this.repetidos.filter(element => element.articulo_codigo == item.articulo_codigo && element.codigo_ean.toString().trim().toUpperCase() == item.codigo_ean.toString().trim().toUpperCase())
      else duplicados = this.repetidos.filter(element => element.codigo_ean.toString().trim().toUpperCase() == item.codigo_ean.toString().trim().toUpperCase())
      if (duplicados.length > 0){
        for (let id in duplicados){
          let unDet = {
            articulo_codigo: duplicados[id].articulo_codigo,
            articulo_nombre: duplicados[id].articulo_nombre,
            codigo_barras: duplicados[id].articulo_cod_barras,
            codigo_ean: duplicados[id].codigo_ean
          }
          this.selectArticulos.push(unDet)
        }
        // abro el modal
        this.dialogSelectArticulo = true
      }else{
        this.$store.dispatch('show_snackbar', {
          text: 'No se detectaron duplicados para el artículo seleccionado.',
          color: 'info',
        })
      }
      // calculo totales
      this.calcularTotales()
    },
    async setDetOC(obj){
      // tener cuidado porque puede ser que el detalle no tenga codigo ean
      if (this.selectedItem.codigo_ean != null && this.selectedItem.codigo_ean != undefined && this.selectedItem.codigo_ean.toString().length > 0){
        // al seleccionar un detalle de la orden de compra, esta creando la relacion ean - codigo BB, paso a crearlo
        this.$store.state.loading = true
        let asociarPeticion = await this.$store.dispatch('recepciones/asociarArtCodBarras', {
          articulo_codigo: obj.articulo_codigo,
          articulo_nombre: obj.articulo_nombre,
          codigo_barras: obj.codigo_barras,
          codigo_ean: this.selectedItem.codigo_ean,
          proveedor_codigo: this.proveedor_codigo,
          opcion: 2 // opcion 2 quiere decir que voy a asociar el EAN al codigo de BB
        })
        this.$store.state.loading = false
        if (asociarPeticion.resultado == 0){
          this.$store.dispatch('show_snackbar', {
            text: asociarPeticion.msj,
            color: 'error',
          })
          return
        }
      }
      // asociacion exitosa, paso a marcar el detalle como consistente
      let pos = this.detalles.indexOf(this.selectedItem)
      // completo los datos en el detalle
      this.detalles[pos].articulo_codigo = obj.articulo_codigo
      this.detalles[pos].articulo_cod_barras = obj.codigo_barras
      this.detalles[pos].cruce_ok = 1
      this.detalles[pos].asociar_ean = 0
      this.detalles[pos].duplicado_ok = 1
      this.detalles[pos].cantidad_oc = obj.cantidad
      // quito el detalle seleccionado de los pendientes
      let posP = this.dialogDetsPendASociar.detsOrdCompPend.indexOf(obj)
      this.dialogDetsPendASociar.detsOrdCompPend.splice(posP, 1)
      this.selectedItem = {}
      this.$store.dispatch('show_snackbar', {
        text: 'La asociación se realizó correctamente.',
        color: 'success',
      })
      // calculo totales
      this.calcularTotales()
      // controlo ean
      this.controlAsociarEAN(1)
    },
    async generarExcel(){
      this.$store.state.loading = true
      let excelPeticion = await this.$store.dispatch('recepciones/getExcelDetsOrdComp', {
        orden_id: this.orden_compra.orden_id,
        deposito_codigo: this.deposito_codigo
      })
      this.$store.state.loading = false
      if (excelPeticion.resultado == 0){
        this.$store.dispatch('show_snackbar', {
          text: excelPeticion.msj,
          color: 'error',
        })
        return
      }
      // paso a descargar el excel
      download(excelPeticion.base64, `Orden de Compra ${this.orden_compra.orden_id}.xlsx`, 'application/vnd.ms-excel')
    },
  },
  watch: {
    activo: function(){
      if (this.activo == false) this.limpiarForm()
      else this.initForm()
    }
  },
}
</script>

<style>

</style>