<template>
  <v-dialog
    v-model="dialog"
    max-width="900"
    scrollable
  >
    <v-card>
      <v-card-title>
        Buscar Artículos
        <v-spacer></v-spacer>
        <v-btn
          icon
          @click="dialog = false"
        >
          <v-icon>fas fa-times</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text class="pa-0">
        <form @submit.prevent="buscar()">
          <v-row class="mx-4 mt-0 mb-4">
            <v-col cols="6" sm="4" md="3" class="py-1">
              Código
              <v-text-field
                v-model.trim="codigo"
                hide-details
                :type="barra ? 'tel' : 'number'"
                ref="codigoInp"
                autofocus
                outlined
                dense
              ></v-text-field>
            </v-col>
            <v-col cols="6" sm="4" md="3" class="pb-0">
              <v-switch
                v-model="barra"
                label="Cod. Barra"
                hide-details
                @change="codigo = null; $refs.codigoInp.focus()"
              ></v-switch>
            </v-col>
            <v-col cols="6" sm="4" md="6" class="d-flex justify-end align-end py-1">
              <v-btn
                color="info"
                type="submit"
              >
                <v-icon left>fas fa-search</v-icon>
                Buscar
              </v-btn>
            </v-col>
          </v-row>
        </form>
        <v-data-table
          class="cebra"
          :items="sobrantes"
          :headers="headers"
          :items-per-page="-1"
          hide-default-footer
          dense
        >
          <template v-slot:[`item.stock_real`]="{ item }">
            <v-text-field
              v-model="item.stock_real"
              type="number"
              class="pb-2"
              hide-details
              dense
            ></v-text-field>
          </template>
          <template v-slot:[`item.observacion`]="{ item }">
            <v-text-field
              v-model.trim="item.observacion"
              class="pb-2"
              hide-details
              dense
            ></v-text-field>
          </template>
          <template v-slot:[`item.actions`]="{ item }">
            <v-icon
              color="error"
              title="Quitar artículo"
              small
              @click="quitar(item)"
            >
              fas fa-times-circle
            </v-icon>
          </template>
          <template v-slot:no-data>
            <v-alert
              class="mx-auto mt-4"
              max-width="400"
              type="warning"
              border="left"
              dense
              text
            >
              No hay artículos seleccionados
            </v-alert>
          </template>
        </v-data-table>
      </v-card-text>
      <v-card-actions class="d-flex justify-end pb-4">
        <v-btn
          color="error"
          class="mt-2 ml-2"
          @click="dialog = false"
        >
          Cancelar
        </v-btn>
        <BtnConfirmar
          clase="mt-2 ml-2"
          nombre="Confirmar"
          texto="¿Desea agregar todos los artículos seleccionados al control de stock?"
          :disabled="sobrantes.length < 1"
          @action="confirmar()"
        />
      </v-card-actions>
    </v-card>
    <!-- componentes -->
    <ModalCoincidenciasCodBarra
      v-model="dialog_resolver"
      :p_coincidencias="coincidencias"
      @asociar="asociar_codigo"
    />
  </v-dialog>
</template>

<script>
/**
 * Modal para pistolear series
 * 
 *  Nota: cuando guarda emite la accion @procesar al padre la cual devuelve las series correctas, sobrantes e inexistentes (en ese orden)
 * 
 */
import BtnConfirmar from '../util/BtnConfirmar'
import ModalCoincidenciasCodBarra from './ModalCoincidenciasCodBarra.vue'

export default {
  data () {
    return {
      barra: false,
      dialog_resolver: false,
      codigo: null,
      codigo_art: null,
      sobrantes: [],
      coincidencias: [],
      headers: [
        { text: 'Cod. Interno', value: 'articulo', align: 'end' },
        { text: 'Cod. Barra', value: 'cod_barra' },
        { text: 'Nombre', value: 'nombre' },
        { text: 'Stock virtual', value: 'stock_virtual', align: 'end', sortable: false },
        { text: 'Stock real', value: 'stock_real', align: 'end', width: '75', sortable: false },
        { text: 'Observaciones', value: 'observacion', sortable: false },
        { text: 'Categoría', value: 'categoria' },
        { text: 'Acciones', value: 'actions', sortable: false, filterable: false, align: 'center' }
      ]
    }
  },
  props: {
    value: Boolean,
    p_id: Number,      // id del control de stock
    p_articulos: Array // articulos del control de stock (enviar sincronizado)
  },
  computed: {
    dialog: {
      get () {
        return this.value
      },
      set (value) {
        this.$emit('input', value)
      }
    },
    articulos: {
      get () {
        return this.p_articulos
      },
      set (value) {
        this.$emit('update:p_articulos', value)
      }
    }
  },
  watch: {
    dialog (val) {
      if (val) {
        this.barra = false
        this.codigo = null
        this.codigo_art = null
        this.sobrantes = []
        this.coincidencias = []
      }
    }
  },
  components: {
    BtnConfirmar,
    ModalCoincidenciasCodBarra
  },
  methods: {
    quitar (item) {
      const index = this.sobrantes.indexOf(item)
      this.sobrantes.splice(index, 1)
    },
    asociar_codigo (articulo) {
      if (this.sobrantes.find(a => a.articulo == articulo.codigo)) {
        this.$store.dispatch('show_snackbar', {
          text: 'Ya se agregó el artículo ' + articulo.codigo,
          color: 'orange'
        })
        this.codigo = null
        this.$refs.codigoInp.focus()
      } else {
        this.codigo_art = articulo.codigo
        this.buscar()
      }
    },
    async confirmar () {
      for (const articulo of this.sobrantes) {
        if (!articulo.stock_real) {
          this.$store.dispatch('show_snackbar', {
            text: 'Debe ingresar el stock real de los artículos',
            color: 'orange'
          })
          return
        }
      }
      this.$store.state.loading = true
      await this.$store.dispatch('controlStock/agregar_articulos', {
        id: this.p_id,
        articulos: this.sobrantes
      })
        .then((res) => {
          res.articulos.forEach(articulo => {
            this.articulos.unshift(articulo)
          })
          this.$store.dispatch('show_snackbar', {
            text: res.message,
            color: 'success'
          })
          this.dialog = false
        })
        .catch(error => {
          this.$store.dispatch('show_snackbar', {
            text: error.message,
            color: 'error'
          })
        })
      this.$store.state.loading = false
    },
    async buscar () {
      if (!this.codigo) {
        this.$refs.codigoInp.focus()
        return
      }
      if (!this.barra && this.sobrantes.find(a => a.articulo == this.codigo)) {
        this.$store.dispatch('show_snackbar', {
          text: 'Ya se agregó el artículo ' + this.codigo,
          color: 'orange'
        })
        this.codigo = null
        this.$refs.codigoInp.focus()
        return
      }

      // si es por barra primero valido
      if (this.barra && this.codigo_art == null) {
        this.$store.state.loading = true
        let result = await this.$store.dispatch('controlStock/validar_codbarra', [ this.codigo ])
        this.$store.state.loading =false

        if (result.exito != 1) {
          return this.$store.dispatch('show_snackbar', {
            text: result.message,
            color: 'error'
          })
        }

        this.coincidencias = result.data

        switch (this.coincidencias.length) {
          case 1:
            this.codigo_art = this.coincidencias[0].codigo
            // valido si ya existe el articulo por cod interno
            if (this.sobrantes.find(a => a.articulo == this.codigo_art)) {
              this.$store.dispatch('show_snackbar', {
                text: 'Ya se agregó el artículo ' + this.codigo_art,
                color: 'orange'
              })
              this.codigo = null
              this.codigo_art = null
              this.$refs.codigoInp.focus()
              return
            }
            break
          case 0:
            this.$store.dispatch('show_snackbar', {
              text:  'No se encontró el código de barra ' + this.codigo + ' en el sistema.',
              color: 'orange'
            })
            this.codigo = null
            this.$refs.codigoInp.focus()
            return
          default:
            this.dialog_resolver = true
            return
        }
      }

      this.$store.state.loading = true
      await this.$store.dispatch('controlStock/validar_articulo', {
        id: this.p_id,
        articulo: this.barra ? this.codigo_art : this.codigo
      })
        .then(async (data) => {
          let articulo = data.articulo
          articulo.cod_barra = this.barra ? this.codigo : null
          if (!data.existe) {
            // mensaje de advertencia que la categoria no se encontro
            await this.$swal.fire({
              icon: 'warning',
              title: `El artículo seleccionado pertenece a una categoría distinta de las definidas en el control de stock (${data.articulo.categoria}) ¿Desea agregarlo de todas formas?`,
              width: '675px',
              confirmButtonText: 'Sí',
              cancelButtonText: 'No',
              showCancelButton: true,
              focusCancel: true
            })
              .then((res) => {
                // si pone que no o hace click fuera del modal no hace nada
                if (res.isConfirmed) {
                  this.sobrantes.push(articulo)
                }
              })
          } else {
            this.sobrantes.push(articulo)
          }
        })
        .catch(error => {
          this.$store.dispatch('show_snackbar', {
            text: error.message,
            color: 'error'
          })
        })
      this.$store.state.loading = false
      this.codigo = null
      this.codigo_art = null
      this.$refs.codigoInp.focus()
    }
  }
}
</script>