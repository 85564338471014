<template>
<div>
  <v-dialog
    v-model="activo"
    width="700"
    :persistent="activo"
    scrollable>
    <v-card>
      <v-card-title class="">
        <div style="font-size: 20px">
          Agregar Artículo
        </div>
        <v-spacer></v-spacer>
        <v-btn right icon @click="activo = false">
          <v-icon>fas fa-times</v-icon>
        </v-btn>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text>
        <!--           :footer-props="{'items-per-page-options':[10, 15, 50, 100]}"  :items-per-page="-1"-->
        <v-data-table      
          class="cebra mt-2"
          :headers="headers"
          :search="search"
          :items="articulos"
          :loading="load"
          dense
          hide-default-footer
          :items-per-page="15"
          :page.sync="page"
          @page-count="pageCount = $event"
          >
          <template v-slot:top>
            <v-row class="d-flex justify-end pa-2" no-gutters>
              <v-col cols="6" sm="3" xl="2">
                <SearchDataTableVue
                  v-model="search"
                />
              </v-col>
            </v-row>
          </template>
          <template v-slot:[`item.accion`]="{item}">
            <v-btn 
              icon color="success" title="Seleccionar"
              @click="seleccionarArt(item)">
              <v-icon small>
                fas fa-check
              </v-icon>
            </v-btn>
          </template>
          <template v-slot:no-data>
            <v-alert
              class="mx-auto mt-4"
              max-width="400"
              type="warning"
              border="left"
              dense
              text
            >
              No se encontraron datos
            </v-alert>
          </template>
        </v-data-table>
        <div class="pt-2">
          <v-pagination
            v-model="page"
            :length="pageCount"
          ></v-pagination>
        </div>
      </v-card-text>
      <v-card-actions class="d-flex justify-end pb-4">
        <v-btn
          class="mr-2"
          color="secondary"
          :disabled="load"
          @click="activo = false"
          small
        >Volver</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</div>
</template>

<script>
import { format_money } from '../../util/utils';
import SearchDataTableVue from '../util/SearchDataTable.vue';

export default {
  name: 'ModalSeleccionarArticulo',
  props:{
    value: Boolean,
    datos:{
      type: Object,
      default: {}
    }  
  },
  computed:{
    activo:{
      get(){
        return this.datos.dialogArt;
      },
      set (value) {
        this.$emit('input', value)
      }
    },
  },
  data(){
    return{
      load: false,
      search: '',
      format_money: format_money,
      headers: [
        { text: 'Código', value: 'articulo_codigo' },
        { text: 'Descripción', value: 'articulo_nombre' },
        { text: 'Rubro', value: 'rubro_nombre' },
        { text: 'Categoria', value: 'categoria_nombre' },
        { text: 'Seleccionar', value: 'accion', filtreable: false },
      ],
      articulos: [],
      page: 1,
      pageCount: 0,
    }
  },
  created(){
  },
  methods:{
    async init(){
      this.load = true;
      this.$store.state.loading = true //obtenerArticulos 
      const res = await this.$store.dispatch('promociones/obtenerArticuloPorCodigo2',{ articulo_codigo: 0, categoria_codigo: this.datos.categoria_codigo })
      this.$store.state.loading = false;
      this.load = false;

      if(res.resultado == 0){
        return this.$store.dispatch('show_snackbar', { text: res.msj, color: 'error'})
      }

      this.articulos = res.articulo
    },
    seleccionarArt(item){
      if(item.articulo_codigo != 0){
        this.$emit('seleccionarArticulo', item);
      }
    }
  },
  components:{
    SearchDataTableVue
  },
  watch:{
    activo(val){
      if(val){
        this.init();
      }else{
        this.search = '';
        this.articulos = [];
      }
    }
  }
}
</script>

<style>

</style>