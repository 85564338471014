<template>
  <v-dialog
    v-model="activo"
    max-width="50%"
  >
    <v-card
      flat
    >
      <v-card-title>
        Series para el Artículo {{ articuloCodigo }} - {{ articuloNombre }}
        <v-spacer></v-spacer>
        <v-btn icon><v-icon @click="activo = false" title="Cerrar">fas fa-times</v-icon></v-btn>
      </v-card-title>
      <v-card-text>
        <v-row>
          <v-col cols="12">
            <v-data-table
              class="cebra elevation-2 mt-2"
              :headers="headers"
              :items="seriesParam"
              dense
              :search="search"
            >
            <!-- Buscador en el datatable -->
            <template v-slot:top>
              <v-row class="d-flex justify-end pa-2" no-gutters>
                <v-col cols="6" sm="3">
                  <SearchDataTable
                    v-model="search"
                  />
                </v-col>
              </v-row>
            </template>
          </v-data-table>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions class="d-flex justify-end mx-2">
        <v-btn color="warning" class="mr-2" @click="activo = false">Volver</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>  
  import SearchDataTable from '../util/SearchDataTable.vue'
export default {
  props: {
    dialogActivo: {
      type: Boolean,
      default: false
    },
    articuloCodigo: {
      type: Number,
      default: 0
    },
    articuloNombre: {
      type: String,
      default: ''
    },
    seriesRemito: {
      type: Array,
      default: []
    }
  },
  components: {
    SearchDataTable,
  },
  computed: {
    activo: {
      get() {
        return this.dialogActivo
      },
      set(value) {
        this.$emit('setearModalSeries', value)
      }
    },
    seriesParam: {
      get() {
        return this.seriesRemito
      }
    }
  },
  data() {
    return {
      headers: [
        { text: 'Series', align: 'center', value: 'serie' }
      ],
      search: ''
    }
  },
}
</script>

<style>

</style>