<template>
  <v-dialog
    v-model="dialog"
    max-width="800"
    scrollable
  >
    <v-card>
      <v-card-title>
        Historial de ubicaciones
        <v-spacer></v-spacer>
        <v-btn
          icon
          @click="dialog = false"
        >
          <v-icon>fas fa-times</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text class="">
        <div class="body-1">
          Artículo {{ p_articulo }} - {{ p_nombre }}
        </div>
        <v-data-table
          class="cebra mt-2"
          :loading="load"
          :items="ubicaciones"
          :headers="headers"
          :items-per-page="-1"
          hide-default-footer
          dense
        >
          <template v-slot:[`item.stock_virtual`]="{ item }">
            <v-chip
              :color="item.color == 1 ? 'orange' : '#0000'"
              small
            >
              {{ item.stock_virtual }}
            </v-chip>
          </template>
          <template v-slot:no-data>
            <v-alert
              class="mx-auto mt-4"
              max-width="400"
              type="warning"
              border="left"
              dense
              text
            >
              No se encontraron movimientos
            </v-alert>
          </template>
        </v-data-table>
      </v-card-text>
      <v-card-actions class="d-flex justify-end pb-4">
        <v-btn
          color="secondary"
          @click="dialog = false"
        >
          Volver
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  data () {
    return {
      load: false,
      ubicaciones: [],
      headers: [
        { text: 'Ubicación', value: 'ubicacion' },
        { text: 'Cantidad', value: 'cantidad', align: 'end' },
        { text: 'Stock real', value: 'stock_real', align: 'end', sortable: false },
        { text: 'Stock virtual', value: 'stock_virtual', align: 'end', sortable: false },
        { text: 'Cod. Barra', value: 'cod_barra', sortable: false },
        { text: 'Fecha', value: 'fecha', sortable: false },
        { text: 'Usuario', value: 'usuario' }
      ]
    }
  },
  props: {
    value: Boolean,
    p_id: Number,
    p_articulo: Number,
    p_nombre: String
  },
  computed: {
    dialog: {
      get () {
        return this.value
      },
      set (value) {
        this.$emit('input', value)
      }
    }
  },
  watch: {
    async dialog (val) {
      if (val) {
        await this.get_hisotiral()
      }
    }
  },
  methods: {
    async get_hisotiral () {
      this.ubicaciones = []
      this.load = true
      await this.$store.dispatch('controlStock/get_historial', {
        id: this.p_id,
        articulo: this.p_articulo
      })
        .then((res) => {
          this.ubicaciones = res.data
        })
        .catch(error => {
          this.$store.dispatch('show_snackbar', {
            text: error.message,
            color: 'error'
          })
        })
      this.load = false
    }
  }
}
</script>