<template>
  <v-dialog
    v-model="dialog"
    max-width="900px"
    :persistent="load"
    scrollable
  >
    <v-card :disabled="load">
      <v-card-title>
        Nuevo control de stock
        <v-spacer></v-spacer>
        <v-btn
          icon
          @click="dialog = false"
        >
          <v-icon>fas fa-times</v-icon>
        </v-btn>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text>
        <v-form ref="form">
          <v-row class="pt-8">
            <v-col cols="12" sm="6" md="3" class="py-0">
              <v-autocomplete
                v-model="control.empresa"
                label="Empresa"
                item-text="nombre_corto"
                item-value="id"
                :items="empresas"
                :rules="[rules.required]"
                validate-on-blur
                outlined
                dense
                @change="get_bodegas(); control.deposito = null;"
              ></v-autocomplete>
            </v-col>
            <v-col cols="12" sm="6" md="3" class="py-0">
              <v-autocomplete
                v-model="control.sucursal"
                label="Sucursal"
                item-text="nombre"
                item-value="id"
                :items="sucursales"
                :rules="[rules.required]"
                validate-on-blur
                outlined
                dense
                @change="get_bodegas(); control.deposito = null;"
              ></v-autocomplete>
            </v-col>
            <v-col cols="12" md="6" class="py-0">
              <v-autocomplete
                v-model="control.deposito"
                label="Bodega"
                item-text="nombre"
                item-value="codigo"
                :items="bodegas_filtradas"
                :rules="[rules.required]"
                validate-on-blur
                outlined
                dense
              ></v-autocomplete>
            </v-col>
            <v-col cols="12" class="py-0">
              <v-textarea
                v-model.trim="control.descripcion"
                label="Descripción"
                rows="3"
                counter="140"
                :rules="[rules.required, rules.max]"
                validate-on-blur
                auto-grow
                outlined
                dense
              ></v-textarea>
            </v-col>
            <v-col cols="12" class="pt-2 pt-sm-0">
              <v-card outlined>
                <v-card-title class="body-1 pt-2">
                  Controlar el stock de
                </v-card-title>
                <SelectControlIntegral
                  @result="integral"
                />
                <v-card-text class="py-0 mt-2 mb-sm-2 mb-4">
                  <v-row>
                    <v-col cols="12" md="6" class="py-0">
                      <v-autocomplete
                        v-model="control.rubros"
                        label="Rubros"
                        item-text="nombre"
                        item-value="codigo"
                        :items="rubros"
                        deletable-chips
                        small-chips
                        clearable
                        multiple
                        outlined
                        dense
                        @change="get_categorias(); control.categorias = []"
                      ></v-autocomplete>
                    </v-col>
                    <v-col cols="12" md="6" class="py-0">
                      <v-autocomplete
                        v-model="control.categorias"
                        label="Categorias"
                        item-text="nombre"
                        item-value="codigo"
                        :items="categorias_filtradas"
                        deletable-chips
                        small-chips
                        clearable
                        multiple
                        outlined
                        dense
                      ></v-autocomplete>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-card-actions class="d-flex justify-end pb-4">
        <v-btn
          class="ml-3 mt-2"
          color="error"
          @click="dialog = false"
        >
          Cancelar
        </v-btn>
        <BtnConfirmar
          clase="ml-3 mt-2"
          @action="guardar()"
        />
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
/**
 *  Modal para crear un control de stock 
 *  
 *  Notas: este componente emite la accion (@nuevo) al padre caundo se crea un control de stock 
 *         (en caso de estar definidas)
 *
 */
import { mapState, mapGetters } from 'vuex'
import { order_list_by } from '../../util/utils'
import moment from 'moment'
import SelectControlIntegral from '../../components/stock/SelectControlIntegral'
import BtnConfirmar from '../util/BtnConfirmar'

export default {
  data () {
    return {
      load: false,
      bodegas_filtradas: [],
      categorias_filtradas: [],
      control: {
        empresa: null,
        sucursal: null,
        deposito: null,
        descripcion: null,
        fecha_desde: moment(new Date()).format('DD/MM/YYYY'),
        categorias: [],
        rubros: []
      },
      rules: {
        required: value => !!value || 'Campo requerido',
        max: value => (value ? value.length : 0) <= 140 || 'Máximo 140 caracteres'
      }
    }
  },
  props: {
    value: Boolean
  },
  computed: {
    ...mapGetters(['una_empresa', 'una_sucursal']),
    ...mapState(['empresas', 'sucursales']),
    ...mapState('rubros', ['rubros']),
    ...mapState('categorias', ['categorias']),
    ...mapState('bodegas', ['bodegas_user']),
    dialog: {
      get () {
        return this.value
      },
      set (value) {
        this.$emit('input', value)
      }
    }
  },
  components: {
    SelectControlIntegral,
    BtnConfirmar
  },
  watch: {
    async dialog (val) {
      if (val) {
        this.control = {
          empresa: null,
          sucursal: null,
          deposito: null,
          descripcion: null,
          fecha_desde: moment(new Date()).format('DD/MM/YYYY'),
          categorias: [],
          rubros: []
        }
        // si tiene solo 1 empresa la selecciona
        if (this.una_empresa) this.control.empresa = this.empresas[0].id
        // si tiene solo 1 sucursal la selecciona
        if (this.una_sucursal) this.control.sucursal = this.sucursales[0].id
        // obtiene los datos necesarios
        await this.get_datos()
        this.get_bodegas()
      } else {
        this.$refs.form.resetValidation()
        this.bodegas_filtradas = []
        this.categorias_filtradas = []
      }
    }
  },
  methods: {
    integral (res) {
      this.control.rubros = res.rubros
      this.get_categorias()
      this.control.categorias = res.categorias
    },
    async guardar () {
      if (this.$refs.form.validate()) {
        if (this.control.categorias.length == 0)  {
          this.$store.dispatch('show_snackbar', {
            text: 'Debe seleccionar al menos una categoria',
            color: 'warning'
          })
          return
        }

        // elimina los saltos de linea
        this.control.descripcion = this.control.descripcion.replaceAll('\n', ' ')

        await this.$store.dispatch('controlStock/nuevo_control', this.control)
          .then((res) => {
            this.dialog = false
            this.$emit('nuevo', res.control)
            this.$store.dispatch('show_snackbar', {
              text: res.message,
              color: 'success'
            })
          })
          .catch(error => {
            this.$store.dispatch('show_snackbar', {
              text: error.message,
              color: 'error'
            })
          })
      }
    },
    async get_datos () {
      this.$store.state.loading = true
      await this.$store.dispatch('rubros/get_rubros_habilitados')
      await this.$store.dispatch('categorias/get_categorias_habilitadas')
      await this.$store.dispatch('bodegas/get_bodegas_user')
      this.$store.state.loading = false
    },
    get_bodegas () {
      this.bodegas_filtradas = []
      const empresa = this.control.empresa
      const sucursal = this.control.sucursal
      if (empresa && sucursal) {
        let bodegas = this.bodegas_user.filter(b => b.empresa_codigo == empresa && b.sucursal_codigo == sucursal)
        bodegas.forEach(bodega => {
          this.bodegas_filtradas.push(bodega)
        })
        order_list_by(this.bodegas_filtradas, 'nombre')
      }
    },
    get_categorias () {
      this.categorias_filtradas = []
      this.control.rubros.forEach(rubro => {
        let categorias = this.categorias.filter(cat => cat.rubro_codigo == rubro)
        categorias.forEach(categoria => {
          this.categorias_filtradas.push(categoria)
        })
      })
      order_list_by(this.categorias_filtradas, 'nombre')
    }
  }
}
</script>