<template>
  <div>
    <v-row class="d-flex justify-center mt-0">
      <v-col cols="12">
        <!-- Filtros -->
        <v-expansion-panels v-model="panel">
          <v-expansion-panel readonly>
            <!-- Boton Agregar nueva Recepción -->
            <v-btn
              style="position: absolute; top: -15px; right: 55px; z-index: 1"
              color="success"
              small
              fab
              title="Nueva Recepción"
              @click="nuevaRecepcion()"
            >
              <v-icon>fas fa-plus</v-icon>
            </v-btn>
            <!-- Titulo Filtros -->
            <v-expansion-panel-header class="py-0 px-4 text-h5" style="cursor: default">
              <div>
                <v-icon class="mb-1" left>fas fa-filter</v-icon>
                Filtros
              </div>
              <template v-slot:actions>
                <v-btn icon @click.stop="panel = panel === 1 ? 0 : 1">
                  <v-icon>fas fa-chevron-down</v-icon>
                </v-btn>
              </template>
            </v-expansion-panel-header>
            <!-- Filtros -->
            <v-expansion-panel-content class="pt-1">
              <v-form @submit.prevent="buscar()">
                <!-- Deposito, Proveedor y Estado -->
                <v-row :no-gutters="$vuetify.breakpoint.xs">
                  <v-col cols="12" sm="4" md="4" class="py-1">
                    Deposito
                    <v-autocomplete
                      v-model="selectedDeposito"
                      item-text="deposito_nombre"
                      :items="depositos"
                      hide-details
                      outlined
                      dense
                      return-object
                      clearable
                    ></v-autocomplete>
                  </v-col>
                  <v-col cols="12" sm="4" md="4" class="py-1">
                    Proveedor
                    <v-autocomplete
                      v-model="selectedProveedor"
                      item-text="proveedor_nombre"
                      :items="proveedores"
                      hide-details
                      outlined
                      dense
                      return-object
                      clearable
                    ></v-autocomplete>
                  </v-col>
                  <v-col cols="12" sm="4" md="4" class="py-1">
                    Estado
                    <v-autocomplete
                      v-model="selectedEstado"
                      item-text="estado_nombre"
                      :items="estados"
                      hide-details
                      outlined
                      dense
                      return-object
                      clearable
                    ></v-autocomplete>
                  </v-col>
                </v-row>
                <!-- Número, Fechas y Botones -->
                <v-row :no-gutters="$vuetify.breakpoint.xs">
                  <v-col cols="12" sm="2" md="2" class="py-1">
                    Número
                    <v-text-field
                      v-model="numero"
                      outlined
                      dense
                      type="number"
                      hide-details
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="2" md="2" class="py-1">
                    Fecha Desde
                    <FechaPicker 
                      v-model="fechaDesde"
                    />
                  </v-col>
                  <v-col cols="12" sm="2" md="2" class="py-1">
                    Fecha Hasta
                    <FechaPicker 
                      v-model="fechaHasta"
                    />
                  </v-col>
                  <v-col cols="12" sm="6" md="6" class="pt-0">
                    <BtnFiltro
                      :loading="load"
                      @clear="limpiar()"
                    />
                  </v-col>
                </v-row>
              </v-form>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
        <!-- Listado de Recepciones -->
        <v-data-table
          class="cebra elevation-2 mt-2"
          :headers="headers"
          :items="recepciones"
          :loading="load"
          dense
          :search="search"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
        >
          <!-- Buscador en el datatable -->
          <template v-slot:top>
            <v-row class="d-flex justify-end pa-2" no-gutters>
              <v-col cols="6" sm="3">
                <SearchDataTable
                  v-model="search"
                />
              </v-col>
            </v-row>
          </template>
          <!-- Msj que se mostrara si no existen resultados -->
          <template v-slot:no-data>
            <v-alert
              class="mx-auto mt-4"
              max-width="500"
              type="warning"
              border="left"
              dense
              text
            >
              No hay Promociones para los filtros seleccionados
            </v-alert>
          </template>
          <!-- Acciones -->
          <template v-slot:[`item.acciones`]="{ item }">
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn small icon color="success" @click="verRecepcion(item)" v-on="on">
                  <v-icon class="mr-2" small>far fa-eye</v-icon>
                </v-btn>
              </template>
              <span>Ver Recepción</span>
            </v-tooltip>
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn small icon :color="parseInt(item.con_archivo) > 0 ? 'success' : 'error'" @click="verImagenes(item)" v-on="on">
                  <v-icon small>fas fa-file-upload</v-icon>
                </v-btn>
              </template>
              <span>Imagenes</span>
            </v-tooltip>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
    <!-- Modal Subir Archivos -->
    <ModalUploadImgGral 
      :datos="dialogSubirImgs"
      @setearModalUpImg="setModalUpImg"
    />
  </div>
</template>

<script>
import FechaPicker from '../../components/util/FechaPicker.vue'
import BtnFiltro from '../../components/util/BtnFiltro.vue'
import SearchDataTable from '../../components/util/SearchDataTable.vue'
import ModalUploadImgGral from '../../components/generales/ModalUploadImgGral.vue'
export default {
  data() {
    return {
      panel: 0,
      selectedDeposito: {},
      depositos: [],
      selectedProveedor: {},
      proveedores: [],
      selectedEstado: {},
      estados: [],
      numero: '',
      fechaDesde: '',
      fechaHasta: '',
      load: false,
      headers: [
        { text: 'Código', align: 'center', value: 'recepcion_id' },
        { text: 'Fecha', align: 'center', value: 'fecha' },
        { text: 'Emision', align: 'center', value: 'emision' },
        { text: 'Numero', align: 'center', value: 'numero' },
        { text: 'Proveedor', align: 'center', value: 'proveedor_nombre' },
        { text: 'Bodega', align: 'center', value: 'bodega_nombre' },
        { text: 'Estado', align: 'center', value: 'estado_nombre' },
        { text: 'Usuario', align: 'center', value: 'usuario_grabacion' },
        { text: 'Acciones', align: 'center', value: 'acciones' }
      ],
      recepciones: [],
      search: '',
      sortDesc: true,
      sortBy: 'recepcion_id',
      dialogSubirImgs: {
        activo: false,
        icono: 'fas fa-eye',
        titulo_modal: 'Imagenes para La Recepción ',
        tipos: ['.jpg', '.png', '.webp', '.pdf'], // formatos admitidos
        multiple: true,
        max_size: 1, // tamaño del archivo en megas
        ruta: '', // ruta del archivo
        raiz: 0, // indica si vamos a trabajar con la raiz del 10.40 o la de jarvis 2
        tabla: 5, // indica la tabla donde va el log
        id: 0, // generalmente indica un numero identificador para la tabla del log, por ejemplo para facturas de compra seria el id de la factura de compra en la tabla LOG_FACTURAS_COMPRAS en el campo FACTURA_COMPRA_ID
        permiso_borrar: 0 // permiso para borrar archivos
      }
    }
  },
  components: {
    FechaPicker,
    BtnFiltro,
    SearchDataTable,
    ModalUploadImgGral
  },
  created () {
    this.initForm()
  },
  methods: {
    async initForm(){
      this.$store.state.loading = true
      let initPeticion = await this.$store.dispatch('recepciones/initRecepIndex')
      this.$store.state.loading = false
      if (initPeticion.resultado == 1){
        this.depositos = initPeticion.depositos
        this.proveedores = initPeticion.proveedores
        this.estados = initPeticion.estados
        this.dialogSubirImgs.permiso_borrar = initPeticion.permiso
      }else{
        this.$store.dispatch('show_snackbar', {
          text: initPeticion.msj,
          color: 'error',
        })
      }
    },
    limpiar() {
      this.selectedDeposito = {}
      this.selectedProveedor = {}
      this.selectedEstado = {}
      this.numero = ''
      this.fechaDesde = ''
      this.fechaHasta = ''
    },
    async buscar(){
      if (Object.keys(this.selectedDeposito).length == 0 && Object.keys(this.selectedProveedor).length == 0 && Object.keys(this.selectedEstado).length == 0 &&
      (this.numero == null || this.numero == undefined || this.numero.toString().length == 0) && (this.fechaDesde == null || this.fechaDesde == undefined || this.fechaDesde.toString().length == 0) &&
      (this.fechaHasta == null || this.fechaHasta == undefined || this.fechaHasta.toString().length == 0)){
        this.$store.dispatch('show_snackbar', {
          text: 'Debe seleccionar al menos un fitro para empezar la búsqueda.',
          color: 'info',
        })
        return
      }
      this.recepciones = []
      this.$store.state.loading = true
      // deposito
      let dep = 0
      if (Object.keys(this.selectedDeposito).length > 0) dep = this.selectedDeposito.deposito_codigo
      // proveedor
      let prov = 0
      if (Object.keys(this.selectedProveedor).length > 0) prov = this.selectedProveedor.proveedor_codigo
      // estado
      let est = 0
      if (Object.keys(this.selectedEstado).length > 0) est = this.selectedEstado.estado_id
      // numero
      let num = 0
      if (this.numero != null && this.numero != undefined && this.numero.toString().length != 0 && this.numero != 0) num = this.numero
      // fecha desde
      let fecha_desde = ''
      if (this.fechaDesde != null && this.fechaDesde != undefined && this.fechaDesde.toString().length != 0) fecha_desde = this.fechaDesde
      // fecha hasta
      let fecha_hasta = ''
      if (this.fechaHasta != null && this.fechaHasta != undefined && this.fechaHasta.toString().length != 0) fecha_hasta = this.fechaHasta
      // armo mi body
      let body = {
        deposito_codigo: dep,
        proveedor_codigo: prov,
        estado_codigo: est,
        numero: num,
        fecha_desde: fecha_desde,
        fecha_hasta: fecha_hasta
      }
      let buscarPeticion = await this.$store.dispatch('recepciones/getRecepciones', body)
      this.$store.state.loading = false
      if (buscarPeticion.resultado == 1){
        this.recepciones = buscarPeticion.recepciones
      }else{
        this.$store.dispatch('show_snackbar', {
          text: buscarPeticion.msj,
          color: 'error',
        })
      }
    },
    verRecepcion(item){
      let path = '/ver-recepcion/' + item.recepcion_id.toString()
      const routeData = this.$router.resolve({ path: path })
      window.open(routeData.href, '_blank')
    },
    nuevaRecepcion(){
      let path = '/nueva-recepcion'
      const routeData = this.$router.resolve({ path: path })
      window.open(routeData.href, '_blank')
    },
    setModalUpImg(value){
      this.dialogSubirImgs.activo = value
      if (this.dialogSubirImgs.activo == false){
        this.dialogSubirImgs.icono = 'fas fa-eye'
        this.dialogSubirImgs.titulo_modal = 'Imagenes para La Recepción '
      }
    },
    verImagenes(item){
      // titulo del modal
      this.dialogSubirImgs.titulo_modal = this.dialogSubirImgs.titulo_modal + item.recepcion_id
      // ruta de la carpeta donde se deberian encontrar los archivos
      this.dialogSubirImgs.ruta = '/recepciones/' + item.recepcion_id
      // indico el id identificador de las fotos para la recepción
      this.dialogSubirImgs.id = item.recepcion_id
      // abro el modal
      this.dialogSubirImgs.activo = true
    }
  },
  watch: {
    selectedDeposito: function(){
      if (this.selectedDeposito == null || this.selectedDeposito == undefined) this.selectedDeposito = {}
    },
    selectedProveedor: function(){
      if (this.selectedProveedor == null || this.selectedProveedor == undefined) this.selectedProveedor = {}
    },
    selectedEstado: function(){
      if (this.selectedEstado == null || this.selectedEstado == undefined) this.selectedEstado = {}
    }
  },
}
</script>

<style>

</style>