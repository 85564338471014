<template>
  <v-row class="d-flex justify-center mt-0">
    <v-col cols="12">
      <v-expansion-panels v-model="panel">
        <v-expansion-panel readonly>
          <v-btn
            style="position: absolute; top: -15px; right: 55px; z-index: 1"
            color="success"
            small
            fab
            @click="open_window(null)"
          >
            <v-icon>fas fa-plus</v-icon>
          </v-btn>
          <v-expansion-panel-header class="py-0 px-4 text-h5" style="cursor: default">
            <div>
              <v-icon class="mb-1" left>fas fa-filter</v-icon>
              Filtro
            </div>
            <template v-slot:actions>
              <v-btn icon @click.stop="panel = panel === 1 ? 0 : 1">
                <v-icon>fas fa-chevron-down</v-icon>
              </v-btn>
            </template>
          </v-expansion-panel-header>
          <v-expansion-panel-content class="pt-1">
            <v-form @submit.prevent="buscar()">
              <v-row>
                <v-col cols="6" md="2" class="py-1">
                  Empresa
                  <v-autocomplete
                    v-model="filtro.empresa"
                    item-text="nombre_corto"
                    item-value="id"
                    tabindex="1"
                    :items="empresas"
                    hide-details
                    autofocus
                    clearable
                    outlined
                    dense
                    @change="get_bodegas()"
                  ></v-autocomplete>
                </v-col>
                <v-col cols="6" md="3" class="py-1">
                  Sucursal
                  <v-autocomplete
                    v-model="filtro.sucursal"
                    item-text="nombre"
                    item-value="id"
                    tabindex="1"
                    :items="sucursales"
                    hide-details
                    clearable
                    outlined
                    dense
                    @change="get_bodegas()"
                  ></v-autocomplete>
                </v-col>
                <v-col cols="12" sm="6" md="4" class="py-1">
                  Bodega
                  <v-autocomplete
                    v-model="filtro.deposito"
                    item-text="nombre"
                    item-value="codigo"
                    tabindex="1"
                    :items="bodegas"
                    hide-details
                    clearable
                    outlined
                    dense
                  ></v-autocomplete>
                </v-col>
                <v-col cols="12" sm="6" md="4" class="py-1">
                  Descripción
                  <v-text-field
                    v-model.trim="filtro.descripcion"
                    tabindex="1"
                    hide-details
                    outlined
                    dense
                  ></v-text-field>
                </v-col>
                <v-col cols="6" md="2" class="d-flex justify-center align-center py-1">
                  <v-switch
                    v-model="filtro.inhabilitado"
                    label="Inhabilitado"
                    class="mb-2"
                    tabindex="1"
                    :true-value="1"
                    :false-value="0"
                    hide-details
                    dense
                  ></v-switch>
                </v-col>
                <BtnFiltro
                  :loading="load"
                  @clear="limpiar()"
                />
              </v-row>
            </v-form>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>

      <v-data-table
        class="cebra elevation-2 mt-2"
        sort-by="descripcion"
        :headers="headers"
        :items="configuraciones"
        :loading="load"
        :search="search"
        :footer-props="{'items-per-page-options':[10, 15, 50, 100]}"
        dense
      >
        <template v-slot:top>
          <v-row class="d-flex justify-end pa-2" no-gutters>
            <v-col cols="6" sm="3" md="2">
              <SearchDataTable
                v-model="search"
              />
            </v-col>
          </v-row>
        </template>
        <template v-slot:[`item.duracion`]="{ item }">
          {{ item.duracion }} {{ item.duracion == 1 ? 'día' : 'días'}}
        </template>
        <template v-slot:[`item.actions`]="{ item }">
          <v-icon
            color="info"
            title="Ver"
            class="mr-2"
            small
            @click="open_window(item.id)"
          >
            fas fa-eye
          </v-icon>
          <BtnConfirmar
            :icono="item.inhabilitado ? 'fas fa-check' : 'fas fa-ban'"
            :color="item.inhabilitado ? 'success' : 'error'"
            :title="item.inhabilitado ? 'Habilitar' : 'Inhabilitar'"
            :texto="item.inhabilitado
              ? `¿Desea habilitar el control de stock <strong>${item.descripcion}</strong>?`
              : `¿Desea inhabilitar el control de stock <strong>${item.descripcion}</strong>?`"
            :icon_button="true"
            :small="true"
            @action="item.inhabilitado ? habilitar(item) : inhabilitar(item)"
          />
        </template>
        <template v-slot:no-data>
          <v-alert
            class="mx-auto mt-4"
            max-width="400"
            type="warning"
            border="left"
            dense
            text
          >
            No hay datos para los filtros seleccionados
          </v-alert>
        </template>
      </v-data-table>
    </v-col>
  </v-row>
</template>

<script>
import { mapState } from 'vuex'
import { order_list_by } from '../../util/utils'
import BtnFiltro from '../../components/util/BtnFiltro'
import BtnConfirmar from '../../components/util/BtnConfirmar'
import SearchDataTable from '../../components/util/SearchDataTable'
import FechaPickerRango from '../../components/util/FechaPickerRango'

export default {
  data () {
    return {
      panel: 0,
      search: '',
      load: false,
      limpiar_fecha: false,
      bodegas: [],
      configuraciones: [],
      headers: [
        { text: 'Descripción', value: 'descripcion' },
        { text: 'Hora inicio', value: 'hora_inicio' },
        { text: 'Hora fin', value: 'hora_fin' },
        { text: 'Duración', value: 'duracion' },
        { text: 'Acciones', value: 'actions', sortable: false, filterable: false, align: 'center' },
      ],
      filtro: {
        empresa: null,
        sucursal: null,
        deposito: null,
        descripcion: '',
        inhabilitado: 0
      }
    }
  },
  async created () {
    this.$store.state.loading = true
    await this.$store.dispatch('controlStock/get_tipos')
    this.$store.state.loading = false
  },
  computed: {
    ...mapState(['empresas', 'sucursales']),
    ...mapState('controlStock', ['tipos_control_stk'])
  },
  components: {
    BtnFiltro,
    BtnConfirmar,
    SearchDataTable,
    FechaPickerRango
  },
  methods: {
    async habilitar (item) {
      this.$store.state.loading = true
      await this.$store.dispatch('controlStock/habilitar_configuracion', item.id)
        .then((res) => {
          this.$store.dispatch('show_snackbar', {
            text: res.message,
            color: 'success'
          })
          this.quitar(item)
        })
        .catch(error => {
          this.$store.dispatch('show_snackbar', {
            text: error.message,
            color: 'error'
          })
        })
      this.$store.state.loading = false
    },
    async inhabilitar (item) {
      this.$store.state.loading = true
      await this.$store.dispatch('controlStock/inhabilitar_configuracion', item.id)
        .then((res) => {
          this.$store.dispatch('show_snackbar', {
            text: res.message,
            color: 'success'
          })
          this.quitar(item)
        })
        .catch(error => {
          this.$store.dispatch('show_snackbar', {
            text: error.message,
            color: 'error'
          })
        })
      this.$store.state.loading = false
    },
    async buscar () {
      this.load = true
      this.search = ''
      this.configuraciones = []
      await this.$store.dispatch('controlStock/get_configuraciones', this.filtro)
        .then((res) => {
          this.configuraciones = res.data
        })
        .catch(error => {
          this.$store.dispatch('show_snackbar', {
            text: error.message,
            color: 'error'
          })
        })
      this.load = false
    },
    async get_bodegas () {
      const empresa = this.filtro.empresa
      const sucursal = this.filtro.sucursal
      if (empresa && sucursal) {
        this.filtro.deposito = null
        this.bodegas = []
        this.$store.state.loading = true
        await this.$store.dispatch('bodegas/get_bodegas', {
          inhabilitada: 0,
          empresa: empresa,
          sucursal: sucursal
        })
          .then((res) => {
            this.bodegas = res.data
          })
          .catch(error => {
            this.$store.dispatch('show_snackbar', {
              text: error.message,
              color: 'error'
            })
          })
        order_list_by(this.bodegas, 'nombre')
        this.$store.state.loading = false
      }
    },
    quitar (item) {
      const index = this.configuraciones.indexOf(item)
      this.configuraciones.splice(index, 1)
    },
    open_window (id) {
      let path = '/conf/control-stock'
      if (id) {
        path = `${path}/${id}`
      }
      const routeData = this.$router.resolve({ path: path })
      window.open(routeData.href, '_blank')
    },
    limpiar () {
      this.bodegas = []
      this.filtro = {
        empresa: null,
        sucursal: null,
        deposito: null,
        descripcion: '',
        inhabilitado: 0
      }
      this.limpiar_fecha = true
    }
  }
}
</script>