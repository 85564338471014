<template>
<div>
<v-dialog
  v-model="activo"
  width="1000"
  :persistent="activo"
  scrollable
>
  <v-card class="">
    <v-card-title :class="pantallaChica? 'pb-0 px-4' : ''">
      <v-spacer v-if="pantallaChica"></v-spacer>
      <v-btn right icon @click="activo = false" x-small v-if="pantallaChica">
          <v-icon>fas fa-times</v-icon>
      </v-btn>
      <div :style="pantallaChica? 'font-size: 16.5px;' : 'font-size: 20px;'" :class="pantallaChica? '': 'd-flex align-center'">
        Compras a conciliar - <div class="body-1 mt-1 ml-1">Extracto: <strong>{{infoExtracto.id}}</strong> | Fecha: <strong>{{ infoExtracto.fecha ? infoExtracto.fecha : '-' }}</strong> | Nro.Comp: <strong>{{ infoExtracto.nro_comprobante ? infoExtracto.nro_comprobante : '-' }}</strong> | Importe: <strong>{{ infoExtracto.importe ? format_money(infoExtracto.importe) : ''}}</strong></div>
      </div>
      <v-spacer></v-spacer>
      <v-btn right icon @click="activo = false" x-small v-if="!pantallaChica">
          <v-icon>fas fa-times</v-icon>
      </v-btn>
    </v-card-title>
    <v-divider></v-divider>
    <v-card-text class="mt-3 pb-1">
      <v-form @submit.prevent="buscar()">
        <v-row class="pt-2">
          <v-col cols="12" sm="3" md="2" class="py-1">
            Empresa
            <v-autocomplete
              v-model="filtro.empresa_codigo"
              item-text="nombre_corto"
              item-value="id"
              :items="empresas"
              hide-details
              clearable
              outlined
              dense
            ></v-autocomplete>
          </v-col>
          <v-col cols="12" sm="3" md="4" class="py-1">
            Proveedor
            <v-autocomplete
              v-model="filtro.proveedor_codigo"
              item-text="proveedor_nombre"
              item-value="proveedor_codigo"
              :items="proveedores"
              hide-details
              clearable
              outlined
              dense
            ></v-autocomplete>
          </v-col>
          <v-col cols="12" sm="2" md="2" class="py-1">
            Id Fact Compra
            <v-text-field
              v-model="filtro.id_factura"
              outlined
              dense
              type="number"
              hide-details
              clearable
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="2" md="2" class="py-1">
            Estado
            <v-autocomplete
              v-model="filtro.estado"
              item-text="estado_nombre"
              item-value="estado_id"
              :items="estados"
              hide-details
              clearable
              outlined
              dense
            ></v-autocomplete>
          </v-col>
          <v-col cols="12" sm="3" md="2" class="pt-1 pb-0">
            Estado Control
            <v-autocomplete
              v-model="filtro.estado_control"
              item-text="estado_control_nombre"
              item-value="estado_control_id"
              :items="estadosControl"
              hide-details
              clearable
              outlined
              dense
            ></v-autocomplete>
          </v-col>
          <v-col cols="12" sm="5" :md="big? '6' : '3'" class="py-1">
            Fecha
            <FechaPickerRangoVue
              v-model="filtro.fecha_rango"
              hide-details
              :limpiar.sync="limpiarFecha"
              clearable
              @changeSize="changeSize"
            />
          </v-col>
          <v-spacer></v-spacer>
          <v-col cols="12" sm="3" :md="big ? '' : '3'" class="pt-0">
            <BtnFiltroVue
              :loading="load"
              @clear="limpiar()"
              @accion="buscar()"
              class="pt-0"
            />
          </v-col>
        </v-row>
      </v-form>
      <v-data-table
        class="cebra elevation-2 py-0"
        :key="c"
        :headers="headers"
        :items="facturas"
        :loading="load"
        dense
        :search="search"
        :item-class="itemRowBackground"
      >
        <!-- Buscador en el datatable -->
        <template v-slot:top>
          <v-row class="d-flex justify-end pa-2" no-gutters>
            <v-col cols="6" sm="3">
              <SearchDataTableVue
                v-model="search"
              />
            </v-col>
          </v-row>
        </template>
        <!-- Msj que se mostrara si no existen resultados -->
        <template v-slot:no-data>
          <v-alert
            class="mx-auto mt-4"
            max-width="400"
            type="warning"
            border="left"
            dense
            text
          >
            No hay datos para los filtros seleccionados
          </v-alert>
        </template>
        <!-- Formato moneda -->
        <template v-slot:[`item.total_real`]="{ item }" >
          {{ format_money(item.total_real) }}
        </template>
        <!-- Formato Fecha -->
        <template  v-slot:[`item.fecha`]="{ item }">
          {{ moment(item.fecha).format('DD/MM/YY') }}
        </template>
        <!-- Observaciones editables -->
        <template v-slot:[`item.observacion`]="{item}">
          <v-text-field
            v-model="item.observacion"
            class="d-inline-flex"
            hide-details
            dense
            outlined
          ></v-text-field>
        </template>
        <!-- Acomodo la emision y numero -->
        <template v-slot:[`item.comprobante`]="{ item }">
          <v-chip :color="item.comprobante.toString().indexOf('NC') != -1 ? 'error' : ''" small>
            {{ item.comprobante }}
          </v-chip>
        </template>
        <!-- En Negrita -->
        <template v-slot:[`item.estado_control_nombre`]="{item}">
          <strong>{{item.estado_control_nombre}}</strong>
        </template>
        <template v-slot:[`item.factura_id`]="{item}">
          <strong>{{item.factura_id}}</strong>
        </template>
        <!-- Acciones -->
        <template v-slot:[`item.accion`]="{ item }">
          <v-tooltip bottom v-if="item.seleccionado == 0">
            <template v-slot:activator="{ on }">
              <v-btn small icon color="success" @click="seleccionarFactura(item)" v-on="on" >
                <v-icon small>fas fa-check</v-icon>
              </v-btn>
            </template>
            <span>Seleccionar Factura</span>
          </v-tooltip>
          <v-tooltip bottom v-if="item.seleccionado == 1">
            <template v-slot:activator="{ on }">
              <v-btn small icon color="error" @click="quitarFactura(item)" v-on="on" >
                <v-icon small>fas fa-times</v-icon>
              </v-btn>
            </template>
            <span>Quitar Factura</span>
          </v-tooltip>
        </template>
      </v-data-table>
    </v-card-text>
    <v-divider></v-divider>
    <!-- BOTONES -->
    <v-card-actions class="pa-0">
      <v-row class="ma-0">
        <v-col cols="12" md="8" :class="pantallaChica? 'd-flex pt-2 pb-0': 'd-flex justify-space-around align-center'" :style="pantallaChica? 'font-size: 13px;' : ''">
          <p class="mb-0">
            Importe: {{ format_money(infoExtracto.importe) }}
          </p>
          <p class="mb-0">
            Pendiente: {{ format_money(infoExtracto.importe - importeFactAcu) }}
          </p>
          <p class="mb-0">
            <strong>Total Facturas: {{ format_money(importeFactAcu) }}</strong>
          </p>
        </v-col>
        <v-col cols="12" md="4" class="d-flex" :class="!pantallaChica ? 'justify-end pb-4' : 'justify-center'">
          <v-btn
            class="mr-2"
            color="secondary"
            @click="activo = false"
          >cancelar</v-btn>
          <BtnConfirmarVue
            :texto="`Conciliar la/s factura/s de compra con el extracto: ${id_conciliacion}. ¿Confirma esta accion?`"
            :nombre="'CONCILIAR'"
            :loading="load"
            @action="guardarEmit()"
          />
        </v-col>
      </v-row>
    </v-card-actions>
  </v-card>
</v-dialog>
</div>
</template>

<script>
import moment from 'moment';
import BtnConfirmarVue from '../util/BtnConfirmar.vue';
import FechaPickerVue from '../util/FechaPicker.vue';
import FechaPickerRangoVue from '../util/FechaPickerRango.vue';
import BtnFiltroVue from '../util/BtnFiltro.vue';
import SearchDataTableVue from '../util/SearchDataTable.vue';
import { format_money } from '../../util/utils';

export default {
  name: 'ModalGetFactCompra',
  props:{
    value: Boolean,
    datos:{
      type: Object,
      default: {}
    }  
  },
  computed:{
    activo:{
      get(){
        return this.datos.activo;
      },
      set (value) {
        this.$emit('input', value)
      }
    },
    id_conciliacion:{
      get(){
        return this.datos.infoExtracto.id;
      },
    },
    infoExtracto:{
      get(){
        return this.datos.infoExtracto;
      },
    }
  },
  data(){
    return{
      c: 0,
      pantallaChica: this.$vuetify.breakpoint.xs,
      format_money: format_money,
      moment: moment,
      feriadoCopia: {},
      load: false,
      search: '',
      limpiarFecha: false,
      filtro:{
        empresa_codigo: null,
        proveedor_codigo: null,
        fecha_rango: [null,null],
        id_factura: null,
        estado: null,
        estado_control: null,
      },
      facturas:[],
      facturasConciliadas: [],
      proveedores: [],
      estados: [],
      estadosControl: [],
      big: false,
      headers:[
        { text: 'Id', value: 'factura_id' },
        { text: 'Fecha', value: 'fecha' },
        { text: 'Estado', value: 'estado_nombre' },
        { text: 'Estado Control', value: 'estado_control_nombre', },
        { text: 'Emis-Nro', value: 'comprobante',},
        { text: 'Importe', value: 'total_real',  align:'end', formatter: format_money  },
        { text: 'Observacion', value: 'observacion'},
        { text: 'Accion', value: 'accion', align:'center', sorteable: false, filtreable: false},
      ],
      pendiente: 0,
      importeFactAcu: 0,

    }
  },
  created(){
    this.init();
  },
  methods:{
    async init(){
      this.$store.state.loading = true
      this.empresas = JSON.parse(localStorage.getItem('empresas'));
      if(this.empresas.length == 1){
        this.filtro.empresa_codigo = this.empresas[0].id;
      }
      // obtener los estados
      this.$store.dispatch('bejerman/get_fc_data')
        .then(response => {
          this.$store.state.loading = false
          if (response.resultado == 1){
            this.estados = response.estados
            this.proveedores = response.proveedores
            this.estadosControl = response.estados_control
          }else{
            return this.$store.dispatch('show_snackbar', {text: response.msj, color: 'error', })
          }
        })
        .catch(error => {
          this.$store.state.loading = false
          this.$store.dispatch('show_snackbar', {
            text: 'No se pudo obtener los estados de facturas: ' + error.message,
            color: 'error',
          })
          return
        })
    },
    guardarEmit(){
      if(this.facturasConciliadas.length != 0){
        this.$emit('conciliar', this.facturasConciliadas, this.id_conciliacion);
      }else{
        return this.$store.dispatch('show_snackbar', {text: 'Debe seleccionar al menos una factura para continuar.', color: 'info',  })
      }
    },
    limpiar(){
      this.filtro.empresa_codigo = null;
      this.filtro.proveedor_codigo = null;
      this.limpiarFecha = true;
      this.filtro.id_factura = null;
      this.filtro.estado = null;
      this.filtro.estado_control = null;
      this.search = '';
    },
    changeSize (custom) {
      this.big = custom
    },

    async buscar(){
      //campos mínimos para el filtro
      if(!this.filtro.empresa_codigo){
        return this.$store.dispatch('show_snackbar', {text: 'Se requiere seleccionar empresa', color: 'warning', })
      }
      if(!this.filtro.proveedor_codigo){
        return this.$store.dispatch('show_snackbar', {text: 'Se requiere seleccionar proveedor', color: 'warning', })
      }

      if (!this.filtro.fecha_rango[0] ){
        this.$store.dispatch('show_snackbar', {text: 'Se requiere ingresa un fecha', color: 'warning',  })
        return
      }

      this.search = ''
      this.facturas = []

      this.load = true
      let res = await this.$store.dispatch('bejerman/get_facturas_compra_admin', {
        idFactura: this.filtro.id_factura ? this.filtro.id_factura : '',
        estado:  this.filtro.estado ? this.filtro.estado : 0,
        estadoControl: this.filtro.estado_control ? this.filtro.estado_control : 0,
        fechaDesde: moment(this.filtro.fecha_rango[0]).format('DD/MM/YYYY'),
        fechaHasta: this.filtro.fecha_rango[1] ? moment(this.filtro.fecha_rango[1]).format('DD/MM/YYYY') : '',
        tipoFactura: '',
        emision: '',
        numero: '',
        referencia: '',
        usuario: '',
        empresa: this.filtro.empresa_codigo,
        proveedor: this.filtro.proveedor_codigo,
        sucursal: 0,
        fDesde: '',
        fHasta: '',
        ordenPago: 0
      });
      this.load = false

      if(res.resultado == 0){
        return this.$store.dispatch('show_snackbar', {text: res.message, color: 'error'})
      }
      this.facturas = res.facturasComp;
      this.facturas.forEach(e => {
        e.seleccionado = 0;
        e.observacion = '';
      })
    },
    itemRowBackground (item) {
      return item.seleccionado == 1 ? 'background-color: rowsuc' : '';
    },
    seleccionarFactura(item){
      this.c += 1;
      let pos = this.facturas.indexOf(item);
      this.facturas[pos].seleccionado = 1;
      this.facturasConciliadas.push(item);

      this.importeFactAcu += item.total_real;
    },
    quitarFactura(item){
      this.c -= 1;
      let pos = this.facturas.map(e => e.factura_id).indexOf(item.factura_id);
      this.facturas[pos].seleccionado = 0;

      const index = this.facturasConciliadas.map(e => e.factura_id).indexOf(item.factura_id)
      this.facturasConciliadas.splice(index, 1);
      
      this.importeFactAcu -= item.total_real;
    }
  },
  components:{
  FechaPickerVue, BtnConfirmarVue, FechaPickerRangoVue, BtnFiltroVue, SearchDataTableVue
  },
  watch:{
    activo: function(val){
      this.limpiar();
      if(!val){
        this.facturas = [];
        this.facturasConciliadas = [];
      }
    },
    '$vuetify.breakpoint.xs'(val){
      this.pantallaChica = val;
    },
    // 'feriadoCopia.id_tipo': {
    //     handler: function(val){
    //       if(val){
    //           this.feriadoCopia.nombre_tipo_feriado = (this.tipos.filter(e => e.tipo_id == val))[0].tipo_nombre
    //       }else this.feriadoCopia.nombre_tipo_feriado = ''
    //     },
    //     deep: true,
    // },
    // 'feriadoCopia.sucursal_codigo': {
    //     handler: function(val){
    //     if(val){
    //           this.feriadoCopia.sucursal_nombre = (this.sucursales.filter(e => e.sucursal_codigo == val))[0].sucursal_nombre
    //       }else this.feriadoCopia.sucursal_nombre = ''
    //     },
    //     deep: true,
    // }
  }
}
</script>

<style>

</style>