<template>
  <div>
    <v-dialog
      v-model="activo"
      max-width="60%"
    >
      <v-card
        flat
      >
        <v-card-title>
          Seleccione a qué detalle de la Orden de Compra corresponde el elemento elegido:
          <v-spacer></v-spacer>
          <v-btn icon ><v-icon @click="activo = false" title="Cerrar">fas fa-times</v-icon></v-btn>
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col cols="12">
              <v-data-table
                class="cebra elevation-2"
                :headers="headers"
                :items="detalles"
                :loading="load"
                dense
                :search="search"
              >
                <!-- Buscador en el datatable -->
                <template v-slot:top>
                  <v-row class="d-flex justify-end pa-2" no-gutters>
                    <v-col cols="6" sm="3">
                      <SearchDataTable
                        v-model="search"
                      />
                    </v-col>
                  </v-row>
                </template>
                <!-- Msj que se mostrara si no existen resultados -->
                <template v-slot:no-data>
                  <v-alert
                    class="mx-auto mt-4"
                    type="warning"
                    border="left"
                    dense
                    text
                  >
                    Sin detalles para seleccionar.
                  </v-alert>
                </template>
                <!-- Acciones -->
                <template v-slot:[`item.acciones`]="{ item }">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                      <v-btn small icon color="success" @click="confirmar(item)" v-on="on">
                        <v-icon small>far fa-check-circle</v-icon>
                      </v-btn>
                    </template>
                    <span>Seleccionar</span>
                  </v-tooltip>
                </template>
              </v-data-table>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
    <!-- Modal Confirmar Acción -->
    <ModalConfirmar 
      :activoDialog="dialogConf"
      :texto="`¿Está seguro de seleccionar este detalle?`"
      @action="seleccionar()"
      @setearModalConfAccion="setModalAsociar"
    />
  </div>
</template>

<script>
import SearchDataTable from '../util/SearchDataTable.vue'
import ModalConfirmar from '../util/ModalConfirmar.vue'
export default {
  props: {
    datos: {
      type: Object,
      default: {}
    },
  },
  components: {
    SearchDataTable,
    ModalConfirmar
  },
  computed: {
    activo: {
      get() {
        return this.datos.activo
      },
      set(value) {
        this.$emit('setearModalDetPendOC', value)
      }
    },
    detalles: {
      get() {
        return this.datos.detsOrdCompPend
      }
    }
  },
  data() {
    return {
      headers: [
        { text: 'Art. Código', align: 'center', value: 'articulo_codigo' },
        { text: 'Art. Nombre', align: 'left', value: 'articulo_nombre' },
        { text: 'Cant.', align: 'center', value: 'cantidad' },
        { text: 'Cant. Remito', align: 'center', value: 'cantidad_remito' },
        { text: 'Cód. Barras', align: 'center', value: 'codigo_barras' },
        { text: 'Acciones', align: 'center', value: 'acciones' }
      ],
      load: false,
      search: '',
      dialogConf: false,
      selectedItem: {}
    }
  },
  methods: {
    limpiarForm(){
      this.search = ''
      this.selectedItem = {}
    },
    confirmar(item){
      this.selectedItem = item
      this.dialogConf = true
    },
    setModalAsociar(value){
      this.dialogConf = value
    },
    seleccionar(){
      this.$emit('setDetPendiente', this.selectedItem)
      this.activo = false
    }
  },
  watch: {
    activo: function(){
      if (this.activo == false) this.limpiarForm()
    }
  },
}
</script>

<style>

</style>