<template>
  <v-dialog
    v-model="dialog"
    max-width="800px"
    :persistent="load"
    :fullscreen="fullscreen"
    scrollable
  >
    <v-card
      :loading="load"
      :disabled="load"
    >
      <v-card-title>
        {{ nuevo ? 'Nuevo inventario' : 'Detalle inventario Nº ' + codigo }}
        <v-spacer></v-spacer>
        <v-btn
          icon
          class="mr-2"
          :title="fullscreen ? 'Minimizar' : 'Maximizar'"
          @click="fullscreen = !fullscreen"
        >
          <v-icon>fas fa-window-{{ fullscreen ? 'restore' : 'maximize' }}</v-icon>
        </v-btn>
        <v-btn
          icon
          title="Cerrar"
          @click="dialog = false"
        >
          <v-icon>fas fa-times</v-icon>
        </v-btn>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text class="pt-8">
        <v-form ref="form">
          <v-row>
            <v-col cols="6" md="2" class="py-0">
              <v-text-field
                v-model="inventario.fecha"
                label="Fecha"
                outlined
                readonly
                filled
                dense
              ></v-text-field>
            </v-col>
            <v-col cols="6" md="3" class="py-0">
              <v-text-field
                v-model="inventario.usuario"
                label="Usuario"
                outlined
                readonly
                filled
                dense
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6" md="4" class="py-0">
              <v-autocomplete
                v-model="inventario.empresa"
                label="Empresa"
                item-text="nombre_corto"
                item-value="id"
                :items="empresas"
                :rules="nuevo ? [rules.required] : []"
                :readonly="!nuevo"
                :filled="!nuevo"
                :autofocus="nuevo"
                validate-on-blur
                outlined
                dense
                @change="get_bodegas()"
              ></v-autocomplete>
            </v-col>
            <v-col cols="12" sm="6" md="3" class="py-0">
              <v-autocomplete
                v-model="inventario.sucursal"
                label="Sucursal"
                item-text="nombre"
                item-value="id"
                :items="sucursales"
                :rules="nuevo ? [rules.required] : []"
                :readonly="!nuevo"
                :filled="!nuevo"
                validate-on-blur
                outlined
                dense
                @change="get_bodegas(); set_responsable({ codigo: null, nombre: null })"
              ></v-autocomplete>
            </v-col>
            <v-col cols="12" sm="6" md="5" class="py-0">
              <v-autocomplete
                v-model="inventario.deposito"
                label="Bodega"
                ref="bodegaSel"
                item-text="nombre"
                item-value="codigo"
                :items="bodegas"
                :rules="nuevo ? [rules.required] : []"
                :readonly="!nuevo"
                :filled="!nuevo"
                validate-on-blur
                outlined
                dense
              ></v-autocomplete>
            </v-col>
            <v-col cols="4" sm="6" md="2" class="py-0">
              <v-text-field
                v-model.trim="inventario.responsable_codigo"
                label="Responsable"
                type="number"
                title="Responsable"
                :rules="nuevo ? [rules.required] : []"
                :readonly="!nuevo"
                :filled="!nuevo"
                validate-on-blur
                outlined
                dense
                @blur="nuevo ? buscar_responsable() : ''"
              ></v-text-field>
            </v-col>
            <v-col cols="8" sm="6" md="5" class="py-0">
              <v-text-field
                v-model="inventario.responsable_nombre"
                label="Nombre"
                outlined
                readonly
                filled
                dense
              >
                <template v-slot:prepend>
                  <v-icon
                    class="mt-1 ml-n4 mr-2"
                    color="info"
                    title="Buscar responsable"
                    :disabled="!nuevo"
                    small
                    @click="dialog_buscar = true"
                  >
                    fas fa-search
                  </v-icon>
                </template>
              </v-text-field>
            </v-col>
            <v-col cols="12" class="py-0">
              <v-textarea
                v-model="inventario.referencia"
                label="Referencia"
                rows="3"
                :readonly="!nuevo"
                :filled="!nuevo"
                hide-details
                auto-grow
                outlined
                dense
                @blur="nuevo && !archivo ? agregar_art(null) : ''"
              ></v-textarea>
            </v-col>
            <v-col cols="12" class="pt-4 pb-0">
              <v-card outlined>
                <v-card-title class="body-1 pt-3">
                  Artículos
                  <v-spacer></v-spacer>
                  <DownloadDataTable
                    v-if="!nuevo"
                    :data="articulos"
                    :name="`Inventario Nº ${inventario.codigo} - ${inventario.deposito_nombre}`"
                    :headers="{
                      'Artículo': 'codigo',
                      'Nombre': 'nombre',
                      'Stock Virtual': 'stock_virtual',
                      'Stock Real': 'stock_real',
                      'Diferencia': 'diferencia'
                    }"
                  />
                  <download-excel
                    v-if="nuevo"
                    name="Modelo de archivo para inventario.xls"
                    :data="[{a:'',d:''}]"
                    :fields="{
                      'Articulo': 'a',
                      'Diferencia': 'd'
                    }"
                  >
                    <v-btn
                      title="Descargar modelo"
                      color="green darken-3"
                      class="ml-2"
                      :x-small="$vuetify.breakpoint.smAndDown"
                      :small="$vuetify.breakpoint.mdAndUp"
                      :fab="$vuetify.breakpoint.smAndDown"
                      dark
                    >
                      <v-icon
                        :left="$vuetify.breakpoint.mdAndUp"
                        small
                      >
                        fas fa-file-download
                      </v-icon>
                      <span v-text="$vuetify.breakpoint.mdAndUp ? 'Modelo' : ''"></span>
                    </v-btn>
                  </download-excel>
                  <v-btn
                    v-if="nuevo"
                    title="Importar archivo"
                    color="green darken-3"
                    class="ml-2"
                    :x-small="$vuetify.breakpoint.smAndDown"
                    :small="$vuetify.breakpoint.mdAndUp"
                    :fab="$vuetify.breakpoint.smAndDown"
                    :disabled="deposito"
                    :dark="!deposito"
                    @click="$refs.input.click()"
                  >
                    <v-icon
                      :left="$vuetify.breakpoint.mdAndUp"
                      small
                    >
                      fas fa-file-upload
                    </v-icon>
                    <span v-text="$vuetify.breakpoint.mdAndUp ? 'Importar' : ''"></span>
                    <input
                      ref="input"
                      type="file"
                      class="d-none"
                      :accept="tipos_validos.join(',')"
                      @change="validar_archivo($event)"
                    >
                  </v-btn>
                  <v-btn
                    v-if="nuevo"
                    title="Agregar artículo"
                    color="success"
                    class="ml-2"
                    :x-small="$vuetify.breakpoint.smAndDown"
                    :small="$vuetify.breakpoint.mdAndUp"
                    :fab="$vuetify.breakpoint.smAndDown"
                    :disabled="deposito || archivo"
                    @click="agregar_art(null)"
                  >
                    <v-icon
                      :left="$vuetify.breakpoint.mdAndUp"
                      small
                    >
                      fas fa-plus
                    </v-icon>
                    <span v-text="$vuetify.breakpoint.mdAndUp ? 'Agregar' : ''"></span>
                  </v-btn>
                </v-card-title>
                <v-card-text class="pa-0 mt-2 mb-sm-3 mb-5">
                  <v-row>
                    <v-col cols="12" class="py-0">
                      <v-divider></v-divider>
                      <v-data-table
                        class="cebra"
                        :headers="headers"
                        :items="articulos"
                        :items-per-page="nuevo ? -1 : 10"
                        :hide-default-footer="nuevo"
                        :disable-sort="deposito"
                        :footer-props="nuevo ? {} : {'items-per-page-options':[10, 15, 50, 100]}"
                        dense
                      >
                        <template v-slot:[`item.codigo`]="{ item }">
                          <v-text-field
                            v-model="item.codigo"
                            type="number"
                            class="pb-2"
                            :ref="`dtCod${item.id}`"
                            :disabled="!nuevo || archivo"
                            hide-details
                            dense
                            @blur="buscar_art(item)"
                          ></v-text-field>
                        </template>
                        <template v-slot:[`item.diferencia`]="{ item }">
                          <v-text-field
                            v-model="item.diferencia"
                            type="number"
                            class="pb-2"
                            :ref="`dtDif${item.id}`"
                            :disabled="!nuevo || archivo"
                            hide-details
                            dense
                            @blur="calcular_diff(item)"
                          ></v-text-field>
                        </template>
                        <template v-slot:[`item.actions`]="{ item }">
                          <v-icon
                            color="error"
                            title="Quitar"
                            class="mr-2"
                            :disabled="!nuevo || archivo"
                            small
                            @click="quitar_art(item)"
                          >
                            fas fa-times-circle
                          </v-icon>
                          <v-icon
                            color="success"
                            title="Series"
                            class="mr-2"
                            :disabled="!nuevo"
                            small
                            @click="ver_series(item)"
                            v-if="item.solicita_serie == 1 && item.diferencia != null && parseInt(item.diferencia) < 0"
                          >
                            fas fa-list
                          </v-icon>
                          <v-icon
                            color="warning"
                            title="Series Inconistentes"
                            class="mr-2"
                            :disabled="!nuevo"
                            small
                            @click="ver_series(item)"
                            v-if="item.series_ok == 0"
                          >
                            fas fa-exclamation-triangle
                          </v-icon>
                        </template>
                        <template v-slot:no-data>
                          <v-alert
                            class="mx-auto mt-4"
                            max-width="400"
                            type="warning"
                            border="left"
                            dense
                            text
                          >
                            No se agregaron artículos
                          </v-alert>
                        </template>
                      </v-data-table>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-card-actions class="d-flex justify-end pb-4">
        <v-row class="d-flex justify-end pa-3">
        <div
          v-if="nuevo"
          class="d-flex align-center ml-sm-3 ml-16 mb-2 mb-sm-0"
        >
          Total de artículos: {{ articulos.length }}
        </div>
        <v-spacer v-if="$vuetify.breakpoint.smAndUp"></v-spacer>
        <v-btn
          class="ml-2 mb-2 mb-sm-0"
          :color="nuevo ? 'error' : 'secondary'"
          :disabled="load"
          @click="dialog = false"
        >
          {{ nuevo ? 'Cancelar' : 'Volver' }}
        </v-btn>
        <BtnConfirmar
          v-if="nuevo"
          clase="ml-2"
          :loading="load"
          :disabled="articulos.length == 0"
          @action="guardar()"
        />
        </v-row>
      </v-card-actions>
    </v-card>
    <ModalVendedoresAcc
      v-model="dialog_buscar"
      titulo_nombre="responsable"
      :sucursal_codigo="inventario.sucursal"
      @obtener="set_responsable"
    />
    <!-- Modal Seleccionar Series -->
    <ControlSeries 
      :datosSeries="seriesParam"
      :showVolver="parseInt(agregarDetalle)"
      @setearModalCSeries="setModalCSeries"
      @confirmanSeries="setSeriesArticulo"
    />
  </v-dialog>
</template>

<script>
/**
 *  Modal para ver o crear un inventario
 * 
 *  Notas: este componente emite acciones @nuevo al padre caundo se crea un inventario (en caso de estar definidas)
 *         todos los props son obligatorios
 */
import moment from 'moment'
import { mapState } from 'vuex'
import { read, utils } from 'xlsx'
import { order_list_by, tipos_archivos } from '../../util/utils'
import BtnConfirmar from '../util/BtnConfirmar'
import ModalVendedoresAcc from '../util/ModalVendedoresAcc'
import DownloadDataTable from '../util/DownloadDataTable'
import ControlSeries from '../generales/ControlSeries.vue'

export default {
  data () {
    return {
      load: false,
      nuevo: false,
      archivo: false,
      deposito: false,
      fullscreen: false,
      dialog_buscar: false,
      control_id: null,
      cant_art: 0,
      bodegas: [],
      articulos: [],
      headers: [
        { text: 'Cod. Interno', value: 'codigo', align: 'end', width: '100' },
        { text: 'Artículo', value: 'nombre', divider: true  },
        { text: 'Stock virual', value: 'stock_virtual', align: 'end' },
        { text: 'Stock real', value: 'stock_real', align: 'end' },
        { text: 'Diferencia', value: 'diferencia', align: 'end', width: '50', divider: true },
        { text: 'Acciones', value: 'actions', align: 'center', sortable: false, filterable: false },
      ],
      tipos_validos: ['.xls', '.xlsx', '.ods'], // los tipos de archivos están en /util/utils.js
      inventario: {},
      rules: {
        required: value => !!value || 'Campo requerido',
      },
      seriesParam: {
        dialogSerie: false, // para abrir el modal de seleccion de series
        articulo_codigo: 0, // codigo del articulo del que voy a seleccionar las series
        articulo_cod_barras: '',
        articulo_nombre: '',  // idem al anterior
        series_disponibles: [], // TODAS LAS SERIES disponibles del artículo, se obtendría consultando las series en stock en la base de datos y quitando las series seleccionadas
        series_seleccionadas: [], // series seleccionadas por el usuario a remitir
        cantidad: 0, // cantidad de series que debe seleccionar el usuario para poder terminar de ingresar un artículo al detalle a remitir
        precio: 0,
        importe: 0,
        solicita_serie: 0,
        tipo: 0
      },
      agregarDetalle: 1,
      pos: -1
    }
  },
  props: {
    value: Boolean,
    codigo: Number,  // codigo de inventario
    control: Object, // variable para crear un inventario a partir de un control de stock
  },
  computed: {
    ...mapState(['empresas', 'sucursales']),
    dialog: {
      get () {
        return this.value
      },
      set (value) {
        this.$emit('input', value)
      }
    }
  },
  components: {
    BtnConfirmar,
    ModalVendedoresAcc,
    DownloadDataTable,
    ControlSeries
  },
  watch: {
    async dialog (val) {
      if (val) {
        if (this.codigo) {
          this.nuevo = false
          // obtiene los valores
          this.load = true
          await this.$store.dispatch('inventario/get_inventario', this.codigo)
            .then((res) => {
              this.inventario = res.data
              this.articulos = this.inventario.articulos
              this.bodegas.push({
                codigo: this.inventario.deposito,
                nombre: this.inventario.deposito_nombre
              })
            })
            .catch(error => {
              this.$store.dispatch('show_snackbar', {
                text: error.message,
                color: 'error'
              })
            })
          this.load = false
        } else {
          this.nuevo = true
          // si control existe es pq se creo desde un control de stock y hereda los datos
          if (this.control) {
            this.control_id = this.control.id
            this.inventario = {
              fecha: moment(new Date()).format('DD/MM/YYYY'),
              usuario: this.$store.state.username,
              empresa: this.control.empresa,
              sucursal: this.control.sucursal,
              deposito: this.control.deposito,
              responsable_codigo: null,
              responsable_nombre: null,
              referencia: this.control.referencia
            }
            this.bodegas = this.control.bodegas
            this.articulos = this.control.articulos
          } else {
            // como es nuevo setea los campos en null
            this.control_id = null
            this.inventario = {
              fecha: moment(new Date()).format('DD/MM/YYYY'),
              usuario: this.$store.state.username,
              empresa: null,
              sucursal: null,
              deposito: null,
              responsable_codigo: null,
              responsable_nombre: null,
              referencia: null
            }
          }
        }
      } else {
        this.$refs.form.resetValidation()
        this.control_id = null
        this.inventario = {
          fecha: null,
          usuario: null,
          empresa: null,
          sucursal: null,
          deposito: null,
          responsable_codigo: null,
          responsable_nombre: null,
          referencia: null
        }
        this.fullscreen = false
        this.archivo = false
        this.articulos = []
        this.bodegas = []
        this.cant_art = 0
      }
    },
    'inventario.deposito' (val) {
      if (val) {
        this.deposito = false
      } else {
        this.deposito = true
      }
    }
  },
  methods: {
    async guardar () {
      if (this.$refs.form.validate()) {

        let falta_diff = false
        let arts = []

        // 04-08-2023 por MMURILLO, controlo la consistencia de las series
        let seriesValidas = this.validarSeries()
        if (seriesValidas.valido == false){
          this.$store.dispatch('show_snackbar', {
            text: seriesValidas.msj,
            color: 'warning'
          })
          return
        }

        // formatea el array de articulos
        for (const art of this.articulos) {
          // valdia que el articulo tenga diferencia
          if (!art.diferencia && art.nombre) {
            this.$store.dispatch('show_snackbar', {
              text: 'Falta la diferencia del articulo ' + art.nombre,
              color: 'warning'
            })
            falta_diff = true
            return
          }
          // valida que no haya stock real negativo
          if (parseInt(art.stock_real) < 0) {
            this.$store.dispatch('show_snackbar', {
              text: 'No puede haber stock real negativo en el articulo ' + art.nombre,
              color: 'warning'
            })
            falta_diff = true
            return
          }
          if (art.codigo) {
            arts.push({
              codigo: art.codigo,
              virt: art.stock_virtual,
              real: art.stock_real,
              diff: art.diferencia,
              solicita_serie: art.solicita_serie,
              series: art.series
            })
          }
        }
        if (falta_diff) return

        // valida que haya al menos 1 art
        if (arts.length == 0) {
          this.$store.dispatch('show_snackbar', {
            text: 'Debe agregar al menos un articulo',
            color: 'warning'
          })
          return
        }
        // formatea la data a enviar
        let data = JSON.parse(JSON.stringify(this.inventario))
        data.control_id = this.control_id
        data.articulos = arts
        this.load = true
        await this.$store.dispatch('inventario/nuevo', data)
          .then((res) => {
            this.dialog = false
            this.$store.dispatch('show_snackbar', {
              text: res.message,
              color: 'success'
            })
            // emite la accion 'nuevo' definifa en el padre
            this.$emit('nuevo', res.codigo)
          })
          .catch(error => {
            this.$store.dispatch('show_snackbar', {
              text: error.message,
              color: 'error'
            })
          })
        this.load = false
      }
    },
    validarSeries(){
      // tengo articulos con series?
      let conSeries = this.articulos.filter(element => element.solicita_serie == 1 && parseInt(element.diferencia) <= 0)
      if (conSeries.length > 0){
        for (let id in conSeries){
          if (conSeries[id].diferencia == null || conSeries[id].diferencia == undefined || conSeries[id].diferencia.toString().length == 0 || conSeries[id].diferencia == 0) return {valido: false, msj: 'La diferencia no puede ser cero.'}
          if (conSeries[id].series.length == 0) return {valido: false, msj: 'No se puede grabar sin seleccionar las series.'}
          if (Math.abs(conSeries[id].diferencia) != conSeries[id].series.length) return {valido: false, msj: 'Las series seleccionadas no coinciden con la diferencia.'}
        }
      }
      return {valido: true, msj: 'OK'}
    },
    async buscar_responsable () {
      const codigo = this.inventario.responsable_codigo
      const sucursal = this.inventario.sucursal
      if (codigo) {
        if (!sucursal) {
          this.$store.dispatch('show_snackbar', {
            text: 'Debe seleccionar una sucursal',
            color: 'warning'
          })
          return
        }
        this.$store.state.loading = true
        await this.$store.dispatch('genericosAcc/get_vendedores', {
          codigo: codigo,
          sucursal: sucursal
        })
          .then((res) => {
            this.inventario.responsable_nombre = res.item.nombre
          })
          .catch(error => {
            this.$store.dispatch('show_snackbar', {
              text: error.message,
              color: 'error'
            })
            // limpia los datos
            this.inventario.responsable_codigo = null
            this.inventario.responsable_nombre = null
          })
        this.$store.state.loading = false
      }
    },
    async get_bodegas () {
      const empresa = this.inventario.empresa
      const sucursal = this.inventario.sucursal
      if (empresa && sucursal) {
        this.inventario.deposito = null
        this.bodegas = []
        this.$store.state.loading = true
        await this.$store.dispatch('bodegas/get_bodegas', {
          inhabilitada: 0,
          empresa: empresa,
          sucursal: sucursal
        })
          .then((res) => {
            this.bodegas = res.data
          })
          .catch(error => {
            this.$store.dispatch('show_snackbar', {
              text: error.message,
              color: 'error'
            })
          })
        order_list_by(this.bodegas, 'nombre')
        this.$store.state.loading = false
      }
    },
    async buscar_art (item) {
      const deposito = this.inventario.deposito
      if (!deposito) {
        this.$store.dispatch('show_snackbar', {
          text: 'Seleccione una bodega',
          color: 'warning'
        })
        this.$refs.bodegaSel.focus()
        return
      }
      if (!item.codigo) {
        return
      }
      // verifica que no quiera agregar un articulo ya existente
      const existe = this.articulos.filter(a => a.codigo == item.codigo)
      if (existe.length > 1) {
        this.$store.dispatch('show_snackbar', {
          text: 'Ya se agrego el articulo ' + item.codigo,
          color: 'warning'
        })
        item.codigo = null
        this.$refs[`dtCod${item.id}`].focus()
        return
      }
      this.$store.state.loading = true
      await this.$store.dispatch('inventario/get_articulo', {
        articulo: item.codigo,
        deposito: deposito
      })
        .then((res) => {
          const data = res.data
          item.nombre = data.nombre
          item.stock_virtual = data.stock_virtual
          item.solicita_serie = data.solicita_serie
          // setea el foco en el input de diferencia
          this.$refs[`dtDif${item.id}`].focus()
        })
        .catch(error => {
          this.$store.dispatch('show_snackbar', {
            text: error.message,
            color: 'error'
          })
          // limpia los campos y vuelve el foco al input de codigo art
          item.codigo = null
          item.nombre = null
          item.stock_virtual = null
          item.stock_real = null
          item.diferencia = null
          this.$refs[`dtCod${item.id}`].focus()
        })
      this.$store.state.loading = false
    },
    async agregar_art (id_q_perdio_el_foco) {
      if (this.inventario.deposito) {
        const index = this.articulos.length - 1
        if (index >= 0) {
          // obtiene el ultimo articulo que se agrego
          const last = this.articulos[index]
          // solo si es la ultima row la que perdio el foco (o null en caso de utilizar el oboton) agrega una nueva
          if (id_q_perdio_el_foco == last.id || id_q_perdio_el_foco == null) {
            // si su codigo de art es null entonces no agrega nada y vuelve el foco al input de codigo art
            if (last.codigo) {
              this.agregar_row()
            } else {
              this.$refs[`dtCod${last.id}`].focus()
            }
          }
        } else {
          this.agregar_row()
        }
      }
    },
    async agregar_row () {
      this.cant_art ++
      // 04-08-2023 por MMURILLO, agregamos control de series
      await this.articulos.push({
        id: this.cant_art,
        codigo: null,
        nombre: null,
        stock_virtual: null,
        stock_real: null,
        diferencia: null,
        solicita_serie: null,
        series: [],
        series_ok: 1
      })
      this.$refs[`dtCod${this.cant_art}`].focus()
    },
    async obtener_articulos (array) {
      const deposito = this.inventario.deposito
      if (!deposito) {
        this.$store.dispatch('show_snackbar', {
          text: 'Seleccione una bodega',
          color: 'warning'
        })
        this.$refs.bodegaSel.focus()
        return
      }

      this.archivo = false
      this.articulos = []

      // valida que no haya repetidos
      let repetidos = false
      for (let index = 0; index < array.length; index++) {
        const art = array[index]
        const existe = await array.filter(a => a.articulo == art.articulo)
        if (existe.length > 1) {
          this.$store.dispatch('show_snackbar', {
            text: `El articulo <strong>${art.articulo}</strong> se encuentra duplicado en la fila ${index + 2}. Revise el archivo y vuelva a subirlo`,
            color: 'warning',
            timeout: 4000
          })
          repetidos = true
          return
        }
      }
      if (repetidos) return

      // solo si selecciono una bodega y no encontro repetidos obtiene la informacion
      this.$store.state.loading = true
      await this.$store.dispatch('inventario/get_articulos', {
        articulos: array,
        deposito: deposito
      })
        .then((res) => {
          this.archivo = true
          this.articulos = res.data
        })
        .catch(error => {
          this.$store.dispatch('show_snackbar', {
            text: error.message,
            color: 'error',
            timeout: 4000
          })
        })
      this.$store.state.loading = false
    },
    validar_archivo (event) {
      const files = event.target.files
      if (files) {
        // obtiene el archivo (en este caso solo puede subir 1)
        const file = files[0]
        const tipo = file.type

        // busca en el array de tipos_archivos el tipo de archivo del archivo que subio
        const tipo_archivo = tipos_archivos.find(a => a.mime == tipo)

        // verifica que exista el tipo de archivo en el array
        if (tipo_archivo) {
          // verifica que pertenezca por lo menos a un tipo de extension existente en el array de tipos
          if (this.tipos_validos.find(a => a == tipo_archivo.extension)) {
            // el archivo que subio es correcto, pasa a convertirlo a json
            try {

              const reader = new FileReader()
              reader.onload = (e) => {
                const bytes = e.target.result
                const excel = read(bytes, { type: 'binary' })
                // obtiene el nombre de la primer hoja
                const hoja_nombre = excel.SheetNames[0]
                // obtiene la primer hoja
                const hoja = excel.Sheets[hoja_nombre]
                // obtiene la data de la primer hoja
                const data = utils.sheet_to_json(hoja, { header: 1 })
                // cicla por cada row de la primer hoja (excluyendo los headers)
                let arts = []
                for (let index = 1; index < data.length; index++) {
                  const row = data[index]
                  // solo si la row tiene algun dato lo agrega al json
                  if (row.length > 0) {
                    arts.push({
                      articulo: row[0],
                      diferencia: row[1]
                    })
                  }
                }
                this.obtener_articulos(arts)
              }

              reader.readAsBinaryString(file)

            } catch (error) {
              this.$store.dispatch('show_snackbar', {
                text: 'Ocurrio un error inesperado al convertir el archivo a JSON: ' + error,
                color: 'error',
                timeout: 4000
              })
            }
          } else {
            // subio un archivo con una extension no valida
            this.$store.dispatch('show_snackbar', {
              text: 'Tipo de archivo no válido',
              color: 'error'
            })
          }
        } else {
          // subio un tipo de archivo que no esta registrado en el array tipos_archivos
          this.$store.dispatch('show_snackbar', {
            text: 'Tipo de archivo no válido para el sistema',
            color: 'error'
          })
        }
        
        // limpia el input de archivos
        this.$refs.input.value = ''
      }
    },
    async calcular_diff (item) {
      if (item.diferencia) {
        item.stock_real = parseInt(item.stock_virtual) + parseInt(item.diferencia)
        if (parseInt(item.stock_real) < 0) {
          this.$store.dispatch('show_snackbar', {
            text: 'No puede haber stock real negativo',
            color: 'warning'
          })
          item.stock_real = null
          item.diferencia = null
          this.$refs[`dtDif${item.id}`].focus()
        } else {
          // 04-08-2023 por MMURILLO, controlo la diferencia que está ingresando. Si es negativa y ademas es articulo con serie, abro modal para que seleccione series
          if (parseInt(item.diferencia) < 0 && item.solicita_serie == 1){
            // marco el detalle como inconsistente de series
            this.pos = -1
            let unElem = this.articulos.filter(element => element.id == item.id)
            if (unElem.length > 0) this.pos = this.articulos.indexOf(unElem[0])
            if (this.pos == -1){
              this.$store.dispatch('show_snackbar', {
                text: 'No se pudo determinar la posición del artículo dentro del listado.',
                color: 'error'
              })
              return
            }
            this.articulos[this.pos].series_ok = 0
            this.articulos[this.pos].series = []
            // paso a buscar las series
            this.$store.state.loading = true
            let seriesPeticion = await this.$store.dispatch('inventario/get_series_inventario', {
              articulo_codigo: item.codigo,
              deposito_codigo: this.inventario.deposito
            })
            this.$store.state.loading = false
            if (seriesPeticion.exito == 0){
              this.$store.dispatch('show_snackbar', {
                text: seriesPeticion.msj,
                color: 'error'
              })
              return
            }
            // tengo series?
            if (seriesPeticion.series.length == 0){
              this.$store.dispatch('show_snackbar', {
                text: 'No se encontraron series disponibles para el artículo ingresado. Verificar.',
                color: 'warning'
              })
              return
            }
            // abro el modal para que seleccione las series
            let art = {
              articulo_codigo: item.codigo,
              articulo_nombre: item.nombre,
              articulo_cod_barras: '',
              series: seriesPeticion.series,
              series_seleccionadas: [],
              cantidad: Math.abs(item.diferencia),
              precio: 0,
              importe: 0,
              solicita_serie: 1,
              tipo: 0
            }
            this.abrirModalControlSeries(art)
            return
          }
          this.agregar_art(item.id)
        }
      }
    },
    quitar_art (item) {
      const index = this.articulos.indexOf(item)
      this.articulos.splice(index, 1)
    },
    set_responsable (obj) {
      this.inventario.responsable_codigo = obj.codigo
      this.inventario.responsable_nombre = obj.nombre
    },
    setModalCSeries(value){
      this.seriesParam.dialogSerie = value
    },
    setSeriesArticulo(){
      // tengo la posicion, paso a asignar las series
      this.articulos[this.pos].series = this.seriesParam.series_seleccionadas
      // controlo la consistencia de la fila
      if (Math.abs(this.articulos[this.pos].diferencia) == this.articulos[this.pos].series.length) this.articulos[this.pos].series_ok = 1
      // agrego otra
      this.agregar_art(this.articulos[this.pos].id)
      // reseteo la posicion
      this.pos = -1
      // limpiar
      this.limpiarSeriesParam()
    },
    abrirModalControlSeries(art){
      Object.assign(this.seriesParam, {})
      this.seriesParam.articulo_codigo = art.articulo_codigo
      this.seriesParam.articulo_nombre = art.articulo_nombre
      this.seriesParam.articulo_cod_barras = art.articulo_cod_barras
      this.seriesParam.series_disponibles = art.series
      this.seriesParam.series_seleccionadas = art.series_seleccionadas
      this.seriesParam.cantidad = art.cantidad
      this.seriesParam.precio = art.precio
      this.seriesParam.importe = art.importe
      this.seriesParam.solicita_serie = art.solicita_serie
      this.seriesParam.tipo = art.tipo
      this.seriesParam.dialogSerie = true
    },
    async ver_series(item){
      this.pos = -1
      let unElem = this.articulos.filter(element => element.id == item.id)
      if (unElem.length > 0) this.pos = this.articulos.indexOf(unElem[0])
      if (this.pos == -1){
        this.$store.dispatch('show_snackbar', {
          text: 'No se pudo determinar la posición del artículo dentro del listado.',
          color: 'error'
        })
        return
      }
      // paso a buscar las series
      this.$store.state.loading = true
      let seriesPeticion = await this.$store.dispatch('inventario/get_series_inventario', {
        articulo_codigo: item.codigo,
        deposito_codigo: this.inventario.deposito
      })
      this.$store.state.loading = false
      if (seriesPeticion.exito == 0){
        this.$store.dispatch('show_snackbar', {
          text: seriesPeticion.msj,
          color: 'error'
        })
        return
      }
      let seriesLocal = []
      for (let id in item.series){
        seriesLocal.push(item.series[id])
      }
      let art = {
        articulo_codigo: item.codigo,
        articulo_nombre: item.nombre,
        articulo_cod_barras: '',
        series: seriesPeticion.series,
        series_seleccionadas: seriesLocal,
        cantidad: Math.abs(item.diferencia),
        precio: 0,
        importe: 0,
        solicita_serie: 1,
        tipo: 0
      }
      this.abrirModalControlSeries(art)
    },
    limpiarSeriesParam(){
      this.seriesParam.articulo_codigo = 0
      this.seriesParam.articulo_nombre = ''
      this.seriesParam.articulo_cod_barras = ''
      this.seriesParam.series_disponibles = []
      this.seriesParam.series_seleccionadas = []
      this.seriesParam.cantidad = 0
      this.seriesParam.precio = 0
      this.seriesParam.importe = 0
      this.seriesParam.solicita_serie = 0
      this.seriesParam.tipo = 0
    }
  }
}
</script>