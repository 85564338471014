<template>
  <v-dialog
    v-model="dialog"
    max-width="700"
    :fullscreen="fullscreen"
    scrollable
    persistent
  >
    <v-card :disabled="load">
      <v-card-title>
        {{ p_estado == 5 ? p_permiso == 1 ? 'Auditar' : 'Detalle auditado' : 'Preauditar' }}
        <v-spacer></v-spacer>
        <v-btn
          icon
          class="mr-2"
          :title="fullscreen ? 'Minimizar' : 'Maximizar'"
          @click="fullscreen = !fullscreen"
        >
          <v-icon>fas fa-window-{{ fullscreen ? 'restore' : 'maximize' }}</v-icon>
        </v-btn>
        <v-btn
          title="Cerrar"
          icon
          @click="dialog = false"
        >
          <v-icon>fas fa-times</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text class="">
        <div class="body-1 mb-4">
          Artículo {{ p_articulo.articulo }} - {{ p_articulo.nombre }}
        </div>
        <v-textarea
          v-model="p_articulo.observacion"
          label="Observación"
          rows="2"
          auto-grow
          outlined
          readonly
          filled
          dense
        ></v-textarea>
        <v-textarea
          v-model="observacion_preauditada"
          label="Observación preauditado"
          rows="2"
          :readonly="p_estado == 5"
          :filled="p_estado == 5"
          auto-grow
          outlined
          dense
        ></v-textarea>
        <v-textarea
          v-if="p_estado == 5"
          v-model="observacion_auditada"
          label="Observación auditado"
          rows="2"
          :readonly="p_permiso != 1"
          :filled="p_permiso != 1"
          auto-grow
          outlined
          dense
        ></v-textarea>
        <v-card outlined>
          <v-card-title class="body-1 py-3">
            Recuperos
            <v-spacer></v-spacer>
            <v-btn
              v-if="permiso"
              color="success"
              small
              @click="agregar_recupero()"
            >
              <v-icon small left>fas fa-plus</v-icon>
              Agregar
            </v-btn>
          </v-card-title>
          <v-card-text class="py-0 pb-2">
            <v-data-table
              class="cebra"
              :loading="load"
              :items="recuperos.filter(r => r.estado != 3)"
              :headers="headers"
              :items-per-page="-1"
              hide-default-footer
              dense
            >
              <template v-slot:[`item.tipo`]="{ item }">
                <v-autocomplete
                  v-model.trim="item.tipo"
                  class="pb-2"
                  item-value="codigo"
                  item-text="nombre"
                  :ref="`recTipo${item.numero}`"
                  :items="tipos_recupero"
                  :disabled="!permiso"
                  hide-details
                  dense
                ></v-autocomplete>
              </template>
              <template v-slot:[`item.monto`]="{ item }">
                <v-text-field
                  v-model.trim="item.monto"
                  type="number"
                  prefix="$"
                  class="pb-2"
                  :ref="`recMonto${item.numero}`"
                  :disabled="!permiso"
                  hide-details
                  dense
                  @blur="calcular_total()"
                ></v-text-field>
              </template>
              <template v-slot:[`item.observacion`]="{ item }">
                <v-text-field
                  v-model.trim="item.observacion"
                  class="pb-2"
                  :title="item.observacion"
                  :disabled="!permiso"
                  hide-details
                  dense
                  @blur="agregar_recupero(item)"
                ></v-text-field>
              </template>
              <template v-slot:[`item.actions`]="{ item }">
                <v-icon
                  class="mr-2"
                  color="error"
                  title="Eliminar"
                  small
                  @click="quitar_recupero(item)"
                >
                  fas fa-times-circle
                </v-icon>
              </template>
              <template v-slot:no-data>
                <v-alert
                  class="mx-auto mt-4"
                  max-width="400"
                  type="warning"
                  border="left"
                  dense
                  text
                >
                  No se encontraron recuperos
                </v-alert>
              </template>
            </v-data-table>
            <div class="body-1 mt-2">
              Monto total: {{ format_money(total) }}
            </div>
            <v-divider></v-divider>
              <v-row class="d-flex align-center justify-end body-1 mt-0 pb-1" :no-gutters="$vuetify.breakpoint.xs">
                <v-col cols="6">
                  Monto a recuperar: {{ format_money(recuperar) }}
                </v-col>
                <v-col cols="6" sm="3" class="d-flex justify-start">
                  <v-text-field
                    v-model="precio"
                    type="number"
                    prefix="$"
                    label="Precio unitario"
                    hide-details
                    outlined
                    dense
                    @blur="calcular_recupero()"
                  ></v-text-field>
                </v-col>
                <v-divider v-if="$vuetify.breakpoint.smAndUp" vertical></v-divider>
                <v-col cols="6" sm="3" class="d-flex justify-end">
                  <v-text-field
                    v-model="diferencia"
                    type="number"
                    label="Diferencia"
                    hide-details
                    outlined
                    dense
                    @blur="calcular_recupero()"
                  ></v-text-field>
                </v-col>
              </v-row>
          </v-card-text>
        </v-card>
      </v-card-text>
      <v-card-actions class="d-flex justify-end pb-4">
        <v-btn
          class="mr-3"
          :color="permiso ? 'error' : 'secondary'"
          @click="dialog = false"
        >
          {{ permiso ? 'Cancelar' : 'Vovler' }}
        </v-btn>
        <BtnConfirmar
          v-if="permiso"
          @action="guardar()"
        />
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
/**
 *  Modal para preauditar/auditar y agregar recuperos
 * 
 *  Nota: cada item del array de recuperos tiene los siguientes estados:
 * 
 *        1. recupero original
 *        2. recupero nuevo
 *        3. recupero eliminado
 *        4. recupero editado
 * 
 *  Otra nota: si se actualiza el precio unitario, el componente emite la accion
 *             @actualizar al padre con el id del detalle y el precio nuevo
 * 
 *      Cuando se gaurda el recupero emite la accion @color (con el id del detalle, diferencia)
 *      al padre para cambiar el color del icono
 * 
 */
import { mapState } from 'vuex'
import { format_money } from '../../util/utils'
import BtnConfirmar from '../util/BtnConfirmar'

export default {
  data () {
    return {
      format_money: format_money,
      load: false,
      permiso: true,
      fullscreen: false,
      diferencia: 0,
      cant_art: 0,
      total: 0,
      precio: 0,
      recuperar: 0,
      observacion_preauditada: '',
      observacion_auditada: '',
      recuperos: [],
      recuperos_copy: [],
      headers: [
        { text: 'Tipo', value: 'tipo', width: '230' },
        { text: 'Monto', value: 'monto', align: 'end', width: '110' },
        { text: 'Observación', value: 'observacion', sortable: false },
      ]
    }
  },
  async created () {
    this.$store.state.loading = true
    await this.$store.dispatch('controlStock/get_tipos_recupero')
    this.$store.state.loading = false
  },
  props: {
    value: Boolean,
    p_permiso: Number,
    p_articulo: Object,
    p_estado: Number
  },
  computed: {
    ...mapState('controlStock', ['tipos_recupero']),
    dialog: {
      get () {
        return this.value
      },
      set (value) {
        this.$emit('input', value)
      }
    }
  },
  components: {
    BtnConfirmar
  },
  watch: {
    async dialog (val) {
      if (val) {
        if (this.p_permiso == 0 && this.p_estado == 5) {
          this.permiso = false
        }
        // si tiene permisos muestra la columna de acciones
        if (this.permiso) {
          this.headers.push({ text: 'Acciones', value: 'actions', sortable: false, filterable: false, align: 'center' })
        }
        this.load = true
        await this.$store.dispatch('controlStock/get_recuperos', this.p_articulo.id)
          .then((res) => {
            this.cant_art = res.size
            this.recuperos = res.data.recuperos
            this.observacion_preauditada = res.data.observacion.preauditado
            this.observacion_auditada = res.data.observacion.auditado
            // guarda una copia de los recuperos
            this.recuperos_copy = JSON.parse(JSON.stringify(this.recuperos))
            // calcula el monto total
            this.calcular_total()
          })
          .catch(error => {
            this.$store.dispatch('show_snackbar', {
              text: error.message,
              color: 'error'
            })
          })
        this.load = false
        // calcula el total a recuperar en base al precio y la diferencia
        this.precio = await parseFloat(JSON.parse(JSON.stringify(this.p_articulo.precio)))
        // si el articulo vino con diferencia utilza esa, sino la calcula
        if (this.p_articulo.diferencia !== null) {
          this.diferencia = parseInt(JSON.stringify(this.p_articulo.diferencia))
        } else {
          this.diferencia = parseInt(this.p_articulo.stock_real) - parseInt(this.p_articulo.stock_virtual)
        }
        this.calcular_recupero()
      } else {
        this.fullscreen = false
        await new Promise(resolve => setTimeout(resolve, 200))
        this.cant_art = 0
        this.total = 0
        this.precio = 0
        this.observacion_preauditada = ''
        this.observacion_auditada = ''
        this.recuperos = []
        // elimina la columna de acciones
        if (this.permiso) {
          this.headers.splice(this.headers.length - 1, 1)
        }
        this.permiso = true
      }
    }
  },
  methods: {
    calcular_recupero () {
      this.recuperar = parseInt(this.diferencia) * parseFloat(this.precio)
      if (this.recuperar != 0) {
        this.recuperar *= -1
      } else {
        this.recuperar = 0
      }
    },
    async guardar () {
      // verifica que exista una diferencia
      if (this.diferencia === '') {
        this.$store.dispatch('show_snackbar', {
          text: 'Debe ingresar una diferencia',
          color: 'warning'
        })
        return
      }

      // verifica la diferencia no genere un stock real negativo
      if (parseInt(this.p_articulo.stock_virtual) + parseInt(this.diferencia) < 0) {
        this.$store.dispatch('show_snackbar', {
          text: 'La diferencia ingresada genera un stock real negativo',
          color: 'warning'
        })
        return
      }

      // elimina los recuperos con tipo y monto null
      for (const recupero of this.recuperos) {
        if (!recupero.tipo && !recupero.monto) {
          this.recuperos.splice(this.recuperos.indexOf(recupero), 1)
        }
      }

      // valdia que todos los recuperos tengan tipo y monto
      let incompleto = false
      for (const recupero of this.recuperos) {
        if (!recupero.tipo) {
          this.$store.dispatch('show_snackbar', {
            text: 'Ingrese el tipo de recupero faltante',
            color: 'warning'
          })
          incompleto = true
          return
        }
        if (!recupero.monto) {
          this.$store.dispatch('show_snackbar', {
            text: 'Ingrese el monto faltante',
            color: 'warning'
          })
          incompleto = true
          return
        }
      }
      if (incompleto) return

      // obtiene los recuperos que quedaron con estado 1
      const recuperos = this.recuperos.filter(r => r.estado == 1)
      for (const recupero of recuperos) {
        // obtiene el recupero del copy
        const copy = this.recuperos_copy.find(r => r.id == recupero.id)
        // si la copia es distitna del recupero es pq se modifico algo
        if (JSON.stringify(copy) != JSON.stringify(recupero)) {
          recupero.estado = 4
        }
      }

      // verifica si se modifico el precio unitario
      let precio = undefined
      if (JSON.stringify(parseFloat(this.precio)) != JSON.stringify(this.p_articulo.precio)) {
        precio = this.precio
      }

      // verifica si se modifico la diferencia
      let diferencia = null
      let diferencia_original = null
      if (this.p_articulo.diferencia !== null) {
        diferencia_original = parseInt(JSON.stringify(this.p_articulo.diferencia))
      } else {
        diferencia_original = parseInt(this.p_articulo.stock_real) - parseInt(this.p_articulo.stock_virtual)
      }
      if (JSON.stringify(parseInt(this.diferencia)) != JSON.stringify(diferencia_original)) {
        diferencia = this.diferencia
      }

      this.$store.state.loading = true
      await this.$store.dispatch('controlStock/actualizar_recuperos', {
        id: this.p_articulo.id,
        precio: precio,
        diferencia: diferencia,
        obs_preaudit: this.observacion_preauditada,
        obs_audit: this.observacion_auditada,
        recuperos: this.recuperos
      })
        .then((res) => {
          this.dialog = false
          this.$store.dispatch('show_snackbar', {
            text: res.message,
            color: 'success'
          })
          // si precio <> undefined => actualizo el precio en el padre
          if (res.precio !== undefined) {
            this.$emit('actualizar', this.p_articulo.id, res.precio)
          }
          // si guarda el recupero emite una accion al padre para cambiar de color el icono del boton del recupero y actualizar la diferencia
          this.$emit('color', this.p_articulo.id, diferencia)
        })
        .catch(error => {
          this.$store.dispatch('show_snackbar', {
            text: error.message,
            color: 'error'
          })
        })
      this.$store.state.loading = false
    },
    quitar_recupero (item) {
      if (item.id) {
        item.estado = 3
      } else {
        this.recuperos.splice(this.recuperos.indexOf(item), 1)
      }
      this.calcular_total()
    },
    async agregar_recupero (item) {
      // obtiene el ultimo recupero con estado distinto de 3
      const ultimo_recupero = this.recuperos.filter(r => r.estado != 3)[this.recuperos.length - 1]
      if (item) {
        // si el ultimo recupero tiene tipo y monto agrego uno nuevo
        if (item.tipo && item.monto) {
          // solo agrega un recupero si el tab se hizo sobre la ultima row
          if (ultimo_recupero && ultimo_recupero.numero == item.numero) {
            await this.inserar_row()
          }
        }
        // si le falta el tipo hago foco en el tipo
        else if (!item.tipo) {
          this.$refs[`recTipo${item.numero}`].focus()
        }
        // si le falta el monyo hago foco en el monto
        else if (!item.monto) {
          this.$refs[`recMonto${item.numero}`].focus()
        }
      } else {
        // si no existe el item es pq hizo desde el boton y debe validar que el ultimo recupero agregado cumpla las condiciones anteriores
        if (ultimo_recupero) {
          await this.agregar_recupero(ultimo_recupero)
        }
        // si no existe un recupero aterior lo agrega
        else {
          await this.inserar_row()
        }
      }
    },
    async inserar_row () {
      this.cant_art ++
      await this.recuperos.push({
        numero: this.cant_art,
        tipo: null,
        monto: null,
        observacion: null,
        estado: 2
      })
      this.$refs[`recTipo${this.cant_art}`].focus()
    },
    calcular_total () {
      this.total = 0
      this.recuperos.forEach(recupero => {
        if (recupero.estado != 3 && recupero.monto) {
          this.total += parseFloat(recupero.monto)
        }
      })
    }
  }
}
</script>