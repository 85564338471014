<template>
  <div>
    <v-row class="d-flex justify-center mt-0 mx-md-2 mx-n1 mb-0">
      <!-- Titulo -->
      <v-col cols="12" class="d-flex justify-end align-center pt-0 px-1">
        <div v-if="nuevaRecep == 1">Nueva Recepción</div>
        <div v-else>Ver Recepción Nro {{ $route.params.id }}</div>
        <v-spacer></v-spacer>
        <v-expand-x-transition>
          <v-switch
            v-model="preRecepcion"
            :label="labelPreRecep"
            class="d-flex align-center py-0 my-0"
            hide-details
            dense
            :disabled="articulos.length > 0 || load == true || nuevaRecep == 0"
          ></v-switch>
        </v-expand-x-transition>
      </v-col>
      <v-col cols="12">
        <v-card
          flat
          width="auto"
        >
          <v-card-text>
            <!-- Depósito, Proveedor y Orden de Compra -->
            <v-row :no-gutters="$vuetify.breakpoint.xs">
              <v-col cols="12" sm="4" md="4" class="py-1">
                <v-autocomplete
                  v-model="selectedDeposito"
                  item-text="deposito_nombre"
                  :items="depositos"
                  hide-details
                  outlined
                  dense
                  return-object
                  label="Depósito"
                  @change="getOrdenesCompra()"
                  :disabled="articulos.length > 0 || nuevaRecep == 0"
                ></v-autocomplete>
              </v-col>
              <v-col cols="12" sm="4" md="4" class="py-1">
                <v-autocomplete
                  v-model="selectedProveedor"
                  item-text="proveedor_nombre"
                  :items="proveedores"
                  hide-details
                  outlined
                  dense
                  return-object
                  label="Proveedor"
                  @change="getOrdenesCompra()"
                  :disabled="articulos.length > 0 || nuevaRecep == 0"
                ></v-autocomplete>
              </v-col>
              <v-col cols="12" sm="4" md="4" class="py-1">
                <v-autocomplete
                  v-model="selectedOrdenCompra"
                  item-text="orden_descripcion"
                  :items="ordenesCompra"
                  hide-details
                  outlined
                  dense
                  return-object
                  label="Orden de Compra"
                  :disabled="ordenesCompra.length == 0 || nuevaRecep == 0"
                  @change="getOrdenCompraDetalle()"
                ></v-autocomplete>
              </v-col>
            </v-row>
            <!-- Fecha, Emision, Número y Observaciones -->
            <v-row :no-gutters="$vuetify.breakpoint.xs" class="pt-4">
              <v-col cols="12" sm="2" md="2" class="py-1">
                <FechaPicker 
                  v-model="fecha"
                  label="Fecha"
                  :disabled="true"
                />
              </v-col>
              <v-col cols="12" sm="2" md="2" class="py-1">
                <v-text-field
                  v-model="emision"
                  outlined
                  dense
                  type="number"
                  hide-details
                  label="Emision"
                  :readonly="nuevaRecep == 0"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="2" md="2" class="py-1">
                <v-text-field
                  v-model="numero"
                  outlined
                  dense
                  type="number"
                  hide-details
                  label="Número"
                  :readonly="nuevaRecep == 0"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="6" class="py-1">
                <v-text-field
                  v-model="observacion"
                  outlined
                  dense
                  type="text"
                  hide-details
                  label="Observaciones"
                  :readonly="nuevaRecep == 0"
                ></v-text-field>
              </v-col>
            </v-row>
            <!-- Artículos -->
            <v-row :no-gutters="$vuetify.breakpoint.xs" class="pt-4">
              <v-col cols="12">
                <v-card
                  elevation="2"
                  outlined
                >
                  <!-- Titulo de la Sección -->
                  <v-card-title>
                    Artículos
                    <v-btn icon @click="getArticulos()" v-if="nuevaRecep == 1"><v-icon color="success" small>fas fa-search</v-icon></v-btn>
                    <v-btn small color="error" @click="limpiarDetalles()" v-if="articulos.length > 0 && nuevaRecep == 1"><v-icon small class="ma-2">fas fa-ban</v-icon> Limpiar Detalles</v-btn>
                    <v-spacer></v-spacer>
                    <v-switch
                      v-model="metodoIngreso"
                      :label="metodoIngreso == false ? 'Por Código de Artículos' : 'Por Código de Barras'"
                      class="d-flex align-center py-0 my-0"
                      hide-details
                      dense
                      :disabled="articulos.length > 0"
                    ></v-switch>
                    <v-btn
                      color="cyan"
                      class="ma-2"
                      small
                      @click="iniciarCargaAsistida()"
                      :disabled="articulos.length > 0 || load == true"
                    >
                      <v-icon small left>fas fa-barcode</v-icon>
                      Carga Asistida
                    </v-btn>
                  </v-card-title>
                  <!-- Listado -->
                  <v-card-text>
                    <!-- Agregar Artículo -->
                    <v-row :no-gutters="$vuetify.breakpoint.xs" v-if="nuevaRecep == 1">
                      <v-col cols="12" sm="2" md="2" class="py-1">
                        <v-text-field
                          v-model="cantidad"
                          :id="cantRef"
                          :ref="cantRef"
                          outlined
                          dense
                          type="number"
                          label="Cantidad"
                          @blur="addArticulo({}, 1)"
                          @keypress.native.enter="$refs[cantRef].blur()"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="2" md="2" class="py-1">
                        <v-text-field
                          v-model="cantidadRemito"
                          :id="cantRemRef"
                          :ref="cantRemRef"
                          outlined
                          dense
                          type="number"
                          label="Cant. Remito"
                          @blur="addArticulo({}, 1)"
                          @keypress.native.enter="$refs[cantRemRef].blur()"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="2" md="2" class="py-1">
                        <v-text-field
                          v-model="codArticulo"
                          :id="artRef"
                          :ref="artRef"
                          outlined
                          dense
                          type="number"
                          label="Artículo Código"
                          @blur="addArticulo({}, 1)"
                          @keypress.native.enter="$refs[artRef].blur()"
                          :readonly="metodoIngreso == true"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="2" md="2" class="py-1">
                        <v-text-field
                          v-model="codBarras"
                          :id="barrasRef"
                          :ref="barrasRef"
                          outlined
                          dense
                          type="text"
                          label="Código Barras"
                          @blur="addArticulo({}, 1)"
                          @keypress.native.enter="$refs[barrasRef].blur()"
                          :readonly="metodoIngreso == false"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="2" md="2" class="py-1">
                        <v-text-field
                          v-model="nombreArticulo"
                          outlined
                          dense
                          hide-details
                          readonly
                          label="Nombre"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="2" md="2" class="py-1">
                        <v-btn 
                          color="primary" 
                          title="Agregar Artículo"
                          @click="addArticulo({}, 1)"
                        >
                          Agregar
                        </v-btn>
                      </v-col>
                    </v-row>
                    <!-- Listado de Artículos -->
                    <v-data-table
                      class="cebra elevation-2 mt-2"
                      :headers="headers"
                      :items="articulos"
                      :loading="load"
                      dense
                      :search="search"
                    >
                      <!-- Buscador en el datatable -->
                      <template v-slot:top>
                        <v-row class="d-flex justify-end pa-2" no-gutters>
                          <v-col cols="6" sm="3">
                            <SearchDataTable
                              v-model="search"
                            />
                          </v-col>
                        </v-row>
                      </template>
                      <!-- Msj que se mostrara si no existen resultados -->
                      <template v-slot:no-data>
                        <v-alert
                          class="mx-auto mt-4"
                          max-width="400"
                          type="warning"
                          border="left"
                          dense
                          text
                        >
                          No se agregaron artículos.
                        </v-alert>
                      </template>
                      <!-- Acciones -->
                      <template v-slot:[`item.acciones`]="{ item }">
                        <v-tooltip bottom>
                          <template v-slot:activator="{ on }">
                            <v-btn small icon color="error" @click="quitarArticulo(item)" v-on="on" :disabled="nuevaRecep == 0">
                              <v-icon small>far fa-times-circle</v-icon>
                            </v-btn>
                          </template>
                          <span>Quitar</span>
                        </v-tooltip>
                        <v-tooltip bottom v-if="item.solicita_serie == 1">
                          <template v-slot:activator="{ on }">
                            <v-btn small icon color="success" @click="verSeries(item)" v-on="on">
                              <v-icon small>fas fa-list</v-icon>
                            </v-btn>
                          </template>
                          <span>Ver Series</span>
                        </v-tooltip>
                        <v-tooltip bottom v-if="item.solicita_serie == 1 && (item.series.length == 0 || item.series.length != item.cantidad)">
                          <template v-slot:activator="{ on }">
                            <v-btn small icon color="warning" @click="verSeries(item)" v-on="on">
                              <v-icon small>fas fa-exclamation-circle</v-icon>
                            </v-btn>
                          </template>
                          <span>Verificar Series</span>
                        </v-tooltip>
                      </template>
                      <!-- Cantidad y Cantidad en Remito -->
                      <template v-slot:[`item.cantidad`]="{ item }">
                        <v-row class="d-flex justify-center" no-gutters>
                          <v-text-field v-model="item.cantidad" type="number" outlined dense style="width: 50px" hide-details @blur="verificarSeries(item)" :readonly="nuevaRecep == 0"></v-text-field>
                        </v-row>
                      </template>
                      <template v-slot:[`item.cantidad_remito`]="{ item }">
                        <v-row class="d-flex justify-center" no-gutters>
                          <v-text-field v-model="item.cantidad_remito" type="number" outlined dense style="width: 50px" hide-details :readonly="nuevaRecep == 0"></v-text-field>
                        </v-row>
                      </template>
                    </v-data-table>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions class="d-flex justify-end mx-2">
            <v-btn color="warning" @click="volverIndex()" class="ma-2">Volver</v-btn>
            <v-btn color="info" @click="subirImagenes()" class="ma-2" v-if="nuevaRecep == 0"><v-icon class="ma-2">fas fa-upload</v-icon> Subir Imagenes</v-btn>
            <BtnConfirmar
              @action="sendRecepcion()"
              color="success"
              :texto="`¿Esta seguro de registrar la nueva Recepción?`"
              nombre="Guardar"
              v-if="nuevaRecep == 1"
            />
            <BtnConfirmar
              @action="cancelRecepcion()"
              color="error"
              :texto="`¿Esta seguro de anular la Recepción ${recepId}?`"
              icono="fas fa-ban"
              nombre="Anular"
              v-if="nuevaRecep == 0"
            />
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
    <!-- Modal Ingresar Series para Recepciones -->
    <ModalIngresarSeries 
      :datos="dialogIngresarSeries"
      @setearModalIngSeries="setModalIngSeries"
      @setSeriesIngresadas="setSeries"
    />
    <!-- Modal Seleccionar un Artículo -->
    <ModalSelectArticulo 
      :datos="dialogSelectArticulo"
      @setearModalSelectArt="setModalSelectArt"
      @setearArticulo="setArticulo"
    />
    <!-- Modal Confirmar Acción -->
    <ModalConfirmar 
      :activoDialog="dialogConfAsociar"
      :texto="`¿Está seguro de Asociar el Código ${selectedArt.articulo_codigo} al Código de Barras '${codBarras}' para el Proveedor ${selectedProveedor.proveedor_nombre}?`"
      @action="asociarArticulo()"
      @setearModalConfAccion="setModalAsociar"
    />
    <!-- Modal Carga Asistida -->
    <ModalCargaAsistidaRecep 
      :datos="dialogCargaAsistida"
      @setearModalCarAsistRecep="setModalCarAsistRecep"
      @setearTipIngCarAsistida="setTipIngCarAsistida"
      @setArtsAsistidos="setArtsCargaAsistida"
      @setearOrdCompra="setOrdCompra"
      @setPreRecepcion="setDetsPreRecepcion"
    />
    <!-- Modal Subir Archivos -->
    <ModalUploadImgGral 
      :datos="dialogSubirImgs"
      @setearModalUpImg="setModalUpImg"
    />
  </div>
</template>

<script>
import FechaPicker from '../../components/util/FechaPicker.vue'
import SearchDataTable from '../../components/util/SearchDataTable.vue'
import BtnConfirmar from '../../components/util/BtnConfirmar.vue'
import ModalIngresarSeries from '../../components/recepciones/ModalIngresarSeries.vue'
import ModalSelectArticulo from '../../components/util/ModalSelectArticulo.vue'
import ModalConfirmar from '../../components/util/ModalConfirmar.vue'
import ModalCargaAsistidaRecep from '../../components/recepciones/ModalCargaAsistidaRecep.vue'
import ModalUploadImgGral from '../../components/generales/ModalUploadImgGral.vue'
import moment from 'moment'
import { roundNumber } from '../../util/utils'
import router from '../../router'
export default {
  data() {
    return {
      nuevaRecep: 0,
      recepId: 0,
      preRecepcion: false,
      labelPreRecep: 'Recepcionar',
      selectedDeposito: {},
      depositos: [],
      selectedProveedor: {},
      proveedores: [],
      selectedOrdenCompra: {},
      ordenesCompra: [],
      fecha: moment(new Date()).format("DD/MM/YYYY"),
      emision: '',
      numero: '',
      observacion: '',
      cantidad: '',
      cantRef: 'cantRef',
      cantidadRemito: '',
      cantRemRef: 'cantRemRef',
      codArticulo: '',
      artRef: 'artRef',
      codBarras: '',
      barrasRef: 'barrasRef',
      nombreArticulo: '',
      headers: [
        { text: 'Cod. Interno', align: 'center', value: 'articulo_codigo' },
        { text: 'Cod. Barras', align: 'left', value: 'codigo_barras' },
        { text: 'Cod. Ean', align: 'left', value: 'codigo_ean' },
        { text: 'Nombre', align: 'left', value: 'articulo_nombre' },
        { text: 'Cantidad', align: 'center', value: 'cantidad' },
        { text: 'Cantidad Remito', align: 'center', value: 'cantidad_remito' },
        { text: 'Acciones', align: 'center', value: 'acciones' }
      ],
      articulos: [],
      load: false,
      search: '',
      metodoIngreso: false,
      dialogIngresarSeries: {
        activo: false,
        articulo_codigo: '',
        articulo_nombre: '',
        series: [], // series ya ingresadas
        cantidad: 0, // cantidad a ingresar
        ver: 0
      },
      posItem: -1,
      dialogSelectArticulo: {
        activo: false,
        articulos: [],
        bloquear_cant: false,
        bloquear_precio: true,
        cerrar_seleccion: true,
        titulo_modal: 'Buscar Artículos',
        color: ''
      },
      asociarCodBarras: 0,
      dialogConfAsociar: false,
      selectedArt: {},
      dialogCargaAsistida: {
        activo: false,
        tipo_ingreso: false,  // si es por codigo de barras o por codigo de BB
        articulos: [],  // articulos asociados al proveedor
        proveedor_codigo: 0,
        proveedor_nombre: '',
        pre_recepcion: 0,  // indica si estan prerecepcionando o no, 0 es no, 1 es si
        orden_compra: {},  // orden de compra seleccionada si es que la hubiere
        ordenes_compra: [],  // contiene todas las ordenes de compra asociadas al deposito y al proveedor
        deposito_codigo: 0
      },
      dialogSubirImgs: {
        activo: false,
        icono: 'fas fa-eye',
        titulo_modal: 'Imagenes para La Recepción ',
        tipos: ['.jpg', '.png', '.webp', '.pdf'], // formatos admitidos
        multiple: true,
        max_size: 1, // tamaño del archivo en megas
        ruta: '', // ruta del archivo
        raiz: 0, // indica si vamos a trabajar con la raiz del 10.40 o la de jarvis 2
        tabla: 5, // indica la tabla donde va el log
        id: 0, // generalmente indica un numero identificador para la tabla del log, por ejemplo para facturas de compra seria el id de la factura de compra en la tabla LOG_FACTURAS_COMPRAS en el campo FACTURA_COMPRA_ID
        permiso_borrar: 0 // permiso para borrar archivos
      },
      actualRef: ''
    }
  },
  components: {
    FechaPicker,
    SearchDataTable,
    BtnConfirmar,
    ModalIngresarSeries,
    ModalSelectArticulo,
    ModalConfirmar,
    ModalCargaAsistidaRecep,
    ModalUploadImgGral
  },
  created () {
    document.addEventListener('focusin', this.focusChanged)
    if (this.$route.hasOwnProperty('params') && Object.keys(this.$route.params).length > 0){
      this.nuevaRecep = 0
      this.recepId = this.$route.params.id
    }else this.nuevaRecep = 1
    this.initForm()
  },
  beforeDestroy() {
    document.removeEventListener('focusin', this.focusChanged)
  },
  methods: {
    async initForm(){
      this.$store.state.loading = true
      let initPeticion = await this.$store.dispatch('recepciones/initUnaRecepForm', {nuevo: this.nuevaRecep, recepcion_id: this.recepId})
      this.$store.state.loading = false
      if (initPeticion.resultado == 0){
        this.$store.dispatch('show_snackbar', {
          text: initPeticion.msj,
          color: 'error',
        })
        return
      }
      // depositos
      this.depositos = initPeticion.depositos
      // proveedores
      this.proveedores = initPeticion.proveedores
      // permiso para borrar imagenes
      this.dialogSubirImgs.permiso_borrar = initPeticion.permiso
      if (this.nuevaRecep == 0){
        // paso a completar los datos de la recepción
        this.completarDatos(initPeticion.recepcion)
      }
    },
    completarDatos(recepcion){
      // deposito
      let unDep = this.depositos.filter(element => element.deposito_codigo == recepcion.enc.bodega_codigo)
      if (unDep.length > 0) this.selectedDeposito = unDep[0]
      // proveedor
      let unProv = this.proveedores.filter(element => element.proveedor_codigo == recepcion.enc.proveedor_codigo)
      if (unProv.length > 0) this.selectedProveedor = unProv[0]
      // orden de compra
      if (Object.keys(recepcion.orden_compra).length > 0){
        let unEstado = {
          orden_id: recepcion.orden_compra.orden_compra_id,
          orden_descripcion: recepcion.orden_compra.estado
        }
        this.ordenesCompra.push(unEstado)
        this.selectedOrdenCompra = unEstado
      }
      // fecha
      this.fecha = moment(recepcion.enc.fecha).format("DD/MM/YYYY")
      // emision
      this.emision = recepcion.enc.emision
      // numero
      this.numero = recepcion.enc.numero
      // observaciones
      this.observacion = recepcion.enc.observaciones == null ? '' : recepcion.enc.observaciones
      // 24-08-2023 por MMURILLO, pre recepcion?
      this.preRecepcion = recepcion.enc.pre_recepcion == 1 ? true : false
      // artículos
      for (let id in recepcion.detalles){
        let unArt = this.armarArticulo(recepcion.detalles[id])
        this.articulos.push(unArt)
      }
    },
    focusChanged (event) {
      if (this.metodoIngreso == false && event.target.id.toString() == 'barrasRef'){
        this.$refs.artRef.focus()
        this.actualRef = event.target.id.toString()
      }
      if (this.metodoIngreso == true && event.target.id.toString() == 'artRef'){
        this.$refs.barrasRef.focus()
        this.actualRef = event.target.id.toString()
      }
    },
    async getOrdenesCompra(){
      if (Object.keys(this.selectedDeposito).length > 0 && Object.keys(this.selectedProveedor).length > 0){
        this.selectedOrdenCompra = {}
        this.ordenesCompra = []
        this.dialogCargaAsistida.ordenes_compra = []
        this.$store.state.loading = true
        let ordenesPeticion = await this.$store.dispatch('recepciones/getOrdenesCompra', {
          deposito_codigo: this.selectedDeposito.deposito_codigo,
          proveedor_codigo: this.selectedProveedor.proveedor_codigo
        })
        this.$store.state.loading = false
        if (ordenesPeticion.resultado == 1){
          this.ordenesCompra = ordenesPeticion.ordenes
          this.dialogCargaAsistida.ordenes_compra = ordenesPeticion.ordenes
        }else{
          this.$store.dispatch('show_snackbar', {
            text: ordenesPeticion.msj,
            color: 'error',
          })
        }
      }
    },
    async getOrdenCompraDetalle(){
      if (Object.keys(this.selectedOrdenCompra).length > 0 && Object.keys(this.selectedDeposito).length > 0 && this.nuevaRecep == 1){
        // 09-08-2023 por MMURILLO, controlo a ver si estan pre recepcionando
        this.articulos = []
        if (this.preRecepcion == false){
          this.$store.state.loading = true
          let detsPeticion = await this.$store.dispatch('recepciones/getDetallesOrdenCompra', {
            deposito_codigo: this.selectedDeposito.deposito_codigo,
            orden_id: this.selectedOrdenCompra.orden_id
          })
          this.$store.state.loading = false
          if (detsPeticion.resultado == 1){
            this.articulos = detsPeticion.detalles
            for (let id in this.articulos){
              this.articulos[id].precio = roundNumber(this.articulos[id].precio, 2)
            }
          }else{
            this.$store.dispatch('show_snackbar', {
              text: detsPeticion.msj,
              color: 'error',
            })
          }
        }else{
          // me quedo con la orden de compra tal cual la seleccionaron
          this.dialogCargaAsistida.orden_compra = this.selectedOrdenCompra
        }
      }
    },
    armarArticulo(art){
      // codigo de barras
      let codBarrasArt = ''
      if (art.articulo_cod_barras != null || art.articulo_cod_barras != undefined && art.articulo_cod_barras.toString().length > 0) codBarrasArt = art.articulo_cod_barras
      // precio
      let precioArt = 0
      if (art.precio != null && art.precio != undefined && art.precio.toString().length > 0) precioArt = art.precio
      // series
      let solicitaSerie = 0
      if (art.detallado != null && art.detallado != undefined && art.detallado != 0 && art.detallado.toString().length > 0) solicitaSerie = 1
      // cantidad en remito
      let cantidadRemito = 0
      if (this.nuevaRecep == 1){
        if (this.cantidadRemito == null || this.cantidadRemito == undefined || this.cantidadRemito.toString().length == 0) cantidadRemito = art.cantidad
        if (this.cantidadRemito != null && this.cantidadRemito != undefined && this.cantidadRemito.toString().length > 0) cantidadRemito = this.cantidadRemito
      }else{
        cantidadRemito = art.cantidad_remito
      }
      // id de la recepcion detalle
      let recep_det_id = 0
      if (art.hasOwnProperty('recepciones_detalle_id') && art.recepciones_detalle_id != null && art.recepciones_detalle_id != undefined && art.recepciones_detalle_id.toString().length > 0) recep_det_id = parseInt(art.recepciones_detalle_id)
      // 24-08-2023 por MMURILLO, agregamos el ean
      let ean = ''
      if (art.hasOwnProperty('codigo_ean') && art.codigo_ean != null && art.codigo_ean != undefined && art.codigo_ean.toString().length > 0) ean = art.codigo_ean
      let arti = {
        articulo_codigo: art.articulo_codigo,
        articulo_nombre: art.articulo_nombre,
        cantidad: art.cantidad,
        cantidad_remito: cantidadRemito,
        codigo_barras: codBarrasArt,
        diferencia: 0,
        distribucion: 0,
        precio: precioArt,
        series: art.series,
        solicita_serie: solicitaSerie,
        recepciones_detalle_id: recep_det_id,
        codigo_ean: ean
      }
      return arti
    },
    limpiarSeccionArts(){
      this.cantidad = ''
      this.cantidadRemito = ''
      this.codArticulo = ''
      this.codBarras = ''
    },
    async addArticulo(art, opcion){
      let articulo = {}
      if (Object.keys(this.selectedProveedor).length == 0){
        this.$store.dispatch('show_snackbar', {
          text: 'Debe seleccionar un proveedor para poder realizar la búsqueda.',
          color: 'info',
        })
        this.limpiarSeccionArts()
        return
      }
      if (opcion == 1){
        // control de cantidades
        if (this.cantidad != null && this.cantidad != undefined && this.cantidad != 0 && this.cantidad.toString().length > 0 && (this.cantidadRemito == null ||
        this.cantidadRemito == undefined || this.cantidadRemito == 0 || this.cantidadRemito.toString().length == 0)){
          this.cantidadRemito = this.cantidad
        }
        if (this.cantidadRemito != null && this.cantidadRemito != undefined && this.cantidadRemito != 0 && this.cantidadRemito.toString().length > 0 && (this.cantidad == null ||
        this.cantidad == undefined || this.cantidad == 0 || this.cantidad.toString().length == 0)){
          this.cantidad = this.cantidadRemito
        }
        if (this.cantidadRemito == null || this.cantidadRemito == undefined || this.cantidadRemito == 0 || this.cantidadRemito.toString().length == 0 ||
        this.cantidad == null || this.cantidad == undefined || this.cantidad == 0 || this.cantidad.toString().length == 0){
          this.limpiarSeccionArts()
          return
        }
        // // en dónde pongo el foco?
        // if (this.metodoIngreso == false) this.$refs.artRef.focus()
        // else this.$refs.barrasRef.focus()
        // controlo el ingreso del usuario
        if ((this.codArticulo != null && this.codArticulo != undefined && this.codArticulo.toString().length > 0) || (this.codBarras != null && this.codBarras != undefined &&
        this.codBarras.toString().length > 0)){
          // primero controlo a ver si estan ingresando con código de art o bien de barras
          if (this.metodoIngreso == false){
            // ingresan por código de artículo
            if (this.codArticulo == null || this.codArticulo == undefined || this.codArticulo == 0 || this.codArticulo.toString().length == 0){
              this.$store.dispatch('show_snackbar', {
                text: 'Debe ingresar un código de artículo válido.',
                color: 'info',
              })
              this.limpiarSeccionArts()
              return
            }
          }else{
            // ingresan por código de barras
            if (this.codBarras == null || this.codBarras == undefined || this.codBarras == 0 || this.codBarras.toString().length == 0){
              this.$store.dispatch('show_snackbar', {
                text: 'Debe ingresar un código de barras válido.',
                color: 'info',
              })
              this.limpiarSeccionArts()
              return
            }
          }
          // agregan desde el formulario, tengo artículos?
          if (this.dialogSelectArticulo.articulos.length == 0 && this.load == false){
            this.load = true
            let artsPeticion = await this.$store.dispatch('recepciones/getArticulosProveedor', {proveedor_codigo: this.selectedProveedor.proveedor_codigo})
            this.load = false
            if (artsPeticion.resultado == 1){
              this.dialogSelectArticulo.articulos = artsPeticion.articulos
            }else{
              this.$store.dispatch('show_snackbar', {
                text: artsPeticion.msj,
                color: 'error',
              })
              this.limpiarSeccionArts()
              return
            }
          }else{
            if (this.load == true) return
          }
          // hasta aquí debería tener los artículos. Busco a ver si tengo el artículo con su código de barras
          let unArt = []
          if (this.metodoIngreso == false) unArt = this.dialogSelectArticulo.articulos.filter(element => element.articulo_codigo == this.codArticulo)
          else unArt= this.dialogSelectArticulo.articulos.filter(element => (element.articulo_cod_barras != null && element.articulo_cod_barras.toUpperCase() == this.codBarras.toUpperCase()) || (element.codigo_ean != null && element.codigo_ean.toUpperCase() == this.codBarras.toUpperCase()))
          // verifico la salida
          if (unArt.length == 1){
            // tengo un solo registro
            articulo = unArt[0]
          }else if (unArt.length > 1){
            // más de un registro, trato de quedarme con el que tenga el código de barras asociado
            let conCodBarras = unArt.filter(element => (element.articulo_cod_barras != null && element.articulo_cod_barras != undefined && element.articulo_cod_barras.toString().length > 0) || (element.codigo_ean != null && element.codigo_ean != undefined && element.codigo_ean.toString().length > 0))
            if (conCodBarras.length > 0){
              // asigno mi articulo
              articulo = conCodBarras[0]
            }else{
              // no tengo código de barras asociado al artículo, asigno el primero de la lista unArt
              articulo = unArt[0]
            }
          }else{
            // no encontré el artículo solicitado, están ingresando código de barras?
            if (this.metodoIngreso == true){
              // está buscando por código de barras y no encontró el artículo, abro el modal de artículos para que asocie el artículo
              this.asociarCodBarras = 1
              this.dialogSelectArticulo.titulo_modal = 'Asociando el Código de Barras ' + this.codBarras
              this.dialogSelectArticulo.color = 'rowwar'
              this.dialogSelectArticulo.bloquear_cant = true
              this.dialogSelectArticulo.activo = true
              return
            }else{
              this.$store.dispatch('show_snackbar', {
                text: 'No se encontró el artículo solicitado.',
                color: 'info',
              })
              this.limpiarSeccionArts()
              return
            }
          }
        }else{
          // no dejo avanzar hasta que ingresen los códigos
          return
        }
      }else articulo = art
      if (articulo.cantidad == null || articulo.cantidad == undefined || articulo.cantidad == 0 || articulo.cantidad.toString().length == 0) articulo.cantidad = 1
      if (this.cantidad != null && this.cantidad != undefined && this.cantidad != 0 && this.cantidad.toString().length > 0) articulo.cantidad = this.cantidad
      if (!articulo.hasOwnProperty('codigo_ean') || articulo.codigo_ean == null || articulo.codigo_ean == undefined) articulo.codigo_ean = ''
      this.asociarCodBarras = 0
      // hasta aqui ya deberia tener un registro de this.dialogSelectArticulo.articulos en la varible local articulo, armo mi detalle tal como lo necesito
      let newArt = this.armarArticulo(articulo)
      // ya existe en el listado?
      let unArt = this.articulos.filter(element => element.articulo_codigo == newArt.articulo_codigo && element.codigo_barras == newArt.codigo_barras)
      if (unArt.length > 0){
        this.$store.dispatch('show_snackbar', {
          text: 'El artículo ya se encuentra en el listado.',
          color: 'warning',
        })
        this.limpiarSeccionArts()
        return
      }
      // no se encuentra en el listado, solicita series?
      if (newArt.solicita_serie == 1){
        // abro el modal para que ingrese las series
        this.selectedArt = newArt
        this.dialogIngresarSeries.articulo_codigo = newArt.articulo_codigo
        this.dialogIngresarSeries.articulo_nombre = newArt.articulo_nombre
        this.dialogIngresarSeries.series = newArt.series
        this.dialogIngresarSeries.cantidad = newArt.cantidad
        this.dialogIngresarSeries.activo = true
        return
      }
      this.articulos.push(newArt)
      this.limpiarSeccionArts()
      if (this.nuevaRecep == 1) this.$refs.cantRef.focus()
    },
    volverIndex(){
      router.push({path: '/recepciones'})
    },
    verificarObligatorios(){
      if (Object.keys(this.selectedDeposito).length == 0) return {valido: false, msj: 'Debe seleccionar el Depósito.'}
      if (Object.keys(this.selectedProveedor).length == 0) return {valido: false, msj: 'Debe seleccionar el Proveedor.'}
      if (this.articulos.length == 0) return {valido: false, msj: 'No se ha ingresado ningún artículo para recepcionar.'}
      let cantidadCero = this.articulos.filter(element => parseInt(element.cantidad) <= 0 || parseInt(element.cantidad_remito) <= 0)
      if (cantidadCero.length > 0) return {valido: false, msj: 'Existen detalles donde la cantidad o la cantidad en remito son cero o menores a cero.'}
      return {valido: true, msj: 'OK'}
    },
    controlarSeries(){
      let band = true
      for (let id in this.articulos){
        if (this.articulos[id].cantidad != this.articulos[id].series.length && this.articulos[id].solicita_serie == 1){
          band = false
        }
      }
      return band
    },
    async sendRecepcion(){
      // verifico obligatorios
      let valido = this.verificarObligatorios()
      if (valido.valido == false){
        this.$store.dispatch('show_snackbar', {
          text: valido.msj,
          color: 'warning',
        })
        return
      }
      // controlo las cantidades de series de los artículos que solicitan serie
      let controlSeries = this.controlarSeries()
      if (controlSeries == false){
        this.$store.dispatch('show_snackbar', {
          text: 'Existen detalles donde no coinciden la cantidad de series ingresadas con la cantidad a recepcionar. No se puede proceder.',
          color: 'warning',
        })
        return
      }
      // orden de compra
      let ordenCompra = 0
      if (Object.keys(this.selectedOrdenCompra).length > 0) ordenCompra = this.selectedOrdenCompra.orden_id
      // emision
      let emis = 0
      if (this.emision != null && this.emision != undefined && this.emision.toString().length > 0) emis = this.emision
      // numero
      let num = 0
      if (this.numero != null && this.numero != undefined && this.numero.toString().length > 0) num = this.numero
      // todo ok, paso a armar mi body
      let body = {
        deposito_codigo: this.selectedDeposito.deposito_codigo,
        proveedor_codigo: this.selectedProveedor.proveedor_codigo,
        orden_compra: ordenCompra,
        fecha: this.fecha,
        emision: emis,
        numero: num,
        observaciones: this.observacion,
        articulos: this.articulos,
        pre_recepcion: this.preRecepcion == false ? 0 : 1
      }
      this.$store.state.loading = true
      let grabarPeticion = await this.$store.dispatch('recepciones/createRecepcion', body)
      this.$store.state.loading = false
      if (grabarPeticion.resultado == 1){
        this.$store.dispatch('show_snackbar', {
          text: 'La Recepción ingresada se grabó correctamente.',
          color: 'success',
        })
        //cambio la ruta con la nueva recepción
        let path = '/ver-recepcion/' + grabarPeticion.recepcion_id.toString()
        router.push({path: path})
        // recargo la página de la nueva recepción
        setTimeout(() => [location.reload()], 2000)
        
      }else{
        this.$store.dispatch('show_snackbar', {
          text: grabarPeticion.msj,
          color: 'error',
        })
      }
    },
    quitarArticulo(item){
      let pos = this.articulos.indexOf(item)
      this.articulos.splice(pos, 1)
    },
    verSeries(item){
      this.posItem = this.articulos.indexOf(item)
      // paso a ingresar las series
      this.dialogIngresarSeries.articulo_codigo = item.articulo_codigo
      this.dialogIngresarSeries.articulo_nombre = item.articulo_nombre
      this.dialogIngresarSeries.series = item.series
      this.dialogIngresarSeries.cantidad = item.cantidad
      if (this.nuevaRecep == 0) this.dialogIngresarSeries.ver = 1
      this.dialogIngresarSeries.activo = true
    },
    verificarSeries(item){
      if (item.series.length != item.cantidad && item.solicita_serie == 1){
        this.posItem = this.articulos.indexOf(item)
        this.dialogIngresarSeries.articulo_codigo = item.articulo_codigo
        this.dialogIngresarSeries.articulo_nombre = item.articulo_nombre
        this.dialogIngresarSeries.series = item.series
        this.dialogIngresarSeries.cantidad = item.cantidad
        this.dialogIngresarSeries.activo = true
      }
    },
    setModalIngSeries(value){
      this.dialogIngresarSeries.activo = value
      if (this.dialogIngresarSeries.activo == false){
        this.dialogIngresarSeries.articulo_codigo = ''
        this.dialogIngresarSeries.articulo_nombre = ''
        this.dialogIngresarSeries.cantidad = 0
        this.dialogIngresarSeries.ver = 0
        this.dialogIngresarSeries.series = []
      }
    },
    setSeries(lisSeries){
      let det = {}
      if (this.posItem == -1 && Object.keys(this.selectedArt).length > 0) det = this.selectedArt
      else det = this.articulos[this.posItem]
      det.series = lisSeries
      if (parseInt(this.posItem) >= 0) Object.assign(this.articulos[this.posItem], det)
      else this.articulos.push(det)
      // reseteo la posicion
      this.posItem = -1
      // limpio selectedArt
      this.selectedArt = {}
      // limpio la seccion de artículos
      this.limpiarSeccionArts()
      // me vuelvo a posicionar en cantidad
      if (this.nuevaRecep == 1) this.$refs.cantRef.focus()
    },
    async getArticulos(){
      if (Object.keys(this.selectedProveedor).length > 0){
        if (this.dialogSelectArticulo.articulos.length == 0){
          this.load = true
          let artsPeticion = await this.$store.dispatch('recepciones/getArticulosProveedor', {proveedor_codigo: this.selectedProveedor.proveedor_codigo})
          this.load = false
          if (artsPeticion.resultado == 1){
            this.dialogSelectArticulo.articulos = artsPeticion.articulos
          }else{
            this.$store.dispatch('show_snackbar', {
              text: artsPeticion.msj,
              color: 'error',
            })
            return
          }
        }
        // abro el modal
        this.dialogSelectArticulo.activo = true
      }else{
        this.$store.dispatch('show_snackbar', {
          text: 'Debe seleccionar un proveedor para buscar Artículos.',
          color: 'info',
        })
      }
    },
    setModalSelectArt(value){
      this.dialogSelectArticulo.activo = value
      if (this.dialogSelectArticulo.activo == false){
        this.dialogSelectArticulo.titulo_modal = 'Buscar Artículos'
        this.dialogSelectArticulo.color = ''
        this.dialogSelectArticulo.bloquear_cant = false
      }
    },
    setArticulo(art){
      if (this.asociarCodBarras == 1 && (this.codBarras != null && this.codBarras != undefined && this.codBarras.toString().length > 0)){
        // vine de seleccionar un artículo para asociar el código de barras
        this.selectedArt = art
        this.dialogConfAsociar = true
        return
      }
      this.asociarCodBarras = 0
      this.addArticulo(art, 2)
    },
    async asociarArticulo(){
      this.$store.state.loading = true
      let asociarPeticion = await this.$store.dispatch('recepciones/asociarArtCodBarras', {
        articulo_codigo: this.selectedArt.articulo_codigo,
        articulo_nombre: this.selectedArt.articulo_nombre,
        codigo_barras: this.codBarras,
        codigo_ean: '',
        proveedor_codigo: this.selectedProveedor.proveedor_codigo,
        opcion: 1 // opcion 1 quiere decir que voy a asociar el codigo de barras al codigo de BB
      })
      this.$store.state.loading = false
      if (asociarPeticion.resultado == 0){
        // no se asoció el código de barras, dejo listo todo para que intente asociar nuevamente si es que el usuario así lo quiere
        this.$store.dispatch('show_snackbar', {
          text: asociarPeticion.msj,
          color: 'error',
        })
        this.selectedArt = {}
        this.asociarCodBarras = 0
        return
      }
      // éxito, paso a agregar
      this.selectedArt.articulo_cod_barras = this.codBarras
      let art = this.selectedArt
      this.addArticulo(art, 2)
      this.selectedArt = {}
      this.asociarCodBarras = 0
    },
    setModalAsociar(){
      this.dialogConfAsociar = false
    },
    async cancelRecepcion(){
      this.$store.state.loading = true
      let anularPeticion = await this.$store.dispatch('recepciones/anularRecepcion', {recepcion_id: this.recepId})
      this.$store.state.loading = false
      if (anularPeticion.resultado == 1){
        this.$store.dispatch('show_snackbar', {
          text: 'La Recepción se anuló correctamente.',
          color: 'success',
        })
        setTimeout(() => [this.volverIndex()], 2000)
      }else{
        this.$store.dispatch('show_snackbar', {
          text: anularPeticion.msj,
          color: 'error',
        })
      }
    },
    async iniciarCargaAsistida(){
      // controlo si seleccionaron la bodega y el proveedor
      if (Object.keys(this.selectedDeposito).length == 0 || Object.keys(this.selectedProveedor).length == 0){
        this.$store.dispatch('show_snackbar', {
          text: 'Debe seleccionar el Depósito y Proveedor para empezar la Carga Asistida.',
          color: 'info',
        })
        return
      }
      // primero obtengo los artículos
      if (this.dialogSelectArticulo.articulos.length == 0 || this.dialogCargaAsistida.articulos == 0){
        this.load = true
        let artsPeticion = await this.$store.dispatch('recepciones/getArticulosProveedor', {proveedor_codigo: this.selectedProveedor.proveedor_codigo})
        this.load = false
        if (artsPeticion.resultado == 1){
          this.dialogSelectArticulo.articulos = artsPeticion.articulos
          this.dialogCargaAsistida.articulos = artsPeticion.articulos
        }else{
          this.$store.dispatch('show_snackbar', {
            text: artsPeticion.msj,
            color: 'error',
          })
          return
        }
      }
      this.dialogCargaAsistida.proveedor_codigo = this.selectedProveedor.proveedor_codigo
      this.dialogCargaAsistida.proveedor_nombre = this.selectedProveedor.proveedor_nombre
      this.dialogCargaAsistida.tipo_ingreso = this.metodoIngreso
      this.dialogCargaAsistida.pre_recepcion = this.preRecepcion == true ? 1 : 0
      this.dialogCargaAsistida.deposito_codigo = this.selectedDeposito.deposito_codigo
      if (Object.keys(this.selectedOrdenCompra).length > 0) this.dialogCargaAsistida.orden_compra = this.selectedOrdenCompra
      this.dialogCargaAsistida.activo = true
    },
    setModalCarAsistRecep(value){
      this.dialogCargaAsistida.activo = value
      if (this.dialogCargaAsistida.activo == false){
        this.dialogCargaAsistida.proveedor_codigo = 0
        this.dialogCargaAsistida.proveedor_nombre = ''
        this.dialogCargaAsistida.pre_recepcion = 0
        this.dialogCargaAsistida.orden_compra = {}
        this.dialogCargaAsistida.deposito_codigo = 0
      }
    },
    setTipIngCarAsistida(value){
      this.metodoIngreso = value
      this.dialogCargaAsistida.tipo_ingreso = value
    },
    setArtsCargaAsistida(listado){
      // limpio la sección de artículos por si acaso
      this.limpiarSeccionArts()
      // recorro el listado y armo mis articulos
      for (let id in listado){
        let unArt = this.armarArticulo(listado[id])
        this.articulos.push(unArt)
      }
    },
    setModalUpImg(value){
      this.dialogSubirImgs.activo = value
      if (this.dialogSubirImgs.activo == false){
        this.dialogSubirImgs.icono = 'fas fa-eye'
        this.dialogSubirImgs.titulo_modal = 'Imagenes para La Recepción '
      }
    },
    subirImagenes(){
      // titulo del modal
      this.dialogSubirImgs.titulo_modal = this.dialogSubirImgs.titulo_modal + this.recepId
      // ruta de la carpeta donde se deberian encontrar los archivos
      this.dialogSubirImgs.ruta = '/recepciones/' + this.recepId
      // indico el id identificador de las fotos para la recepción
      this.dialogSubirImgs.id = parseInt(this.recepId)
      // abro el modal
      this.dialogSubirImgs.activo = true
    },
    setOrdCompra(value){
      this.selectedOrdenCompra = value
      this.dialogCargaAsistida.orden_compra = value
    },
    setDetsPreRecepcion(listado){
      // limpio la sección de artículos por si acaso
      this.limpiarSeccionArts()
      // recorro el listado y armo mis articulos
      for (let id in listado){
        let unArt = this.armarArticulo(listado[id])
        this.articulos.push(unArt)
      }
    },
    limpiarDetalles(){
      this.selectedOrdenCompra = {}
      this.dialogCargaAsistida.orden_compra = {}
      this.articulos = []
    }
  },
  watch: {
    metodoIngreso: function(){
      if (this.metodoIngreso == false) this.codBarras = ''
      else this.codArticulo = ''
    },
    selectedDeposito: function(){
      if (this.nuevaRecep == 1){
        this.articulos = []
        this.dialogSelectArticulo.articulos = []
        this.dialogCargaAsistida.articulos = []
      }
    },
    selectedProveedor: function(){
      if (this.nuevaRecep == 1){
        this.articulos = []
        this.dialogSelectArticulo.articulos = []
        this.dialogCargaAsistida.articulos = []
      }
    },
    preRecepcion: function(){
      if (this.preRecepcion == true){
        this.labelPreRecep = 'Pre Recepcionar'
        this.articulos = []
        if (Object.keys(this.selectedOrdenCompra).length > 0) this.dialogCargaAsistida.orden_compra = this.selectedOrdenCompra
        // indico automaticamente que voy a ingresar por codigos de barra
        this.metodoIngreso = true
      }else{
        this.labelPreRecep = 'Recepcionar'
        this.setModalCarAsistRecep(false)
      }
    }
  },
}
</script>

<style>

</style>