<template>
  <v-row class="d-flex justify-center mt-0">
    <v-col cols="12">
      <v-expansion-panels v-model="panel">
        <v-expansion-panel readonly>
          <v-btn
            style="position: absolute; top: -15px; right: 55px; z-index: 1"
            color="success"
            small
            fab
            @click="dialog = true"
          >
            <v-icon>fas fa-plus</v-icon>
          </v-btn>
          <v-expansion-panel-header class="py-0 px-4 text-h5" style="cursor: default">
            <div>
              <v-icon class="mb-1" left>fas fa-filter</v-icon>
              Filtro
            </div>
            <template v-slot:actions>
              <v-btn icon @click.stop="panel = panel === 1 ? 0 : 1">
                <v-icon>fas fa-chevron-down</v-icon>
              </v-btn>
            </template>
          </v-expansion-panel-header>
          <v-expansion-panel-content class="pt-1">
            <v-form @submit.prevent="buscar()">
              <v-row>
                <v-col cols="6" md="2" class="py-1">
                  Empresa
                  <v-autocomplete
                    v-model="filtro.empresa"
                    item-text="nombre_corto"
                    item-value="id"
                    tabindex="1"
                    :items="empresas"
                    hide-details
                    autofocus
                    clearable
                    outlined
                    dense
                    @change="filtro.deposito = null"
                  ></v-autocomplete>
                </v-col>
                <v-col cols="6" md="2" class="py-1">
                  Sucursal
                  <v-autocomplete
                    v-model="filtro.sucursal"
                    item-text="nombre"
                    item-value="id"
                    tabindex="1"
                    :items="sucursales"
                    hide-details
                    clearable
                    outlined
                    dense
                    @change="filtro.deposito = null"
                  ></v-autocomplete>
                </v-col>
                <v-col cols="12" sm="6" md="4" class="py-1">
                  Bodega
                  <v-autocomplete
                    v-model="filtro.deposito"
                    item-text="nombre"
                    item-value="codigo"
                    tabindex="1"
                    :items="bodegas_user.filter(b => b.empresa_codigo == filtro.empresa && b.sucursal_codigo == filtro.sucursal)"
                    hide-details
                    clearable
                    outlined
                    dense
                  ></v-autocomplete>
                </v-col>
                <v-col cols="6" md="2" class="py-1">
                  Estado
                  <v-autocomplete
                    v-model="filtro.estado"
                    item-text="nombre"
                    item-value="codigo"
                    tabindex="1"
                    :items="permiso == 1 ? estados_control_stk : estados_control_stk.filter(e => e.permiso != 1)"
                    hide-details
                    clearable
                    outlined
                    dense
                  ></v-autocomplete>
                </v-col>
                <v-col cols="6" md="2" class="py-1">
                  ID
                  <v-text-field
                    v-model.trim="filtro.id"
                    tabindex="1"
                    type="number"
                    hide-details
                    outlined
                    dense
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="3" class="py-1">
                  Descripción
                  <v-text-field
                    v-model.trim="filtro.descripcion"
                    tabindex="1"
                    hide-details
                    outlined
                    dense
                  ></v-text-field>
                </v-col>
                <v-col cols="12" :sm="big ? 6 : 3" class="py-1">
                  Fecha
                  <FechaPickerRango
                    v-model="filtro.fecha"
                    :limpiar.sync="limpiar_fecha"
                    :rango_def="5"
                    @changeSize="changeSize"
                  />
                </v-col>
                <BtnFiltro
                  :loading="load"
                  @clear="limpiar()"
                />
              </v-row>
            </v-form>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>

      <v-data-table
        class="cebra elevation-2 mt-2"
        sort-by="id"
        :headers="headers"
        :items="controles"
        :loading="load"
        :search="search"
        sort-desc
        dense
      >
        <template v-slot:top>
          <v-row class="d-flex justify-end pa-2" no-gutters>
            <v-col cols="6" sm="3" md="2">
              <SearchDataTable
                v-model="search"
              />
            </v-col>
          </v-row>
        </template>
        <template v-slot:[`item.estado`]="{ item }">
          <v-chip
            class="black--text"
            :color="estados_control_stk.find(e => e.codigo == item.estado_id).color"
            small
          >
            {{ item.estado }}
          </v-chip>
        </template>
        <template v-slot:[`item.actions`]="{ item }">
          <v-icon
            color="info"
            title="Ver"
            class="mr-2"
            small
            @click="open_window(item.id)"
          >
            fas fa-eye
          </v-icon>
          <v-icon
            v-if="item.estado_id == 4"
            color="info"
            title="Informe"
            class="mr-2"
            small
            @click="descargar_informe(item.id)"
          >
            fas fa-print
          </v-icon>
          <BtnConfirmar
            v-if="(item.estado_id == 1 && permiso == 1) || cancelar == 1"
            icono="fas fa-ban"
            title="Inhabilitar"
            clase="mr-2"
            :color="estados_control_stk.find(e => e.codigo == 6).color"
            :texto="`¿Desea <strong>inhabilitar</strong> el control de stock Nº<strong>${item.id}</strong>?`"
            :icon_button="true"
            :small="true"
            @action="inhabilitar_control(item)"
          />
          <v-icon
            v-if="item.estado_id == 4 || item.estado_id == 5 || item.estado_id == 7"
            class="mr-2"
            :color="item.archivos ? 'success' : 'error'"
            :title="item.archivos ? 'Ver archivos' : 'Subir archivos'"
            small
            @click="archivos(item)"
          >
            fas fa-file-{{ item.archivos ? 'download' : 'upload' }}
          </v-icon>
          <BtnConfirmar
            v-if="item.estado_id == 4 && permiso == 1"
            icono="fas fa-check"
            title="Preauditar"
            :color="estados_control_stk.find(e => e.codigo == 7).color"
            :texto="`¿Desea <strong>preauditar</strong> el control de stock Nº<strong>${item.id}</strong>?`"
            :icon_button="true"
            :small="true"
            @action="preauditar(item)"
          />
          <BtnConfirmar
            v-if="item.estado_id == 7 && permiso == 1"
            icono="fas fa-check-double"
            title="Auditar"
            :color="estados_control_stk.find(e => e.codigo == 5).color"
            :texto="`¿Desea <strong>auditar</strong> el control de stock Nº<strong>${item.id}</strong>?`"
            :icon_button="true"
            :small="true"
            @action="auditar(item)"
          />
        </template>
        <template v-slot:no-data>
          <v-alert
            class="mx-auto mt-4"
            max-width="400"
            type="warning"
            border="left"
            dense
            text
          >
            No hay datos para los filtros seleccionados
          </v-alert>
        </template>
      </v-data-table>
    </v-col>
    <NuevoControlStock
      v-model="dialog"
      @nuevo="nuevo_control"
    />
    <PDFViewer
      v-model="dialog_pdf"
      :pdf="pdf"
      :nombre="pdf_nombre"
      :titulo="pdf_nombre"
    />
    <ModalImagenes
      v-model="dialog_archivo"
      :p_permiso="permiso"
      :p_control="control"
    />
  </v-row>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import { reporteControlStock } from '../../util/plantillas/pdfs'
import BtnFiltro from '../../components/util/BtnFiltro'
import BtnConfirmar from '../../components/util/BtnConfirmar'
import SearchDataTable from '../../components/util/SearchDataTable'
import FechaPickerRango from '../../components/util/FechaPickerRango'
import NuevoControlStock from '../../components/stock/NuevoControlStock'
import PDFViewer from '../../util/plantillas/PDFViewer'
import ModalImagenes from '../../components/stock/ModalImagenes'

export default {
  data () {
    return {
      panel: 0,
      permiso: 0,
      cancelar: 0,
      search: '',
      pdf_nombre: '',
      pdf: null,
      big: false,
      load: false,
      dialog: false,
      dialog_pdf: false,
      dialog_archivo: false,
      limpiar_fecha: false,
      controles: [],
      headers: [
        { text: 'ID', value: 'id', align: 'end' },
        { text: 'Descripción', value: 'descripcion' },
        { text: 'Estado', value: 'estado' },
        { text: 'Dispobible desde', value: 'desde' },
        { text: 'Disponible hasta', value: 'hasta' },
        { text: 'Bodega', value: 'deposito' },
        { text: 'Acciones', value: 'actions', sortable: false, filterable: false, align: 'center' },
      ],
      control: {},
      filtro: {
        id: null,
        empresa: null,
        sucursal: null,
        deposito: null,
        descripcion: '',
        estado: null,
        fecha: [ null, null ]
      }
    }
  },
  async created () {
    // si tiene solo 1 empresa la selecciona
    if (this.una_empresa) {
      this.filtro.empresa = this.empresas[0].id
    }
    // si tiene solo 1 sucursal la selecciona
    if (this.una_sucursal) {
      this.filtro.sucursal = this.sucursales[0].id
    }
    // obtiene los datos necesarios
    this.$store.state.loading = true
    await this.$store.dispatch('bodegas/get_bodegas_user')
    await this.$store.dispatch('controlStock/get_estados')
    let res = await this.$store.dispatch('controlStock/verify_permiso')
    this.permiso = res.permiso
    this.cancelar = await this.$store.dispatch('controlStock/puede_cancelar')
    this.$store.state.loading = false
  },
  computed: {
    ...mapGetters(['una_empresa', 'una_sucursal']),
    ...mapState(['empresas', 'sucursales']),
    ...mapState('controlStock', ['estados_control_stk']),
    ...mapState('bodegas', ['bodegas_user'])
  },
  components: {
    BtnFiltro,
    BtnConfirmar,
    SearchDataTable,
    FechaPickerRango,
    NuevoControlStock,
    ModalImagenes,
    PDFViewer
  },
  methods: {
    async auditar (item) {
      this.$store.state.loading = true
      await this.$store.dispatch('controlStock/auditar_control', item.id)
        .then((res) => {
          item.estado_id = res.estado
          item.estado = this.estados_control_stk.find(e => e.codigo == res.estado).nombre
          this.open_window(item.id)
        })
        .catch(error => {
          this.$store.dispatch('show_snackbar', {
            text: error.message,
            color: 'error'
          })
        })
      this.$store.state.loading = false
    },
    async preauditar (item) {
      this.$store.state.loading = true
      await this.$store.dispatch('controlStock/preauditar_control', item.id)
        .then((res) => {
          item.estado_id = res.estado
          item.estado = this.estados_control_stk.find(e => e.codigo == res.estado).nombre
          this.open_window(item.id)
        })
        .catch(error => {
          this.$store.dispatch('show_snackbar', {
            text: error.message,
            color: 'error'
          })
        })
      this.$store.state.loading = false
    },
    archivos (item) {
      this.control = item
      this.dialog_archivo = true
    },
    async descargar_informe (id) {
      this.$store.state.loading = true
      await this.$store.dispatch('controlStock/get_datos_informe', id)
        .then(async (res) => {
          this.pdf = await reporteControlStock(res.data)
          this.pdf_nombre = `Control de Stock Nº ${id}`
          this.dialog_pdf = true
        })
        .catch(error => {
          this.$store.dispatch('show_snackbar', {
            text: error.message,
            color: 'error'
          })
        })
      this.$store.state.loading = false
    },
    async inhabilitar_control (item) {
      this.$store.state.loading = true
      await this.$store.dispatch('controlStock/inhabilitar_control', item.id)
        .then((res) => {
          item.estado_id = res.estado
          item.estado = this.estados_control_stk.find(e => e.codigo == res.estado).nombre
          this.$store.dispatch('show_snackbar', {
            text: res.message,
            color: 'success'
          })
        })
        .catch(error => {
          this.$store.dispatch('show_snackbar', {
            text: error.message,
            color: 'error'
          })
        })
      this.$store.state.loading = false
    },
    async buscar () {
      this.load = true
      this.search = ''
      this.controles = []
      await this.$store.dispatch('controlStock/get_controles', this.filtro)
        .then((res) => {
          this.controles = res.data
        })
        .catch(error => {
          this.$store.dispatch('show_snackbar', {
            text: error.message,
            color: 'error'
          })
        })
      this.load = false
    },
    async nuevo_control (item) {
      this.$store.state.loading = true
      await this.$store.dispatch('controlStock/get_controles', { id: item.id })
        .then((res) => {
          this.controles.unshift(res.data[0])
        })
        .catch(error => {
          this.$store.dispatch('show_snackbar', {
            text: 'Ocurrio un error al obtener el nuevo control de stock: ' + error.message,
            color: 'error',
            timeout: 4000
          })
        })
      this.$store.state.loading = false
    },
    open_window (id) {
      let path = '/nuevo/control-stock'
      if (id) {
        path = `/control-stock/${id}`
      }
      const routeData = this.$router.resolve({ path: path })
      window.open(routeData.href, '_blank')
    },
    limpiar () {
      this.filtro = {
        id: null,
        empresa: null,
        sucursal: null,
        deposito: null,
        descripcion: '',
        estado: null,
        fecha: [ null, null ]
      }
      this.limpiar_fecha = true
    },
    changeSize (custom) {
      this.big = custom
    }
  }
}
</script>