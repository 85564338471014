<template>
  <div>
    <v-row class="d-flex justify-center mt-0">
      <v-col cols="12">
        <v-expansion-panels v-model="panel">
          <v-expansion-panel readonly>
            <!-- Titulo y boton agregar -->
            <v-btn
              style="position: absolute; top: -15px; right: 55px; z-index: 1"
              color="success"
              small
              fab
              title="Nuevo Remito"
              @click="nuevoRemito()"
            >
              <v-icon>fas fa-plus</v-icon>
            </v-btn>
            <v-expansion-panel-header class="py-0 px-4 text-h5" style="cursor: default">
              <div>
                <v-icon class="mb-1" left>fas fa-filter</v-icon>
                Filtro
              </div>
              <template v-slot:actions>
                <v-btn icon @click.stop="panel = panel === 1 ? 0 : 1">
                  <v-icon>fas fa-chevron-down</v-icon>
                </v-btn>
              </template>
            </v-expansion-panel-header>
            <!-- Filtros -->
            <v-expansion-panel-content class="pt-1">
              <v-form @submit.prevent="buscar()">
                <v-row :no-gutters="$vuetify.breakpoint.xs">
                  <v-col cols="12" sm="4" md="4" class="py-1">
                    Bodega Origen
                    <v-autocomplete
                      v-model="selectedBodOrigen"
                      item-text="bodega_nombre"
                      :items="bodegasOrigen"
                      hide-details
                      outlined
                      dense
                      return-object
                    ></v-autocomplete>
                  </v-col>
                  <v-col cols="12" sm="4" md="4" class="py-1">
                    Bodega Destino
                    <v-autocomplete
                      v-model="selectedBodDestino"
                      item-text="bodega_nombre"
                      :items="bodegasDestino"
                      hide-details
                      outlined
                      dense
                      return-object
                    ></v-autocomplete>
                  </v-col>
                  <v-col cols="12" sm="4" md="4" class="py-1">
                    Estados
                    <v-autocomplete
                      v-model="selectedEstado"
                      item-text="estado_nombre"
                      :items="estados"
                      hide-details
                      outlined
                      dense
                      return-object
                    ></v-autocomplete>
                  </v-col>
                </v-row>
                <v-row :no-gutters="$vuetify.breakpoint.xs">
                  <v-col cols="12" sm="2" md="2" class="py-1">
                    Remito Número
                    <v-text-field
                      v-model="remitoNumero"
                      outlined
                      dense
                      type="number"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" :sm="big ? 6 : 3" class="py-1">
                    Fecha
                    <FechaPickerRango
                      v-model="fechaRango"
                      :limpiar.sync="limpiarFecha"
                      @changeSize="changeSize"
                    />
                  </v-col>
                  <v-col cols="12" :sm="big ? 3 : 6" class="pt-0">
                    <BtnFiltro
                      :loading="load"
                      @clear="limpiar()"
                    />
                  </v-col>
                </v-row>
              </v-form>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
        <!-- Listado de Remitos -->
        <v-data-table
          class="cebra elevation-2 mt-2"
          :headers="headers"
          :items="remitos"
          :loading="load"
          dense
          :search="search"
        >
          <!-- Buscador en el datatable -->
          <template v-slot:top>
            <v-row class="d-flex justify-end pa-2" no-gutters>
              <v-col cols="6" sm="3">
                <SearchDataTable
                  v-model="search"
                />
              </v-col>
            </v-row>
          </template>
          <!-- Acciones -->
          <template v-slot:[`item.acciones`]="{ item }">
            <v-btn icon small @click="verRemito(item)"><v-icon small color="success" title="Ver">fas fa-eye</v-icon></v-btn>
            <v-btn icon small @click="generarPDF(item)"><v-icon small color="error" title="Descargar PDF">fas fa-file-pdf</v-icon></v-btn>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  <!-- Modal ver PDF -->
  <PDFViewer 
    v-model="dialogPdf"
    :pdf="pdf"
    :nombre="nombreVer"
    :titulo="nombreVer"
  />
  </div>
</template>

<script>
  import FechaPickerRango from '../../components/util/FechaPickerRango.vue'
  import BtnFiltro from '../../components/util/BtnFiltro.vue'
  import SearchDataTable from '../../components/util/SearchDataTable.vue'
  import PDFViewer from '../../util/plantillas/PDFViewer.vue'
  import moment from 'moment'
  import router from '../../router'
  import {reporteRemitoInterno} from '../../util/plantillas/pdfs'
export default {
  components: {
    FechaPickerRango,
    BtnFiltro,
    SearchDataTable,
    PDFViewer
  },
  data() {
    return {
      panel: 0,
      selectedBodOrigen: {},
      bodegasOrigen: [],
      selectedBodDestino: {},
      bodegasDestino: [],
      selectedEstado: {},
      estados: [],
      remitoNumero: '',
      fechaRango: [ null, null ],
      limpiarFecha: false,
      big: false,
      load: false,
      headers: [
        { text: 'Número', align: 'center', value: 'remito_numero' },
        { text: 'Fecha', align: 'center', value: 'fecha' },
        { text: 'Bodega Origen', align: 'center', value: 'bodega_origen_nombre' },
        { text: 'Bodega Destino', align: 'center', value: 'bodega_destino_nombre' },
        { text: 'Estado', align: 'center', value: 'estado_nombre' },
        { text: 'Usuario', align: 'center', value: 'usuario_estado' },
        { text: 'Acciones', align: 'center', value: 'acciones' }
      ],
      remitos: [],
      search: '',
      dialogPdf: false,
      pdf: '',
      nombreVer: ''
    }
  },
  created () {
    this.initForm()
  },
  methods: {
    async initForm(){
      this.$store.state.loading = true
      // ya tengo los datos en localStorage?
      if (localStorage.getItem('bodegasO') && JSON.parse(localStorage.getItem('bodegasO')).length > 0 &&
      localStorage.getItem('bodegasD') && JSON.parse(localStorage.getItem('bodegasD')).length > 0 &&
      localStorage.getItem('estadosRemitos') && JSON.parse(localStorage.getItem('estadosRemitos')).length > 0){
        this.$store.state.loading = false
        this.bodegasOrigen = JSON.parse(localStorage.getItem('bodegasO'))
        this.bodegasDestino = JSON.parse(localStorage.getItem('bodegasD'))
        this.estados = JSON.parse(localStorage.getItem('estadosRemitos'))
      }else{
        let initPeticion = await this.$store.dispatch('remitos/initForm')
        this.$store.state.loading = false
        if (initPeticion.resultado == 1){
          this.bodegasOrigen = initPeticion.bodegasO
          this.bodegasDestino = initPeticion.bodegasD
          this.estados = initPeticion.estados
        }else{
          this.bodegasOrigen = []
          this.bodegasDestino = []
          this.estados = []
          this.$store.dispatch('show_snackbar', {
            text: initPeticion.msj,
            color: 'error',
          })
          return
        }
      }
    },
    filtrosVacios(){
      if (Object.keys(this.selectedBodOrigen).length == 0 && Object.keys(this.selectedBodDestino).length == 0 && Object.keys(this.selectedEstado).length == 0 && this.remitoNumero.length == 0){
        return true
      }
      return false
    },
    async buscar(){
      if (this.filtrosVacios()){
        this.$store.dispatch('show_snackbar', {
          text: 'Debe ingresar al menos un filtro para realizar la búsqueda.',
          color: 'info',
        })
        return
      }
      this.$store.state.loading = true
      this.remitos = []
      // consigo los datos que necesito para la consulta
      let bodegaOrigen = 0
      if (Object.keys(this.selectedBodOrigen).length > 0){
        bodegaOrigen = this.selectedBodOrigen.bodega_codigo
      }
      let bodegaDestino = 0
      if (Object.keys(this.selectedBodDestino).length > 0){
        bodegaDestino = this.selectedBodDestino.bodega_codigo
      }
      let estado = 0
      if (Object.keys(this.selectedEstado).length > 0){
        estado = this.selectedEstado.estado_codigo
      }
      let numeroRemito = 0
      if (this.remitoNumero.length > 0){
        numeroRemito = this.remitoNumero
      }
      let fechaHastaBus = ''
      if (this.fechaRango[1] && null || this.fechaRango[1] != undefined && this.fechaRango[1].length > 0){
        fechaHastaBus = moment(this.fechaRango[1]).format('DD/MM/YYYY')
      }
      let datos = {
        bodega_origen: bodegaOrigen,
        bodega_destino: bodegaDestino,
        estado: estado,
        remito_numero: numeroRemito,
        fecha_desde: moment(this.fechaRango[0]).format('DD/MM/YYYY'),
        fecha_hasta: fechaHastaBus
      }
      let remitosPeticion = await this.$store.dispatch('remitos/getRemitos', datos)
      this.$store.state.loading = false
      if (remitosPeticion.resultado == 1){
        this.remitos = remitosPeticion.remitos
      }else{
        this.$store.dispatch('show_snackbar', {
          text: remitosPeticion.msj,
          color: 'error',
        })
      }
    },
    limpiar(){
      this.selectedBodDestino = {}
      this.selectedBodOrigen = {}
      this.selectedEstado = {}
      this.remitoNumero = ''
      this.limpiarFecha = true
    },
    verRemito(item){
      let path = '/remito-intero/' + item.remito_numero.toString()
      const routeData = this.$router.resolve({ path: path })
      window.open(routeData.href, '_blank')
    },
    async generarPDF(item){
      this.$store.state.loading = true
      let reportePeticion = await this.$store.dispatch('remitos/getRemitoPDF', {remito_numero: item.remito_numero})
      this.$store.state.loading = false
      if (reportePeticion.resultado == 1){
        if (reportePeticion.enabezado.length > 0){
          // formateo las fechas
          reportePeticion.enabezado[0].fecha = moment(reportePeticion.enabezado[0].fecha).format('DD/MM/YYYY')
          reportePeticion.enabezado[0].fecha_grabacion = moment(reportePeticion.enabezado[0].fecha_grabacion).format('DD/MM/YYYY')
          let pdfPeticion = await reporteRemitoInterno({enc: reportePeticion.enabezado[0], detalles: reportePeticion.detalles})
          if (pdfPeticion.resultado == 1){
            this.nombreVer = 'Remito N°' + item.remito_numero
            this.pdf = pdfPeticion.pdf
            this.dialogPdf = true
          }else{
            this.$store.dispatch('show_snackbar', {
              text: pdfPeticion.msj,
              color: 'error',
            })
          }
        }else{
          this.$store.dispatch('show_snackbar', {
            text: 'El remito sobrepasa la antiguedad establecida.',
            color: 'info',
          })
        }
      }else{
        this.$store.dispatch('show_snackbar', {
          text: reportePeticion.msj,
          color: 'error',
        })
      }
    },
    nuevoRemito(){
      router.push({name: 'Nuevo Remito Interno'})
    },
    changeSize (custom) {
      this.big = custom
    }
  },
}
</script>

<style>

</style>